/**
 * @name    WavRecorder - Wav Recording Service to record PCM audio and store any reference using audiodb
 * @file    wavrecorder.service.js
 * @date    12/10/2020
 * @version 1.0.0
 * @author  L.Tavolato
 *
 * @see https://github.com/arnesson/angular-cordova
 */
import { Injectable         } from '@angular/core';
import { Platform           } from '@ionic/angular';
import { File               } from '@ionic-native/file/ngx';
import { ISong              } from '../models/hermes.models';
import { AudiodbService     } from './audiodb.service';

// get the reference to Wav Recorder
// const Recorder   = (window as any).voicewise.Recorder;
// const Recorder   = (window as any).voicewise?.Recorder || (voicewise as any).Re;
// const platformId = (window as any).cordova.platformId;

function getWindow(): any {
    // return the global native browser window object
    return window;
}

@Injectable()
export class WavRecorderService {

    private recording: boolean = false;
    private audioPath: string;
    private audioName: string;
    private audioKey: string;
    // private Recorder: any = null;
    private recorder: any = null;
    private ready: boolean = false;
    private status: any = null;
    private config = {
        sampleRate: 44100,
        channels: 1,
        encoding: 16
    };

    private plugin = null;
    private Recorder = null;
    private window  = null;
    private statusCode = {
        INITIALIZING: 'INITIALIZING',
        READY: 'READY',
        RECORDING: 'RECORDING',
        ERROR: 'ERROR',
        STOPPED: 'STOPPED',
        PERMISSION_DENIED: 'PERMISSION_DENIED'
    };

    constructor(
        private file: File,
        private platform: Platform,
        private songdbService: AudiodbService) {
            const that = this;
            this.platform.ready().then( () => {
            // tslint:disable-next-line:no-console
            console.debug(`wavRecorder.constructor: platform(s) [${this.platform.platforms().join(', ')}]`);
            that.ready = true;
            that.window = getWindow();
            if ( that.window ) {
                that.plugin   = that.window.martinescu || null;
                that.Recorder = that.plugin   ? that.plugin.Recorder  : null;
                that.status   = that.Recorder ? that.statusCode.READY : that.statusCode.ERROR;
                console.log(`wavRecorder: PLUGIN IS ${that.plugin ? 'OK' : 'KO'} RECORDER IS ${that.Recorder ? 'OK' : 'KO'} STATUS is ${that.status}`, JSON.stringify(that.statusCode));
            } else {
                console.error(`wavRecorder: FAILED`);
            }
        });
    }

    /**
     * Returns a 8 bytes unique id
     */
    getUniqueID() {
        function chr4() {
            return Math.random().toString(16).slice(-4);
        }
        return `${chr4()}${chr4()}`;
    }

    /**
     * Generate a name for the audio file
     * @returns a filename based on current moment
     */
    private makeFileName() {
        const now = new Date();
        const fmt = (n) => (n > 9 ? n : '0' + n);
        return 'audio' + now.getFullYear() + '' + fmt(now.getMonth()) + fmt(now.getDate()) + fmt(now.getHours()) + '' + fmt(now.getMinutes()) + '' + fmt(now.getSeconds()) + '.wav';
    }

    /**
     * Return true if recording is going on, false otherwise
     */
    isRecording() {
        return this.recording;
    }

    /**
     * Start recording a new sound
     * @param audioName file name without extension
     * @param audioKey file key
     */
    startRecord(audioName?: string, audioKey?: string) {

        if ( !this.Recorder ) {
            console.error(`wavRecorderService.startRecord: no recorder plugin\n\n`);
            return( false );
        }
        if ( !this.ready ) {
            console.error(`wavRecorderService.startRecord: RECORDER NOT READY\n\n`);
            return( false );
        }
        if ( this.isRecording() ) {
            this.stopRecord();
        }
        if ( audioName && audioName.length ) {
            this.audioName = audioName + '.wav';
        } else {
            this.audioName = this.makeFileName();
        }
        if ( audioKey ) {
            this.audioKey = audioKey;
        } else {
            this.audioKey = this.audioName.split('.')[0];
        }

        if (this.platform.is('ios')) {
            this.audioPath = this.file.documentsDirectory.replace(/file:\/\//g, '') + this.audioName;
        } else if (this.platform.is('android')) {
            this.audioPath = this.file.externalDataDirectory.replace(/file:\/\//g, '') + this.audioName;
        }

        this.recorder = new this.Recorder(this.audioName, this.config, this.statusCallback, this.bufferCallback);

        // start recording
        if ( this.recorder ) {
            this.recorder.record();
            this.recording = true;
            console.log(`wavRecorderService.startRecord: START recording ${this.audioName} \nPath: ${this.audioPath}\n\n`);
            return( true );
        }

        console.error(`wavRecorderService.startRecord: CANNOT RECORD ${this.audioName} \nPath: ${this.audioPath}\n\n`);
        return( false );

    }

    /**
     * Stop the recording process, release used resources and update audio db
     */
    async stopRecord(): Promise<ISong> {

        if ( !this.isRecording() ) {
            return;
        }

        if ( !this.recorder || !this.recorder.stop || !this.recorder.location ) {
            console.error(`wavRecorderService.stopRecord: NO RECORDER`);
            return;
        }

        // stop recording -> call this.recorder.release() to delete the file and release memory
        this.recorder.stop();

        // get the path where the audio file has been stored
        this.audioPath = await this.recorder.location();

        const song: ISong = {
            id:   null,
            key:  this.audioKey,
            name: this.audioName,   // set the file name
            path: this.audioPath    // set the absolute path to recorded file
        };

        console.log(`wavRecorderService.stopRecord: STOP recording ${this.audioName} \nPath: ${this.audioPath}\n\n`);
        console.log(`----------------------------------------------------------------------------------------`);

        // update the song db
        await this.songdbService.setItem(song.key, song);
        this.recording = false;
        return song;
    }

    /**
     * Receives any status change notification
     * @param mediaStatus the recorder status
     * @param error any error message
     */
    statusCallback(mediaStatus, error) {
        if ( mediaStatus === 'ERROR' ) {
            console.error(`wavRecorderService.statusCallback: ERROR ${error}\n\n`);
            this.stopRecord();
        }
        /*
        switch (mediaStatus) {
            case this.statusCode.ERROR:
                console.error(`wavRecorderService.statusCallback: ERROR ${error}\n\n`);
                this.stopRecord();
                break;
            case this.statusCode.INITIALIZING:
                console.log(`wavRecorderService.statusCallback: ${mediaStatus}\n\n`);
                break;
            case this.statusCode.READY:
                console.log(`wavRecorderService.statusCallback: ${mediaStatus}\n\n`);
                break;
            case this.statusCode.RECORDING:
                console.log(`wavRecorderService.statusCallback: ${mediaStatus}\n\n`);
                break;
            case this.statusCode.STOPPED:
                console.log(`wavRecorderService.statusCallback: ${mediaStatus}\n\n`);
                break;
            case this.statusCode.PERMISSION_DENIED:
                console.error(`wavRecorderService.statusCallback: ${mediaStatus}\n\n`);
                break;
            default:
                console.error(`wavRecorderService.statusCallback: unknown status ${mediaStatus}\n\n`);
                break;
        }
        */
        this.status = mediaStatus;
    }

    /**
     * Receives the next audio buffer (PCM not encoded)
     * @param buffer audio buffer
     */
    bufferCallback(buffer: any) {
        // console.log(`wavRecorderService.bufferCallback: received ${this.audioName} #${buffer.length} bytes`);
    }

}
