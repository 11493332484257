import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule  } from '@ionic/angular';
// import { AngularSvgIconModule } from 'angular-svg-icon';

import { PasswordForgotModalPage  } from './passwordforgot.modal';

import { TranslateModule } from '@ngx-translate/core';
import { AuthService     } from '../../services/auth.service';

const routes: Routes = [
  {
    path: '',
    component: PasswordForgotModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    // AngularSvgIconModule,
    TranslateModule.forChild(),
    RouterModule.forChild(routes)
  ],
  declarations: [PasswordForgotModalPage],
  providers: [AuthService]
})
export class PasswordForgotModalPageModule {}
