/**
 * Gestione inserimento o modifica dati paziente Hermes
 */
import { Component, OnInit, Input } from '@angular/core';
import { Router            } from '@angular/router';
import { AlertController   } from '@ionic/angular';
import { TranslateService  } from '@ngx-translate/core';
import { Apollo, gql       } from 'apollo-angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ModalController, NavParams } from '@ionic/angular';

import { ECollection      } from '../../services/api.service';
import { AuthService      } from '../../services/auth.service';
import { ToastService     } from '../../services/toast.service';
import { StoreService     } from '../../services/store.service';
import { Md5Service       } from '../../services/md5.service';                 // calculates the MD5 of a buffer
import { UtilityService   } from '../../services/utility.service';

import { IPatient, IUser, EAction, IEvaluation, IEvalShort, EEvaluationStatus, IAnalysis, IPathology, EKeys, IRecording } from '../../models/hermes.models';

import { v4 as uuidv4, validate as uuidValidate } from 'uuid';

const moment    = require('moment-timezone');

// get the patient that has the defined code
const PATIENT_BY_CODE = gql`
  query FindPatientByCode( $code: String! ) {
      patients(where: { code: $code }) {
          id
          age
          sex
          code
          height
          weight
          perfect
          ethnicity
          note
          user {
              id
              firstname
              lastname
          }
          evaluations {
              id
              pathology {
                  id
                  name
                  code
              }
              status
              analysis {
                  response
              }
              recordings {
                id
                name
                size
                code
                MD5
                createdAt
                updatedAt
              }
          }
      }
  }
`;

const PATIENT_BY_ID = gql`
  query GetPatient( $patientId: ID! ) {
      patient(id: $patientId) {
          id
          age
          sex
          code
          height
          weight
          perfect
          ethnicity
          note
          user {
              id
              firstname
              lastname
          }
          evaluations {
              id
              code
              note
              pathology {
                  name
                  code
              }
              status
              analysis {
                  response
              }
              recordings {
                id
                name
                size
                code
                MD5
                createdAt
                updatedAt
              }
              surveyAnswers
          }
      }
  }
`;

// get the patient that has the defined code
const PATIENT_CREATE = gql`
  mutation CreatePatient( $code: String!, $age: Int!, $sex: ENUM_PATIENT_SEX, $height: Int!, $weight: Int!, $perfect: Boolean, $ethnicity: String!, $note: String!, $user: ID!) {
      createPatient(
          input: {
              data: {
                  age: $age,
                  sex: $sex,
                  code: $code,
                  height: $height,
                  weight: $weight,
                  perfect: $perfect,
                  ethnicity: $ethnicity,
                  note: $note,
                  user: $user,
              }
          }
      ) {
          patient {
              id
              age
              sex
              code
              height
              weight
              perfect
              ethnicity
              note
              user {
                  id
                  firstname
                  lastname
              }
          }
      }
  }
`;

const PATIENT_UPDATE = gql`
  mutation UpdatePatient( $id: ID!, $age: Int!, $sex: ENUM_PATIENT_SEX, $height: Int!, $weight: Int!, $perfect: Boolean, $ethnicity: String!, $note: String! ) {
      updatePatient(
          input: {
              where: { id: $id }
              data: {
                age: $age,
                sex: $sex,
                height: $height,
                weight: $weight,
                perfect: $perfect,
                ethnicity: $ethnicity,
                note: $note
              }
          }
      ) {
          patient {
              id
              age
              sex
              code
              height
              weight
              perfect
              ethnicity
              note
              user {
                  id
                  firstname
                  lastname
              }
          }
      }
  }
`;

const PATIENT_DELETE = gql`
  mutation DeletePatient( $id: ID! ) {
      deletePatient( input: { where: { id: $id } } ) {
          patient {
              id
              age
              sex
              code
              height
              weight
              perfect
              ethnicity
              note
              user {
                  id
                  firstname
                  lastname
              }
          }
      }
  }
`;

const PATIENT_EVALUATIONS = gql`
  query GetPatientEvaluations( $patientId: ID!) {
      evaluations(where: { patient: $patientId }) {
          id
          code
          status
          longitude
          latitude
          createdAt
          updatedAt
          patientCode
          recordings {
              id
              name
              size
              code
              MD5
              createdAt
              updatedAt
          }
          analysis {
              id
              response
          }
          pathology {
              name
              code
          }
          user {
              id
              firstname
              lastname
          }
          surveyAnswers
      }
  }
`;

const EVALUATION_RECORDINGS = gql`
  query GetEvaluationRecordings( $evaluationId: ID!) {
      recordings(where: { evaluation: $evaluationId }) {
          id
          name
          size
          code
          MD5
          audio64
          createdAt
          updatedAt
      }
  }
`;

@Component({
  selector:      'hpatient-modal',
  templateUrl: './hpatient.modal.html',
  styleUrls: [ './hpatient.modal.scss']
})
export class HPatientModalPage implements OnInit {

  patient: IPatient = null;
  patientId: string = null;
  patientCode: string = null;
  patientForm: FormGroup;
  evaluation: IEvalShort = null;
  evalIndex: number = 0;

  recordings: IRecording[] = [];

  evaluations: IEvalShort[] = [];

  patientTemplate: IPatient  = {
    id: '',
    code: null,
    age: 50,
    birthdate: null,
    perfect: false,
    sex: 'M',
    height: 160,
    weight: 60,
    ethnicity: 'IT',    // italian, caucasian, ...
    note: '',
    evaluations: [],    // zero or more evaluations
    user: null
  };

  user: IUser = null;
  userId: string = null;
  editMode: boolean = true;
  isAdmin: boolean = false;
  isSuperDoctor: boolean = false;
  isDisabled: boolean = true;
  isAnyOne: boolean = false;
  data = '';

  qrType = 'text';
  qrValue = 'Voice4Health';

  genders = [ {label: 'Maschile', id: 'M'}, { label: 'Femminile', id: 'F' }];

  constructor(
    private router: Router,
    private apollo: Apollo,
    private auth: AuthService,
    private alert: AlertController,
    private store: StoreService,
    private md5Service: Md5Service,
    private navParams: NavParams,
    private utility: UtilityService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private modalController: ModalController,
    private translateService: TranslateService) {
    this.userId = auth.getUserId();
    this.isAdmin = auth.isAdministrator();
    this.isSuperDoctor = auth.isSuperDoctor();
    this.isAnyOne = auth.isAdministrator() || auth.isSuperDoctor();
    console.log(`hpatientModal: any=${this.isAnyOne} admin=${this.isAdmin} super=${this.isSuperDoctor} language=${translateService.getDefaultLang()}`, translateService.store.translations.it);
  }

  /**
   * initialise the modal
   */
  ngOnInit() {
    console.log(`hpatientModal.ngOnInit: ${JSON.stringify(this.navParams.data.patient, null, 4)}`);
    // console.table(this.navParams);
    this.patient     = this.navParams.data.patient;               // the patient to edit
    this.patientId   = this.navParams.data.patientId;             // the id of the patient to edit
    this.patientCode = this.navParams.data.patientCode;           // the patient code to use
    this.user        = this.navParams.data.user;                  // current user
    this.userId      = this.userId || this.navParams.data.userId; // current user id
    this.editMode    = this.navParams.data.editMode;              // true if shall edit patient

    console.log(`hpatientModal.ngOnInit: patientId=${this.patientId} patientCode=${this.patientCode} userId=${this.userId}`, this.patient);
    // console.table(this.patient);

    // patient if available
    if ( this.patient ) {
      // setup patient data
      this.setPatient(this.patient, this.patient.code);     // set base patient data
      this.getPatient(this.patient.id || this.patient._id); // get full patient data
    } else {
       // Get patient data on patient code or patient id defined
      if ( this.patientCode && this.validate(this.patientCode) ) {  // select a patient by code or create a new one?
        console.log('hpatientModal.ngOnInit: loading patient by code ' + this.patientCode); // select or create a patient?
        this.findPatient(this.patientCode);   // patient lookup
      } else if ( this.patientId && this.patientId.length > 1 ) {
        console.log('hpatientModal.ngOnInit: loading patient by id ' + this.patientId);     // modify and select a patient?
        this.getPatient(this.patientId);      // get all patient data
      } else {
        // no reference, create a new patient
        console.log('hpatientModal.ngOnInit: new patient');
        this.setPatient(null, null);          // init  a new patient
      }
    }

  }

  /**
   * Return true if the survey can be edited
   * @param evaluation the evaluation to edit
   * @returns true if the survey can be edited
   */
  canEditSurvey(evaluation: IEvalShort) {
    // console.log(`hpatientModal.canEditSurvey: admin=${this.isAdmin} super=${this.isSuperDoctor} status=${evaluation.status}`);
    if ( (this.isAdmin || this.isSuperDoctor) && evaluation.status === EEvaluationStatus.EXECUTED ) {
      return true;
    }
    return false;
  }

  /**
   * Check if a patient code is valid
   * @param code patient code
   */
  validate(code: string) {
    // console.log(`hpatientModal.validate: evaluating ${code} length=${code.length}`);
    // is ExpertLab Patient Code?
    if ( code.length >= 36 && code.indexOf('-') < 0) {
      // convert 811bdc257baa46549d2b7ff0d007837cA8D4 to uuid + MD5 (32 UUID + 4 Md5)
      // 77fb8610-3649-4791-8e69-01249aea39ce
      const sizes = [8, 4, 4, 4, 12, 4];
      const segments = [];
      let index = 0;
      sizes.forEach( size => { segments.push(code.substr(index, size)); index += size; });
      const base  = code.substr(0, 32);       // unique patient id
      // generate MD5 checks from EL patient code
      const check1 = code.substr(32, 4).toLowerCase();
      const check2 = this.md5Service.make(base).substr(0, 4).toLowerCase();
      if ( check1 !== check2 ) {
        console.error(`hpatientModal.validate: codice EL ${code} non valido => ${check1} != ${check2}`);
        return false;
      }
      // generate uuid from EL patient code
      const uuid = `${segments[0]}-${segments[1]}-${segments[2]}-${segments[3]}-${segments[4]}`;
      if ( uuidValidate(uuid) ) {
        console.log(`hpatientModal.validate: UUID EL ${uuid} valido`);
        return true;
      }
      console.log(`hpatientModal.validate: UUID EL NON ${uuid} VALIDO`);
      return false;
    }
    console.log(`hpatientModal.validate: check if UUID v4`);
    // check if uuid
    if ( uuidValidate(code) ) {
      return true;
    }
    console.log(`hpatientModal.validate: check if COD-MD5`);
    // check if code + md5
    const seg = code.split('-');
    if ( seg.length > 2 ) {
      return false;
    }
    const md5 = this.md5Service.make(seg[0]);
    return md5 === seg[1];
  }

  /**
   * Set only editable data and ignore all the rest
   * @param patient patient data
   */
  private async setPatient(patient: IPatient, code) {
    const patientCode = code || uuidv4();
    this.patientTemplate.id        = patient ?  patient.id : null;
    this.patientTemplate.code      = patient && patient.code       ? patient.code      : patientCode;
    this.patientTemplate.age       = patient && patient.age        ? patient.age       : 50;
    this.patientTemplate.birthdate = patient && patient.birthdate  ? patient.birthdate : '';
    this.patientTemplate.sex       = patient && patient.sex        ? patient.sex       : 'M';
    this.patientTemplate.height    = patient && patient.height     ? patient.height    : 160;
    this.patientTemplate.weight    = patient && patient.weight     ? patient.weight    : 60;
    this.patientTemplate.perfect   = patient && patient.perfect    ? patient.perfect   : false;
    this.patientTemplate.note      = patient && patient.note       ? patient.note      : '';
    this.patientTemplate.user      = this.userId;
    if ( patient ) {
      if ( patient.evaluations ) {
        this.setupEvaluations(patient.evaluations as IEvaluation[]);     // setup evaluations for display
      } else {
        this.loadEvaluations(patient.id);
      }
    }

    console.log(`hpatientModal.setPatient: uuid=${code} id=${this.patientTemplate.id} patient`, this.patientTemplate);
    // console.table(this.patientTemplate);
  }

  /**
   * Return the label associated to current gender
   */
  getGender() {
    const gender = this.genders.find(gg => gg.id === this.patientTemplate.sex);
    return gender ? gender.label : this.patientTemplate.sex;
  }

  /**
   * Verifies that the patient can be saved
   */
  verifyPatient(patient: IPatient) {
    // console.log('hpatientModal.verifyPatient:', patient);
    if ( !patient ) {
      console.error('hpatientModal.verifyPatient: istanza mancante');
      this.toastService.showMessage('toast.technical-error');
      return false;
    }
    if (!patient.age) {
      console.error('hpatientModal.verifyPatient: missing age');
      this.toastService.showMessage('toast.must-add-age');
      return false;
    }
    if ( !patient.sex ) {
      console.error('hpatientModal.verifyPatient: missing sex');
      this.toastService.showMessage('toast.must-add-genre');
      return false;
    }
    // check that the patient code uses the correct format
    if ( patient.code && patient.code.length && !this.validate(patient.code)) {
      this.toastService.showMessage('toast.patient-code-not-valid');
      return false;
    }
    return true;
  }

  onEditPatient() {
    this.editMode = true;
    // console.log(`hpatientModal.onEditPatient: ${JSON.stringify(this.patientTemplate, null, 4)}`);
  }

  /**
   * Close the modal
   */
  async onGoBack() {
    // console.log('hpatientModal.onGoBack');
    await this.modalController.dismiss({
      type: EAction.CANCEL,
      data: null}, ECollection.PATIENTS);
  }

  /**
   * Start patient processing: survey and voice recording
   * @param patient the patient to manage
   */
  async onStartPatientFlow(patient: IPatient) {
    // console.log('hpatientModal.onGoBack: patient', patient);
    this.store.remove(EKeys.SHORT_EVAL_KEY);  // remove any previously selected short description
    await this.modalController.dismiss({
      type: EAction.GOTO,
      data: {
        page: 'hsurvey',
        // tslint:disable-next-line:object-literal-shorthand
        patient: patient
      }
    }, ECollection.PATIENTS);
  }

  /**
   * Saves or updates patient information
   */
  onSave(patient: IPatient) {
    const patientId = this.patientId || this.patientTemplate.id;
    const idValid   = patientId && patientId.length;
    console.log(`hpatientModal.onSave: ID=${idValid ? 'OK' : 'NONE'} ${JSON.stringify(this.patientTemplate, null, 4)}`);
    if ( !this.verifyPatient(this.patientTemplate) ) {
      return;
    }
    if ( idValid ) {
      this.updatePatient(patientId);
    } else {
      this.createPatient();
    }
  }

  /**
   * Ask if the patient shall be deleted
   */
  async onDelete() {
    console.log('patient.onDelete:', this.patient);
    const that  = this;
    const alert = await this.alert.create({
      header: 'Cancellazione',
      message: `Vuoi cancellare questo paziente in modo permanentemente?`,
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Skip patient delete');
          }
        }, {
          text: 'SI',
          handler: () => {
            that.deletePatient();
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * Read patient data - if any - using patient's code
   * If the patient exist, use him/her for next evaluation otherwise create a new one
   * @param patientCode unique code of the patient
   */
  async findPatient(patientCode: string) {
    const that = this;
    console.log(`hpatientModal.hpatientModal: patientCode=${patientCode}`);
    if (!patientCode || !patientCode.length) {
        return;
    }
    this.apollo.query<any>( { query: PATIENT_BY_CODE, variables: { code: patientCode} }).subscribe(
      ({ data, loading, networkStatus }) => {
        console.log(`hpatientModal.hpatientModal:`, data.patients );
        // existing patient?
        if ( Array.isArray( data.patients) &&  data.patients.length ) {
          that.setPatient( data.patients[0], patientCode);  // Yes, select it and move to the survey
          console.log(`hpatientModal.findPatient: select`,  data.patients[0]);
          that.onStartPatientFlow( data.patients[0]);
          that.editMode = false;
          return;
        }
        console.log(`hpatientModal.findPatient: new patient with code ${patientCode}`);
        that.editMode = true;                 // enable editing new patient
        that.setPatient(null, patientCode);   // create a new patient and assign this code
      },
      (error) => {
        const msg = that.utility.getQueryError(error) || error.toString();
        that.editMode = true;                 // enable editing new patient
        that.setPatient(null, patientCode);   // create a new patient and assign this code
        console.error('hpatientModal.findPatient: ERROR ' + msg);
      }
    );
  }

  /**
   * Read patient data
   */
  getPatient(id) {
    const that = this;
    console.log(`hpatientModal.getPatient: getting patient ${id}`);
    this.apollo.query<any>( { query: PATIENT_BY_ID, variables: { patientId: id } }).subscribe(
      ({ data, loading, networkStatus }) => {
        console.log(`hpatientModal.getPatient: RESPONSE`, data.patient);  //
        that.setPatient(data.patient, data.patient.code);   // set patient for editing
      },
      (error) => {
        that.setPatient(that.patientTemplate, null);
        const msg = that.utility.getQueryError(error) || error.toString();
        console.error('hpatientModal.getPatient: ERROR ' + msg, error);
        that.toastService.showMessage(msg);
      }
    );
  }

  /**
   * Save existing patient - data has been already validated
   */
  async updatePatient(patientId) {
    const that = this;
    const vars = {
      id:        patientId,
      sex:       this.patientTemplate.sex,
      age:      +this.patientTemplate.age    || 0,
      height:   +this.patientTemplate.height || 0,
      weight:   +this.patientTemplate.weight || 0,
      perfect:   this.patientTemplate.perfect,
      ethnicity: this.patientTemplate.ethnicity,
      code:      this.patientTemplate.code,
      note:      this.patientTemplate.note
    };
    console.log(`hpatientModal.updatePatient: data=`, vars);
    this.apollo.mutate<any>( { mutation: PATIENT_UPDATE, variables: vars }).subscribe(
      ({data}) => {
        console.log(`hpatientModal.updatePatient: RESPONSE`, data.patient);
        that.toastService.showMessage('toast.patient-updated');
        that.patient  = data.patient;
        that.editMode = false;
      },
      (error) => {
        const msg = that.utility.getQueryError(error) || error.toString();
        console.error(`hpatientModal.updatePatient: ERROR ${msg}\nVARIABLES=${JSON.stringify(vars, null, 4)}\nERROR=${JSON.stringify(error, null, 4)}`);
        that.toastService.showMessage(msg);
      }
    );
  }

  /**
   * Create a new patient - data has been already validated
   */
  async createPatient() {
    const that = this;
    const vars = {
      code:      this.patientTemplate.code,
      sex:       this.patientTemplate.sex,
      age:       +this.patientTemplate.age    || 0,
      height:    +this.patientTemplate.height || 0,
      weight:    +this.patientTemplate.weight || 0,
      perfect:   this.patientTemplate.perfect,
      ethnicity: this.patientTemplate.ethnicity,
      note:      this.patientTemplate.note,
      user:      this.userId
    };
    console.log(`hpatientModal.createPatient: Sending`, vars);
    this.apollo.mutate<any>( { mutation: PATIENT_CREATE, variables: vars }).subscribe(
      ({data}) => {
        that.editMode = false;
        that.patient  = data.createPatient.patient;
        console.log(`hpatientModal.createPatient: RESPONSE ${JSON.stringify(that.patient, null, 4)}`);
        that.toastService.showMessage('toast.patient-created');
        that.setPatient(that.patient, that.patient.code);
      },
      (error) => {
        const msg = that.utility.getQueryError(error) || error.toString();
        console.error(`hpatientModal.createPatient: ERROR ${msg}\nVARIABLES=${JSON.stringify(vars, null, 4)}\nERROR=${JSON.stringify(error, null, 4)}`);
        that.toastService.showMessage(msg);
      }
    );
  }

  /**
   * Delete a patient
   */
  async deletePatient() {
    const that = this;
    const patientId = this.patient.id;
    console.log(`hpatientModal.deletePatient: killing`, this.patient);
    this.apollo.mutate<any>( { mutation: PATIENT_DELETE, variables: { id: patientId } }).subscribe(
      async ({data}) => {
        that.toastService.showMessage('patient.patient-deleted');
        await that.modalController.dismiss({
          type: EAction.DELETE,
          data: that.patient }, ECollection.PATIENTS as any);
      },
      (error) => {
        const msg = that.utility.getQueryError(error) || error.toString();
        console.error(`hpatientModal.deletePatient: ERROR ${msg}\nERROR=${JSON.stringify(error, null, 4)}`);
        that.toastService.showMessage(msg);
      }
    );
  }

  /**
   * Load evaluation belonging to current patient
   * @param patId id of current patient
   */
  async loadEvaluations(patId: string) {
    const that = this;
    console.log(`hpatientModal.loadEvaluations: patient id=${patId}`);
    if ( !patId || !patId.length ) {
      return;
    }
    this.apollo.query<any>( { query: PATIENT_EVALUATIONS, variables: { patientId: patId} }).subscribe(
      ({data}) => {
        console.log(`hpatientModal.loadEvaluations:`, data.evaluations );
        that.setupEvaluations(data.evaluation);
      },
      (error) => {
        const msg = that.utility.getQueryError(error) || error.toString();
        console.error('hpatientModal.loadEvaluations: ERROR ' + msg);
        that.toastService.showMessage(msg);
      }
    );
  }

  /**
   * Load recordings belonging to current evaluation
   * @param evalId id of current evaluation
   */
  async loadRecordings(evalId: string) {
    const that = this;
    console.log(`hpatientModal.loadRecordings: evaluation id=${evalId}`);
    if ( !evalId || !evalId.length ) {
      return;
    }
    this.apollo.query<any>( { query: EVALUATION_RECORDINGS, variables: { evaluationId: evalId} }).subscribe(
      ({ data, loading, networkStatus }) => {
        console.log(`hpatientModal.loadRecordings:`, data.recordings );
        that.recordings = [];
        (data.recordings || []).forEach( rec => {
          rec.date = (new moment(rec.createdAt || rec.updatedAt)).format('DD/MM/YYYY, HH:mm');
          that.recordings.push(rec);
        });
      },
      (error) => {
        const msg = that.utility.getQueryError(error) || error.toString();
        console.error('hpatientModal.loadRecordings: ERROR ' + msg);
        that.toastService.showMessage(msg);
      }
    );
  }


  /**
   * Build the short list of evaluation info
   * @param evaluations the list of patient's evaluation
   */
  setupEvaluations(evaluations: IEvaluation[]) {
    this.evaluations = [];
    // console.log('hpatientModal.setupEvaluations: IN', evaluations);
    (evaluations || []).forEach( ev => {
      const evs: IEvalShort = {} as IEvalShort;
      const result = ev.analysis ? (ev.analysis as IAnalysis).response : {};
      const date   = new moment(ev.createdAt || ev.updatedAt);
      evs.recordings = [];
      (ev.recordings || []).forEach(rec => {
        const dd = new moment(rec.createdAt || rec.updatedAt).format('DD/MM/YYYY, HH:mm');
        const newRec = Object.assign({date: dd}, rec);
        // console.log('old', rec, 'new', newRec);
        evs.recordings.push(newRec);
      });
      evs.id              = ev.id;
      evs.totalRecordings = evs.recordings.length;
      evs.analysisId      = ev.analysis   ? (ev.analysis as IAnalysis).id : '';
      evs.pathology       = ev.pathology  ? (ev.pathology as IPathology).name : '';
      evs.user            = ev.user       ? (ev.user as IUser).id : '';
      evs.latitude        = ev.latitude;
      evs.longitude       = ev.longitude;
      evs.patient         = ev.patientCode;
      evs.status          = ev.status;
      evs.note            = ev.note;
      evs.surveyAnswers   = ev.surveyAnswers   || null;
      evs.result          = result.stateMetric || '-';    // 0=sano, 1=malato
      evs.score           = result.scoreMetric || '-';
      evs.date            = date.format('DD/MM/YYYY, HH:mm');
      this.evaluations.push(evs);
    });
    // console.log('hpatientModal.setupEvaluations: OUT', this.evaluations);
  }

  onSelectEvaluation(evaluation: IEvalShort, index: number) {
    console.log('hpatientModal.onSelectEvaluation:', evaluation);
    this.evalIndex  = index + 1;
    this.evaluation = evaluation;
    this.recordings = (evaluation.recordings || []) ; // <IRecording[]
   // this.loadRecordings(evaluation.id)
  }

  onSelectRecording(recording: IRecording) {
    console.log('hpatientModal.onSelectRecording:', recording);
  }

  /**
   * Activates survey editing
   * @param evaluation the evaluation that contains the survey to modify
   */
    async onEditSurvey(evaluation: IEvalShort) {
      console.log('hpatientModal.onEditSurvey:', evaluation);
      this.store.set(EKeys.SHORT_EVAL_KEY, evaluation);
      await this.modalController.dismiss({
        type: EAction.GOTO,
        data: {
          page: 'hsurvey',
          patient: this.patient,
          // tslint:disable-next-line:object-literal-shorthand
          evaluation: evaluation
        }
      }, ECollection.EVALUATIONS);
    }
}
