import { NgModule         } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AuthSuperService,
  AuthAdminService,
  AuthEditorService,
  AuthDoctorService,
} from './services/auth-guard.service';

const routes: Routes = [

  // public pages
  { path: ''                , redirectTo:   'home', pathMatch: 'full'                                                 },
  { path: 'home'            , loadChildren: './pages/public/home/home.module#HomePageModule'                          },
  { path: 'help'            , loadChildren: './pages/public/help/help.module#HelpPageModule'                          },
  { path: 'about'           , loadChildren: './pages/public/about/about.module#AboutPageModule'                       },
  { path: 'login'           , loadChildren: './pages/public/login/login.module#LoginPageModule'                       },
  { path: 'privacy'         , loadChildren: './pages/public/privacy/privacy.module#PrivacyPageModule'                 },
  { path: 'register'        , loadChildren: './pages/public/register/register.module#RegisterPageModule'              },
  { path: 'forgotpass'      , loadChildren: './pages/public/forgotpass/forgotpass.module#ForgotPassPageModule'        },
  { path: 'changepass'      , loadChildren: './pages/public/changepass/changepass.module#ChangePassPageModule'        },
  { path: 'updatecheck'     , loadChildren: './pages/public/updatecheck/updatecheck.module#UpdateCheckPageModule'     },
  { path: 'termsconditions' , loadChildren: './pages/public/termsconditions/termsconditions.module#TermsConditionsPageModule' },

  // private pages
  { path: 'hsurvey'         , loadChildren: './pages/private/hsurvey/hsurvey.module#HSurveyPageModule'                },
  { path: 'hpatients'       , loadChildren: './pages/private/hpatients/hpatients.module#HPatientsPageModule'          },
  { path: 'hevaluations'    , loadChildren: './pages/private/hevaluations/hevaluations.module#HEvaluationsPageModule' },
  { path: 'hrecordings'     , loadChildren: './pages/private/hrecordings/hrecordings.module#HRecordingsPageModule'    },
  { path: 'hrecorder'       , loadChildren: './pages/private/hrecorder/hrecorder.module#HRecorderPageModule'          },
  { path: 'hrecords'        , loadChildren: './pages/private/hrecords/hrecords.module#HRecordsPageModule'             },
  { path: 'hresponse'       , loadChildren: './pages/private/hresponse/hresponse.module#HResponsePageModule'          },
  { path: 'hmqtt'           , loadChildren: './pages/private/hmqtt/hmqtt.module#HMqttPageModule'                      },
  { path: 'hscanner-web'    , loadChildren: './pages/private/hscanner-web/hscanner-web.module#HScannerWebPageModule'  },
  { path: 'hscanner-mobile' , loadChildren: './pages/private/hscanner-mobile/hscanner-mobile.module#HScannerMobilePageModule'  },

  // admin pages
  { path: 'admin-users'      , loadChildren: './pages/admin/admin-users/admin-users.module#AdminUsersPageModule'  },
  { path: 'admin-patients'   , loadChildren: './pages/admin/admin-patients/admin-patients.module#AdminPatientsPageModule'  },
  { path: 'admin-evaluations', loadChildren: './pages/admin/admin-evaluations/admin-evaluations.module#AdminEvaluationsPageModule'  },
  { path: 'admin-recordings' , loadChildren: './pages/admin/admin-recordings/admin-recordings.module#AdminRecordingsPageModule'  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

