/**
 * Datastore is an easy and advanced way to store key/value pairs and JSON objects.
 *
 * Datastore uses a variety of storage engines underneath, picking the best one available depending on the platform.
 * When running in a native app context, Datastore will prioritize using SQLite, as it's one of the most stable and
 * widely used file-based databases, and avoids some of the pitfalls of things like localstorage and IndexedDB, such
 * as the OS deciding to clear out such data in low disk-space situations.
 * When running in the web or as a Progressive Web App, Datastore will attempt to use IndexedDB, WebSQL, and
 * localstorage, in that order.
 *
 * @see https://ionicframework.com/docs/building/storage
 */
import { Injectable } from '@angular/core';
import { Storage    } from '@ionic/storage';

@Injectable()
export class DatastoreService {

    /**
     * Create a new Storage instance using the order of drivers and any additional config options to pass to LocalForage.
     * Possible driver options are: ['sqlite', 'indexeddb', 'websql', 'localstorage'] and the default is that exact ordering.
     * @param datastore
     */
    constructor(private datastore: Storage) {
    }

    /**
     * Build a searchable key
     * @param key the key to convert
     */
    private normalizeKey(key) {
        if (!key || !key.length) { return( null ); }
        key = key.replace(/ /g, '_');   // remove white spaces
        key = key.toLowerCase();        // convert to lower case
        return ( key );
    }

    /**
     * Build the key for storing a file using the key associated to its metadata
     * @param key is the key associated to file metadata
     */
    private getFileId(key: string) {
        return this.normalizeKey(key) + '-f1l3';
    }

    /**
     * Returns a promise that resolves when the key and value are set
     * @param key string
     * @param value any
     */
    async set(key: string, value: any) {
        key = this.normalizeKey(key);
        return this.datastore.set(key, value);
    }

    /**
     * Returns a promise with the value of the given key
     * @param key the search key
     */
    async get(key: string) {
        key = this.normalizeKey(key);
        return this.datastore.get(key);
    }

    /**
     * Returns a promise that resolves when the key, value and file are set
     * @param key unique key associated to value and file
     * @param value the data
     * @param file the file
     */
    async setFile(key: string, value: any, file: string) {
        const that = this;
        key = this.getFileId(key);
        const fileId = this.getFileId(key);
        return Promise.all([that.datastore.set(key, value), that.datastore.set(fileId, file)]);
    }

    /**
     * Returns a promise with the file associated to the given key
     * @param key the search key
     */
    async getFile(key: string) {
        key = this.getFileId(key);
        return this.datastore.get(key);
    }

    /**
     * Remove any value and any file associated with this key.
     * @param key the search key
     */
    async deleteFile(key: string) {
        const that = this;
        key = this.getFileId(key);
        const fileId = this.getFileId(key);
        return Promise.all([that.datastore.remove(key), that.datastore.remove(fileId)]);
    }

    /**
     * Remove any value associated with this key.
     * @param key the search key
     */
    async delete(key: string) {
        key = this.normalizeKey(key);
        const fileId = this.getFileId(key);
        await  this.datastore.remove(fileId);
        return this.datastore.remove(key);
    }

    /**
     * Returns a promise that resolves when the store is cleared
     */
    async clear() {
        return this.datastore.clear();
    }

    /**
     * Returns a promise that resolves when the iteration has finished
     * @param callback the callback that will receive for each iteration: value, key, iterationNumber
     */
    async each(callback) {
        return this.datastore.forEach(callback);
    }

    /**
     * Returns a promise that resolves when the store is ready
     */
    async ready() {
        return this.datastore.ready();
    }

    /**
     * Returns a promise that resolves with the keys in the store.
     */
    async keys() {
        return this.datastore.keys();
    }

    /**
     * Returns a promise that resolves with the number of keys stored.
     */
    async totalKeys() {
        return this.datastore.length();
    }

    /**
     * Return the name of the datastore driver
     */
    driver() {
        return this.datastore.driver;
    }

}
