/**
 * Audio Recorder based on WebRTC
 * @see https://stackblitz.com/edit/angular-custom-audio-recorder?file=src%2Fapp%2Faudio-recording%2Faudio-player.component.ts
 */
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import adapter from 'webrtc-adapter';

import { IMediaRecord, EMediaStatus } from '../../models/global.models';

@Component({
  selector:      'player-web',
  templateUrl: './player-web.component.html',
  styleUrls: [ './player-web.component.scss'],
})
export class PlayerWebComponent implements OnInit, OnChanges {

  @Input() record: IMediaRecord = null;

  @Output() status: EventEmitter<EMediaStatus> = new EventEmitter<EMediaStatus>();   // bas64 encoded audio file or empty/null string

  @ViewChild('audioElement', {static: true}) audioElement;

  playingFlag = false;
  readyFlag = false;
  time = '00:00:00';
  player = null;
  start = 0;
  audio = null;
  audioUrl;

  constructor(private domSanitizer: DomSanitizer) {
    console.log(`playerWeb:`, adapter);

  }

  ngOnInit() {
    console.log(`playerWeb.ngOnInit: browser`, adapter.browserDetails, 'record', this.record);
    if ( this.record && this.record.path && this.record.path.length ) {
      console.log(`playerWeb.onTimeUpdate: got record`);
    }
    this.setupAudio();
  }

  async ngOnChanges() {
    if ( this.record && this.record.audio && this.record.audio.length ) {
      console.log(`playerWeb.ngOnChanges: browser`, adapter.browserDetails, 'record', this.record);
      this.audioUrl = this.sanitize(this.record.audio);
      await this.setupAudio();
      if ( this.audio ) {
        console.log(`playerWeb.ngOnChanges: can use audio player`);
        this.audio.src = this.sanitize(this.record.audio);
      } else {
        console.log(`playerWeb.ngOnChanges: cannot use audio player`);
      }
    } else {
      console.error(`playerWeb.ngOnChanges: record not available`, this.record);
    }
  }

  /**
   * setup audio player
   */
  async setupAudio() {
    if ( !this.audio ) {
      this.audio = await document.querySelector('audio');
      if ( this.audio ) {
        console.log(`playerWeb.setupAudio: MPEG=${this.audio.canPlayType('audio/mpeg')} OGG=${this.audio.canPlayType('audio/ogg')} MP4=${this.audio.canPlayType('audio/mp4')}`);
        this.audio.ontimeupdate = function() {
          console.log(`playerWeb.onTimeUpdate: ${this.audio.currentTime / this.audio.duration * 100 + '%'}`);
        };
        this.readyFlag = true;
      } else {
        console.error(`playerWeb.setupAudio: cannot setup audio`);
        this.readyFlag = false;
      }
    }
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  updateTime() {
    if ( !this.start ) {
      this.time = '00:00:00';
      return;
    }
    const now     = (new Date()).getTime();
    const elapsed = Math.trunc((now - this.start) / 1000);
    const hours   = Math.trunc( elapsed / (60 * 60));
    const value   = elapsed - hours * (60 * 60);
    const minutes = Math.trunc( value / 60 );
    const seconds = value - (minutes * 60);
    this.time = (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds);
  }

  nextLoop( ) {
    const that = this;
    const nextTick = () => {
      if ( that.playingFlag ) {
        that.updateTime();
        setTimeout(nextTick, 1000);
      }
    };
    nextTick();
  }

  onStartPlaying() {
    if ( !this.playingFlag && this.readyFlag ) {
      console.log(`playerWeb.onStartPlaying: playing=${this.playingFlag} ${this.record ? this.record.name : ''}`);
      this.start = (new Date()).getTime();
      this.playingFlag = true;
      this.nextLoop();
    } else {
      console.error(`playerWeb.onStartPlaying: cannot play`);
    }
  }

  onStopPlaying() {
    if ( this.playingFlag && this.readyFlag ) {
      console.log(`playerWeb.onStopPlaying: playing=${this.playingFlag} ${this.record ? this.record.name : ''}`);
      this.audio.pause();
      this.playingFlag = false;
    } else {
      console.error(`playerWeb.onStopPlaying: cannot stop`);
    }
  }

  // audio events

  onPlay(event) {
    console.log(`playerWeb.onTimeUpdate:`, event);
  }

  onPlaying(event) {
    console.log(`playerWeb.onPlaying:`, event);
  }

  onPause(event) {
    console.log(`playerWeb.onTimeUpdate:`, event);
  }

  onEnd(event) {
    console.log(`playerWeb.onTimeUpdate:`, event);
  }

  onLoaded(event) {
    console.log(`playerWeb.onTimeUpdate:`, event);
  }

  onTimeUpdate( event) {
    console.log(`playerWeb.onTimeUpdate:`, event);
  }

  onComplete(event) {
    console.log(`playerWeb.onTimeUpdate:`, event);
  }

  onCanPlay(event) {
    console.log(`playerWeb.onCanPlay:`, event);
  }

}
