import { Component          } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { Subscription       } from 'rxjs';
import { TranslateService   } from '@ngx-translate/core';

import localeIt from '@angular/common/locales/it';

import { MenuController, Platform } from '@ionic/angular';
import { ModalController          } from '@ionic/angular';
import { LoadingController        } from '@ionic/angular';
import { Router, NavigationError  } from '@angular/router';

// ionic native plugins
import { AndroidPermissions       } from '@ionic-native/android-permissions/ngx';
import { SplashScreen             } from '@ionic-native/splash-screen/ngx';
import { StatusBar                } from '@ionic-native/status-bar/ngx';
import { AppUpdate                } from '@ionic-native/app-update/ngx';
import { Device                   } from '@ionic-native/device/ngx';

// modal pages
import { LoginModalPage           } from './modals/login/login.modal';
import { PasswordForgotModalPage  } from './modals/passwordforgot/passwordforgot.modal';
import { PasswordResetModalPage   } from './modals/passwordreset/passwordreset.modal';

import { enableAkitaProdMode      } from '@datorama/akita';

import { SpinnerService                   } from './services/spinner.service';
import { LinkService                      } from './services/link.service';
import { StoreService                     } from './services/store.service';
import { LoggerService                    } from './services/logger.service';
import { ToastService                     } from './services/toast.service';
import { MqttNotifyService                } from './services/mqttnotify.service';
import { MonitorService                   } from './services/monitor.service';    // monitor MQTT notifications and acts accordingly if required
import { NotifyService, IMessage          } from './services/notify.service';     // simple pub/sub notification service
import { DeviceDetailsService             } from './services/device.service';
import { AuthService, AuthServiceStatus   } from './services/auth.service';
import { MetadataService, EMetadataStatus } from './services/metadata.service';
import { HttpService, HttpServiceInfo, HttpServiceStatus } from './services/http.service';
import { EnvironmentService, IEnvInfo     } from './services/environment.service'; // provides environment variables
import { CacheService                     } from './services/cache.service';

import { EKeys, IAction, EAction, IHermesMessage, EClientAction, ESystemAction } from './models/hermes.models';

import { MqttService, IMqttMessage } from 'ngx-mqtt';

import * as moment from 'moment';
import { env } from 'process';

// import { ForgotPassPage } from './pages/public/forgotpass/forgotpass.page';

/*
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';
import { HttpSpinnerInterceptor } from './httpspinner.interceptor';
*/

const FingerprintJS = require('@fingerprintjs/fingerprintjs');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  rootTopic = 'vo1c3w1se3';
  private clientTopic = 'client';
  private systemTopic = 'system';

  public anonymousPages = [
    {
      title: 'menu.general-informations',
      url: '/home',
      icon: 'home'
    },
    /*
    {
      title: 'Recorder',
      url: '/recorder',
      icon: 'mic'
    },
    {
      title: 'Notizie',
      url:   '/appnews',
      icon:  'paper'
    }, */
    {
      title: 'menu.help',
      url:   '/help',
      icon:  'help-buoy'
    },
    {
      title: 'menu.terms-and-conditions',
      url:   '/termsconditions',
      icon:  'book'
    },
    {
      title: 'menu.privacy',
      url:   '/privacy',
      icon:  'information-circle-outline'
    },
    {
      title: 'menu.check-updates',
      url:   '/updatecheck',
      icon:  'construct'
    },
    {
      title: 'menu.credits',
      url:   '/about',
      icon:  'globe'
    }
  ];


  public loggedInPages = [
    {
      title: 'menu.general-informations',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'menu.patient-qrcode-scan',
      url:   '/hscanner-web',
      icon:  'qr-scanner'
    },
    /*
    {
      title: 'Acquisizione Codice Paziente',
      url:   '/hscanner-mobile',
      icon:  'qr-scanner'
    },
    */
    {
      title: 'menu.patients',
      url:   '/hpatients',
      icon:  'person'
    },
    {
      title: 'menu.help',
      url:   '/help',
      icon:  'help-buoy'
    },
    {
      title: 'menu.terms-and-conditions',
      url:   '/termsconditions',
      icon:  'book'
    },
    {
      title: 'menu.privacy',
      url:   '/privacy',
      icon:  'information-circle-outline'
    },
    {
      title: 'menu.check-updates',
      url:   '/updatecheck',
      icon:  'construct'
    },
    {
      title: 'menu.credits',
      url:   '/about',
      icon:  'globe'
    }
  ];

  /**
   * Administration and test pages
   */
  public adminPages = [
    {
      title: 'menu.users',
      url: '/admin-users',
      icon: 'contacts'
    },
    {
      title: 'menu.mqttTest',
      url: '/hmqtt',
      icon: 'bug'
    },
    {
      title: 'menu.local-recordings',
      url: '/hrecords',
      icon: 'folder'
    },
    {
      title: 'menu.recording-test',
      url: '/hrecorder',
      icon: 'mic'
    },
    {
      title: 'menu.survey-test',
      url:   '/hsurvey',
      icon:  'document'
    },
    {
      title: 'menu.response-wait-test',
      url: '/hresponse',
      icon: 'finger-print'
    },
    {
      title: 'menu.current-patient-evaluations',
      url: '/hevaluations',
      icon: 'albums'
    },
    /*
    {
      title: 'Notizie',
      url:   '/appnews',
      icon:  'paper'
    },
    {
      title: 'Gestione Notizie',
      url:   '/newslist',
      icon:  'paper'
    },
    {
      title: 'Gestione Pazienti',
      url:   '/patients',
      icon:  'person'
    },
    {
      title: 'Gestione Utenti',
      url:   '/users',
      icon:  'globe'
    },
    {
      title: 'Gestione Quesiti',
      url:   '/questions',
      icon:  'document'
    },
    {
      title: 'Gestione Frasi',
      url:   '/phrases',
      icon:  'book'
    }
    */

  ];

  isAdmin         = false;
  isEditor        = false;
  isMember        = false;
  isDoctor        = false;
  isAuthenticated = false;
  isConnected     = true;
  roleType        = '';
  loadingFlag     = true;
  language        = null;
  pathology       = null;
  pathologyTitle  = 'pathology.covid';
  defaultTitle    = 'Voice4Health';
  menuTitle       = this.defaultTitle;
  companyName     = null;
  httpRequests    = 0;
  subscribedChannel: Subscription = null;
  private httpSpinner = null;
  info: IEnvInfo  = null;
  deviceId: string = null;

  // optional app update configuration
  private appUpdateOptions = {
    authType: 'basic',
    username: 'username',
    password: 'password'
  };

  constructor(
    private router: Router,
    private toast: ToastService,
    private menu: MenuController,
    private appUpdate: AppUpdate,
    private modal: ModalController,
    private mqttService: MqttService,             // monitor MQTT messages
    private authService: AuthService,
    private httpService: HttpService,
    private metadata: MetadataService,            // load most relevant data
    private cacheService: CacheService,
    private loadingCtrl: LoadingController,
    private spinnerService: SpinnerService,
    private envService: EnvironmentService,
    // private mqttService: MqttNotifyService,    // start MQTT service
    private linkService: LinkService,
    private notify: NotifyService,                // root
    private store: StoreService,                  // permanently store/retrieve key/value pairs
    private logger: LoggerService,
    private splashScreen: SplashScreen,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private statusBar: StatusBar,
    private translateService: TranslateService
    // private device: DeviceDetailsService,
    ) {
    const that = this;
    this.rootTopic     = envService.getMqttRootTopic();
    const rootTopic    = store.get(EKeys.MQTT_ROOT_KEY);
    const deviceID     = store.get(EKeys.DEVICE_KEY);
    this.language      = envService.getLanguage();
    this.info          = envService.getInfo();
    this.deviceId      = deviceID;

    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang(envService.getLanguage() || 'it');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use(envService.getLanguage() || 'it');

    console.log(`APP.constructor: START ${deviceID} TimeStamp ${this.info.ts} Project ${this.info.project} Branch ${this.info.branch} CMS=${this.info.cms} MQTT=${this.info.mqtt},${this.rootTopic} -----------------------------------`);

    if ( this.rootTopic !== rootTopic ) {
      store.set(EKeys.MQTT_ROOT_KEY, this.rootTopic);
    }

    registerLocaleData(localeIt);

    metadata.preload();      // preload metadata that do not require any login

    this.initializeApp();
    this.setScannerRoute(this.platform.is('mobile'));

    this.platform.ready().then(async () => {
      logger.info(`APP.constructor: platform(s) [${this.platform.platforms().join(', ')}]`);
      if ( this.platform.is('mobile') ) {
        await this.getPermissions();
      }
      if ( this.platform.is('android') ) {
        setTimeout(() => {
          // this.manageUpdate();
        }, 2000);
      }
    });

    FingerprintJS.load().then(fp => {
      // The FingerprintJS agent is ready.
      // Get a visitor identifier when you'd like to.
      fp.get().then(result => {
        // This is the visitor identifier:
        const visitorId = result.visitorId;
        logger.info(`APP.constructor: visitor id=${visitorId} current=${deviceID} --${visitorId !== deviceID && deviceID ? 'ERROR' : 'OK' }----------------------------------------`);
        if ( !deviceID || deviceID.indexOf('D#') < 0 ) {
          that.deviceId = `D#${visitorId}`;
          store.set(EKeys.DEVICE_KEY, that.deviceId);
        }
      });
    });

    this.startMonitor();
  }


  startMonitor() {
    const that  = this;
    const clientTopic = `${this.rootTopic}/${this.clientTopic}`;
    const systemTopic = `${this.rootTopic}/${this.systemTopic}`;
    console.log(`APP.startMonitor: client ${clientTopic}, system ${systemTopic}`);
    this.mqttService.observe(clientTopic).subscribe((mqttMessage: IMqttMessage) => {
      let message: IHermesMessage = null;
      try {
        message  = JSON.parse(mqttMessage.payload.toString());
      } catch (e) {
        console.error(`hResponse: CLIENT ERROR ${e.toString()}`);
      }
      console.log(`APP.MQTT: client got ${mqttMessage.payload.toString()}`);
      if ( !message || (message.to && message.to !== that.deviceId)) {
        return;
      }
      switch (message.action) {
        case EClientAction.MESSAGE:
          if ( message.data && (message.data.message || message.data.msg) ) {
            that.toast.showMessage(message.data.message || message.data.msg);
          }
          break;
        case EClientAction.NEW:
        case EClientAction.INFO:
        case EClientAction.RESPONSE:
        case EClientAction.LOGOUT:
        case EClientAction.TEST:
      }
    });
    this.mqttService.observe(systemTopic).subscribe((mqttMessage: IMqttMessage) => {
      let message: IHermesMessage = null;
      let data: any = null;
      // console.log(`APP.MQTT: system got ${message}`);
      try {
        message = JSON.parse(mqttMessage.payload.toString());
        data    = message.data;
      } catch (e) {
        console.error(`hResponse: SYSTEM ERROR ${e.toString()}`);
      }
      if ( !message ) {
        return;
      }
      switch (message.action) {
        case ESystemAction.KEEPALIVE:
          const info: IHermesMessage = {
            action: ESystemAction.ANNOUNCE,
            from: that.deviceId,
            to: message.from,
            timestamp: moment().format('YYYY-MM-DD HH:mm'),
            data: {
              type: 'device',
              identity: that.deviceId,
              project: that.info.project,
              message: `Hello from client ${that.deviceId}`,
            }
          };
          that.mqttService.unsafePublish(`${this.rootTopic}/${this.systemTopic}`, JSON.stringify(info), {qos: 1, retain: false});
          break;
        case ESystemAction.ALIVE:
        case ESystemAction.EMAIL:
        case ESystemAction.FAILURE:
        case ESystemAction.TEST:
      }
    });
    this.mqttService.onConnect.subscribe(msg => {
      console.log('MQTT.connect', msg);
    });
    this.mqttService.onClose.subscribe(msg => {
      console.log('MQTT.close', msg);
    });
    this.mqttService.onOffline.subscribe(msg => {
      console.log('MQTT.offline', msg);
    });
    this.mqttService.onError.subscribe(msg => {
      // console.log('MQTT.error', msg);
    });
    this.mqttService.onMessage.subscribe(msg => {
      // console.log('MQTT.message', msg);
    });
  }

  /**
   * Set the route to the correct QR Code scanner
   * @param isMobile true if mobile device
   */
  setScannerRoute(isMobile) {
    const page = this.loggedInPages.find( pp => pp.icon === 'qr-scanner');
    if ( page ) {
      page.url = isMobile ? '/hscanner-mobile' : '/hscanner-web';
    }
  }

  /**
   * Asynchronously ask for permission
   * @param id the id of the permission
   * @param name the name of the permission
   */
  async askPermission(id, name) {
    const that = this;
    return new Promise((resolve, reject) => {
      this.androidPermissions.checkPermission(id).then(
        result => {
          console.log(`APP.getPermission: Has ${name} [${id}] permission? ${result.hasPermission ? 'YES' : 'NO'}`);
          if (!result.hasPermission) {
            this.androidPermissions.requestPermission(id).then( res => {
              console.log(`APP.getPermission: permission ${name} response ${JSON.stringify(res)}`);
              // alert(`Grazie, è ora necessario far ripartire la applicazione per attivare le funzionalità approvate!`);
              resolve({ error: false, message: 'ok', info: result });
            }).catch(error => {
              // alert(`Error! ${error}`);
              console.error(`APP.getPermission: permission ${name} not given`);
              resolve({ error: true, message: `Permesso ${name} non disponibile`, info: result });
            });
          }
        },
        err => {
          console.error(`APP.getPermission: asking permission ${name}`);
          this.androidPermissions.requestPermission(id);
        }
      ).catch(error => {
        console.error(`APP.getPermission: permission ${id} ERROR ${error.toString()}`);
        resolve({ error: true, message: error.toString(), info: error });
        // alert(`Error! ${error}`);
      });
    });
  }

  /**
   * Ask Android permission to get details about phone state
   */
  getPermissions() {
    const permissions = [
      { id: this.androidPermissions.PERMISSION.CAMERA                , name: 'CAMERA'         },
      { id: this.androidPermissions.PERMISSION.RESOURCE_VIDEO_CAPTURE, name: 'VIDEO CAPTURE'  },
      { id: this.androidPermissions.PERMISSION.READ_PHONE_STATE      , name: 'PHONE STATE'    },
      { id: this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION  , name: 'FINE LOCATION'  },
      { id: this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE , name: 'READ EXTERNAL'  },
      { id: this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE, name: 'WRITE EXTERNAL' }
    ];
    console.log(`APP.getPermissions: START -------------------------------------`);
    permissions.forEach( async permission => {
      await this.askPermission( permission.id, permission.name ).then(
        async function success(response: any) {
          if ( response.error ) {
            console.error(`APP.getPermissions: Error in permission ${permission.name}:${response.message}`);
          } else {
            console.log(`APP.getPermissions: Permission ${permission.name} granted`);
          }
        }
      ).catch( exception => {
        console.error(`APP.getPermissions: EXCEPTION ${exception.toString()} - Exception in permission ${permission.name}: ${exception}`, exception);
      });
    });
  }

  async initializeApp() {
    const that = this;

    // @see https://netbasal.gitbook.io/akita/getting-started/production-mode
    // @see https://engineering.datorama.com/building-production-quality-real-world-applications-with-angular-akita-bf18d516f2f7
    if (this.info.production) {
      enableAkitaProdMode();
    }

    // preload cache
    this.cacheService.loadDocuments();
    this.pathology = await this.cacheService.loadPathology();
    this.pathologyTitle = this.pathology ? this.pathology.title : 'pathology.covid';

    console.log(`APP.constructor: PATHOLOGY ${this.pathologyTitle} -----------------------------------`);

    this.router.events.subscribe( event => {
      // if (event instanceof NavigationError) {
        // console.error(`app.RouterEvent:`, event);
      // }
    });

    this.platform.ready().then(() => {
      that.statusBar.styleDefault();
      that.splashScreen.hide();
    });

    that.httpSpinner = await that.loadingCtrl.create({
      message: this.translateService.instant('common.loading'),
      spinner: 'circular',
      translucent: true
    });

    // monitor metadata
    this.metadata.preloadStatus.subscribe( status => {
      // console.log(`V4C APP: metadata status = '${status}'`);
      if ( status === EMetadataStatus.COMPLETE ) {
      }
    });

    // monitor http status
    this.httpService.loadingStatus.subscribe( data =>  {
      const info = data as HttpServiceInfo;
      // console.warn(`--->>> V4C APP HTTP STATUS ${JSON.stringify(info)}  online=${info.online} REQUESTS ${that.httpRequests}`);
      this.isConnected = info.online;
      if ( !data.spinner ) {
        return;
      }
      switch ( info.status ) {
        case HttpServiceStatus.START:
            if ( that.httpRequests <= 0 ) {
              // console.warn('--->>> V4C APP SHOW SPINNER');
              that.showSpinner();
            }
            that.httpRequests++;
            break;
        case HttpServiceStatus.COMPLETE:
        case HttpServiceStatus.FAILURE:
            if ( that.httpRequests > 0 ) {
              that.httpRequests--;
            }
            if ( that.httpRequests <= 0 ) {
              // console.warn('--->>> V4C APP HIDE SPINNER');
              that.hideSpinner();
            }
            break;
      }
    });

    // monitor authorization status
    this.authService.authenticationState.subscribe(state  => {
      // console.warn(`V4C APP: authenticationState = ${state}`);
      switch ( state ) {

        case AuthServiceStatus.USER_LOGGED_IN:
            // console.log('app.initializeApp: logged in, move to home page');
            that.menuTitle = that.authService.getName() ? that.authService.getName() : that.defaultTitle;
            that.isAuthenticated = true;
            that.roleType   = that.authService.getRoleType();     // get role type if authenticated
            that.isDoctor   = that.authService.isDoctor();        // true if user represents is a doctor
            that.isEditor   = that.authService.isEditor();        // true if user can edit some kind of data
            that.isAdmin    = that.authService.isAdministrator(); // true if user can administer the platform
            // that.authService.loadRoles();  // load user roles
            that.authService.getUserData();   // load user info
            setTimeout( () => {
              that.metadata.preload();        // preload metadata
            }, 500);
            // this.menu.enable(true, 'loggedInMenu');
            that.router.navigate(['home']);
            break;

        case AuthServiceStatus.USER_LOGGED_OUT:
            // console.log('V4C APP: logged out, move to login page');
            that.isAuthenticated = false;
            that.roleType   = '';
            that.isAdmin    = false;
            that.isMember   = false;
            that.isEditor   = false;
            that.isDoctor   = false;
            that.menuTitle  = that.defaultTitle;
            // this.menu.enable(false, 'loggedInMenu');
            that.router.navigate(['home']);
            break;
      }

      // console.warn('menuTitle: ' + that.menuTitle);
    });
/*
    this.subscribedChannel = this.notify.getChannel().subscribe((message: IMessage) => {
      console.log('APP: received message ' + JSON.stringify(message));
    });
*/
    await this.metadata.isReady();
  }

  private async showSpinner() {
    const that = this;
    await this.httpSpinner.present();
    setTimeout( () => {
      that.httpSpinner.dismiss();
    }, 3000);
  }

  private hideSpinner() {
    const that = this;
    if ( this.httpSpinner ) {
      setTimeout( () => {
        if ( that.httpRequests <= 0 ) {
          that.httpSpinner.dismiss();
        }
      }, 500);
    } else {
      console.error('app.hideSpinner: ERROR', this.httpSpinner);
    }
  }

  onLogout() {
    console.log('app.logout');
    this.authService.logout();
  }

  /**
   * Manage login
   */
  async onLogin( ) {
    console.warn(`app.onLogin`);
    // this.router.navigateByUrl('/appcompany/' + company.id);
    const modal = await this.modal.create({
      component: LoginModalPage,
      componentProps: {},
      cssClass: 'login-modal-css'
    });

    // on modal dismiss, handle provided action
    modal.onDidDismiss().then(this.handleAction);
    return await modal.present();
  }

  async onForgotPassword() {
    console.warn(`app.onForgotPassword`);
    // this.router.navigateByUrl('/appcompany/' + company.id);
    const modal = await this.modal.create({
      component: PasswordForgotModalPage,
      componentProps: {},
      cssClass: 'app-modal-css'
    });

    // on modal dismiss, handle provided action
    modal.onDidDismiss().then(this.handleAction);
    return await modal.present();
  }

  async onResetPassword() {
    console.warn(`app.onForgotPassword`);
    // this.router.navigateByUrl('/appcompany/' + company.id);
    const modal = await this.modal.create({
      component: PasswordResetModalPage,
      componentProps: {},
      cssClass: 'app-modal-css'
    });

    // on modal dismiss, handle provided action
    modal.onDidDismiss().then(this.handleAction);
    return await modal.present();
  }

  /**
   * Handles action
   * @param action action descriptor
   */
  // tslint:disable-next-line:variable-name
  private handleAction(_action: any) {
    const action = _action as IAction;
    console.log('APP.handleAction.onDidDismiss: onDidDismiss', action);
    if ( !action || typeof action.type === undefined ) {
      console.error('APP.handleAction.onDidDismiss: NO ACTION', action);
      return;
    }
    switch (action.type) {
      case EAction.CANCEL:
          console.log('APP.handleAction.onDidDismiss: nothing to do');
          return;
      case EAction.DELETE:
          console.log('APP.handleAction.onDidDismiss: delete');
          return;
      case EAction.CREATE:
        console.log('APP.handleAction.onDidDismiss: create');
        return;
      case EAction.UPDATE:
        console.log('APP.handleAction.onDidDismiss: update');
        return;
      case EAction.SUCCESS:
        console.log('APP.handleAction.onDidDismiss: success');
        this.isAuthenticated = true;
        return;
      case EAction.FAILURE:
        console.log('APP.handleAction.onDidDismiss: failure');
        return;
      case EAction.GOTO:
        console.log('APP.handleAction.onDidDismiss: goto');
        return;
      default:
        // console.error(`APP.handleAction.onDidDismiss: unknown action type ${action.type}`, action);
    }
  }

}
