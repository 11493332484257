/**
 * Gestione cambio password
 */
import { Component, OnInit, Input } from '@angular/core';
import { Router            } from '@angular/router';
import { Storage           } from '@ionic/storage';
import { AlertController   } from '@ionic/angular';
import { TranslateService  } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ModalController, NavParams } from '@ionic/angular';

import { ApiService, ECollection } from '../../services/api.service';
import { AuthService     } from '../../services/auth.service';
import { ToastService    } from '../../services/toast.service';

import { EKeys, IUser, IRole, EAction, IAction } from '../../models/global.models';

@Component({
  selector: 'app-passwordforgot-modal',
  templateUrl: './passwordforgot.modal.html',
  styleUrls: ['./passwordforgot.modal.scss'],
})
export class PasswordForgotModalPage implements OnInit {

  user: IUser  = null;
  userId: string = null;

  forgotPassForm: FormGroup;

  email: string = '';   // email to use for password change/recovery

  constructor(
    private router: Router,
    private api: ApiService,
    private storage: Storage,
    private auth: AuthService,
    private navParams: NavParams,
    private alert: AlertController,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private modalController: ModalController,
    private translateService: TranslateService) {
  }


  /**
   * initialise the modal
   */
  ngOnInit() {
    console.warn(`passwordForgotModal.ngOnInit:`);
    console.table(this.navParams);
    this.user   = this.navParams.data.user;         // the user to edit
    this.userId = this.navParams.data.userId;       // the id of the user to edit

    // if the user is authenticated, use account's email
    if ( this.user && this.user.email ) {
      this.email = this.user.email;
    }

    console.warn(`passwordForgotModal.ngOnInit: userid=${this.userId}`);
    console.table(this.user);

    this.forgotPassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

  }

  onSubmit() {

  }

  /**
   * Close the modal
   */
  async onGoBack() {
    console.warn('passwordForgotModal.onGoBack');
    await this.modalController.dismiss({
      type: EAction.CANCEL,
      data: null}, 'forgotpass');
  }

  /**
   * Ask if the user shall be deleted
   */
  async onSendForgotRequest() {
    console.warn('user.onSendChangeRequest:', this.email);
    const that  = this;
    const alert = await this.alert.create({
      header: 'Cancellazione',
      message: `Vuoi inviare una richiesta di ripristino password?`,
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Skip password reset');
          }
        }, {
          text: 'SI',
          handler: () => {
            that.sendForgotRequest();
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * Send the request to change the password
   */
  sendForgotRequest() {
    const that = this;
    this.email = this.email ? this.email : this.forgotPassForm.value.email;
    if ( !this.email || !this.email.length ) {
      console.error(`passwordForgotModal.sendForgotRequest: missing email`, this.forgotPassForm);
      return;
    }
    console.warn(`passwordForgotModal.sendForgotRequest: email=${this.email}`);
    this.auth.forgotPassword( this.email ).then(
      function success(response) {
        console.log('passwordForgotModal.onSubmit: SUCCESS', response);
        if ( response.error ) {
          that.toastService.showMessage(response.msg);
          that.modalController.dismiss({
            type: EAction.FAILURE,
            data: response}, 'forgotpass');
        } else {
          that.toastService.showMessage('Richiesta ripristino password inviata con successo');
          that.modalController.dismiss({
            type: EAction.SUCCESS,
            data: response}, 'forgotpass');
        }
      },
      function failure(error) {
        console.error('passwordForgotModal.onSubmit: ERROR', error);
        that.toastService.showMessage('Invio richiesta modifica password fallita');
      }
    ).catch(function exception(exc) {
      console.error('passwordForgotModal.onSubmit: EXCEPTION', exc);
      that.toastService.showMessage('Invio richiesta modifica password fallita');
    });
  }

}
