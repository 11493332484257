
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService  } from '@ngx-translate/core';

@Injectable()
export class SpinnerService {

    private loaderAnim: any;
    private total = 0;

    constructor( private loadingController: LoadingController, private translate: TranslateService) {
    }

    startLoad() {
        if ( !this.total ) {
            console.warn('spinnerService: activate animation');
            this.loaderAnim = this.loadingController.create({
                message: this.translate.instant('common.loading')
            }).then((res) => {
                res.present();
                res.onDidDismiss().then((dis) => {
                    console.warn('spinnerService: dismissed animation');
                });
            });
        }
        this.total ++;
        console.warn(`spinnerService.startLoad: total ${this.total}`);
    }

    endLoad() {
        this.total --;
        if ( this.total <= 0 ) {
            this.loadingController.dismiss();
        }
        console.warn(`spinnerService.endLoad: total ${this.total}`);
    }

}
