import { NgModule             } from '@angular/core';
import { CommonModule         } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule  } from '@ionic/angular';
// import { AngularSvgIconModule } from 'angular-svg-icon';

import { PasswordResetModalPage  } from './passwordreset.modal';

import { TranslateModule } from '@ngx-translate/core';
import { AuthService     } from '../../services/auth.service';

const routes: Routes = [
  {
    path: '',
    component: PasswordResetModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    RouterModule.forChild(routes)
  ],
  declarations: [PasswordResetModalPage],
  providers: [AuthService]
})
export class PasswordResetModalPageModule {}
