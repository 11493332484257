/**
 * Backend services via GraphQL
 * @name    GRAPHQL_API
 * @file    api.graphql.js
 * @date    24/02/2021
 * @version 1.0.0
 * @author  L.Tavolato
 * @see https://about.lovia.life/strapi-graphql-authenticated-crud-operation/
 */

import { Injectable         } from '@angular/core';
import { ApolloQueryResult  } from '@apollo/client/core';
import { Apollo, gql        } from 'apollo-angular';
import { StoreService       } from './store.service';
import { UtilityService     } from './utility.service';
import { EnvironmentService } from './environment.service';
import { EKeys, IPathology, IRules } from '../models/hermes.models';

// convert MarkDown to HTML
import showdown from 'showdown/dist/showdown.js';

const GET_DOCUMENTS = gql`
    query GetDocuments($language: String) {
        documents(where: { language: $language }) {
            code
            title
            body
            language
        }
    }
`;

const GET_PATHOLOGY = gql`
    query GetPathology($code: String) {
        pathologies( where: { code: $code }) {
            id
            code
            name
            title
            description
        }
    }
`;

const GET_PATHOLOGY_AND_RULES = gql`
    query FindPathology( $code: String! ) {
        pathologies(where: { code: $code }) {
            id
            code
            name
            title
            description
            rules {
                id
                code
                name
                version
                language
                messageStart
                messageEnd
                audioMinSeconds
                audioMaxSeconds
                directions
            }
        }
    }
`;

const LOGIN = gql`
    mutation login( $identifier: String!, $password: String! ) {
        login(
            input: {
                identifier: $identifier
                password: $password
                provider: "local"
            }
        ) {
            jwt
            user {
                username,
                email,
                role {
                    name,
                    type
                }
            }
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    deviceID  = null;
    documents = [];
    converter = null;
    language  = 'IT';
    pathology: IPathology = null;
    rules: IRules = null;

    constructor(private apollo: Apollo, private store: StoreService, private envService: EnvironmentService, private utility: UtilityService) {
        this.converter = new showdown.Converter();
        this.deviceID  = store.get(EKeys.DEVICE_KEY);
        this.language  = (envService.getLanguage() || 'it').toUpperCase();

        if ( !this.apollo ) {
            console.error(`cacheService.constructor: cannot get Apollo client`);
        } else {
            console.log(`cacheService.constructor: Apollo client ready`);
        }

        // caches documents
        this.loadDocuments().then( docs => {}).catch( error => { console.error(`cacheService: ERROR ${error.toString()}`); });

        // caches pathology and rules
        this.loadPathologyAndRules();
    }

    /**
     * read all the documents
     * @returns the list of document or error descriptor
     */
    loadDocuments() {
        return new Promise( resolve => {
            this.apollo.query<any>({ query: GET_DOCUMENTS, variables: { language: this.envService.getLanguage().toUpperCase() } }).subscribe(
                ({ data, loading, networkStatus })  => {
                    console.log(`cacheService.loadDocuments: loading=${loading} network=${networkStatus} documents`, data.documents);
                    const fields   = [ 'title', 'body'];
                    this.documents = this.markdownToHtml(data.documents, fields);
                    resolve(this.documents);
                },
                error => {
                    console.error(`cacheService.getDocuments: ERROR ${error.toString()} ===>>> `, error);
                    resolve({ error: true, message: error.toString(), data: error });
                }
            );
        });
    }

    /**
     * Return current pathology descriptor or error
     * @returns pathology or error descriptor
     */
    loadPathology(): Promise<any> {
        return new Promise( resolve => {
            if ( this.pathology ) {
                resolve(this.pathology);
                return;
            }
            this.apollo.query<any>({ query: GET_PATHOLOGY, variables: { code: this.envService.getPathologyCode() } }).subscribe(
                ({ data, loading, networkStatus })  => {
                    console.log(`cacheService.loadPathology: loading=${loading} network=${networkStatus} pathology`, data.pathologies);
                    this.pathology = Array.isArray(data.pathologies) ? data.pathologies[0] : data.pathologies;
                    resolve(this.pathology);
                },
                error => {
                    console.error(`cacheService.loadPathology: ERROR ${error.toString()} ===>>> `, error);
                    resolve({ error: true, message: error.toString(), data: error });
                }
            );
        });
    }

    async getRules() {
        if (this.rules) {
            return this.rules;
        }
        const pr = await this.loadPathologyAndRules().catch( err => {
            console.error(`cacheService.getRules: EXCEPTION ${err.toString()}\n`);
        }) as any;
        if ( !pr || pr.error ) {
            console.error(`cacheService.getRules: cannot get rules\n`);
            return null;
        }
        this.rules = pr.rules;
        return pr.rules;
    }

    /**
     * Load pathology data for COVID
     */
    loadPathologyAndRules() {
        const that = this;
        return new Promise( resolve => {
            const pathologyCode = this.envService.getPathologyCode();
            const pathologyLanguageCode = this.envService.getPathologyLanguageCode();
            if ( that.pathology && that.rules ) {
                // return cached information
                resolve({ pathology: that.pathology, rules: that.rules });
                return;
            }
            this.apollo.query<any>( { query: GET_PATHOLOGY_AND_RULES, variables: { code: pathologyCode } }).subscribe(
            ({ data, loading, networkStatus }) => {
                //  pathology exist?
                if ( !Array.isArray(data.pathologies) || !data.pathologies.length ) {
                    console.error(`cacheService.loadPathologyAndRules: cannot get pathology ${pathologyCode}\n-----\n\n`);
                    resolve({ error: true, message: `toast.pathology-data-unavailable`, data: { code: pathologyCode } });
                    return;
                }
                // console.log(`cacheService.loadPathology: ${JSON.stringify(data.pathologies, null, 4)}\n-----\n\n`);
                that.pathology = data.pathologies[0];                 // set pathology descriptor
                if ( !that.pathology.rules || !Array.isArray(that.pathology.rules) ) {
                    console.error(`cacheService.loadPathologyAndRules: CANNOT GET PATHOLOGY ${pathologyCode}\n-----\n\n`);
                    resolve({ error: true, message: `toast.no-pathology-rules`, data: that.pathology });
                    return;
                }
                const rulesArray: IRules[] = that.pathology.rules;
                that.rules = that.pathology.rules.find( r => r.code === pathologyLanguageCode); // get rules descriptor
                if ( !that.rules || !that.rules.id ) {
                    console.error(`cacheService.loadSurvey: CANNOT GET RULES FOR ${pathologyLanguageCode} => ${JSON.stringify(that.pathology.rules, null, 4)}`);
                    resolve({ error: true, message: `toast.rules-unavailable`, data: that.rules });
                    return;
                }
                resolve({ pathology: that.pathology, rules: that.rules });
            },
            (error) => {
                const msg = that.utility.getQueryError(error) || error.toString();
                console.error('cacheService.loadSurvey: ERROR ' + msg);
                resolve({ error: true, message: msg, data: { code: pathologyCode } });
            });
        });
    }

    /**
     * Return one, some or all the documents that has been cached
     * @param codes the list of document codes
     * @returns found documents
     */
    getDocs(codes: string[]): Promise<any[]> {
        let retries = 0;
        let postfix = '';
        if ( this.language !== 'IT' ) {
            postfix = `-${this.language}`;
        }
        return new Promise( resolve => {
            const check = () => {
                const docs = [];
                if ( this.documents && this.documents.length ) {
                    codes.forEach( code => {
                        const doc = this.documents.find( dd => dd.code === (code + postfix) );
                        if ( doc ) {
                            docs.push(doc);
                        }
                    });
                    resolve(docs);
                }
                retries++;
                if ( retries > 10 ) {
                    resolve(null);
                }
                setTimeout(check, 1000);
            };
            if ( !codes || !codes.length ) {
                resolve(this.documents);
                return;
            }
            check();
        });
    }

    /**
     * Return a single document
     * @param code unique identifier of the document
     * @returns the document
     */
    async getDoc(code) {
        const docs = await this.getDocs([code]);
        if ( docs && docs.length ) {
            return docs[0];
        }
        return null;
    }

    /**
     * Return pathology descriptor
     * @returns pathology descriptor
     */
    async getPathology() {
        return this.pathology;
    }

    /**
     * Perform the login
     * @param identifier username or email
     * @param password the password
     * @returns error data or token
     */
    login(identifier, password) {
        return new Promise( resolve => {
            console.log(`cacheService.login: identifier=${identifier} password=${password} apollo=${this.apollo ? 'OK' : 'KO'}`);
            return this.apollo.mutate<any>( { mutation: LOGIN, variables: { identifier, password } }).subscribe(
                ({data}) => {
                    console.log(`cacheService.login: got data`, data.login);
                    resolve(data.login);
                },
                (error) => {
                    console.error(`cacheService.login:: ERROR ${error.toString()} => ${JSON.stringify(error, null, 4)}`);
                    resolve({ error: true, message: error.toString(), data: error });
                }
            );
        });
    }

    /**
     * Convert some fields of an array or each element of the array from markdown to html
     * @param data array of strings or json objects with markdown content(s)
     * @param fields optional array of fields whose data shall be converted
     */
    markdownToHtml(data, fields) {
        // console.log(`cacheService.markdownToHtml: fields [${fields ? fields.join(', ') : ''}] of`, data);
        if ( Array.isArray(data) && this.converter && this.converter.makeHtml ) {
            const targetData = [];
            // scan the array elements
            data.forEach( el => {
                let element = { ...el};
                // console.log(`cacheService.markdownToHtml: element`, element);
                // fields defined?
                if ( fields && Array.isArray(fields) ) {
                    // scan the fields to convert
                    fields.forEach( field => {
                        // console.log(`cacheService.markdownToHtml: field ${field}`, element[field]);
                        if ( element[field] && element[field].length ) {
                            element[field] = this.converter.makeHtml(element[field]);
                        } else {
                            console.error(`cacheService.markdownToHtml: field ${field} does not exist in`, element);
                        }
                    });
                } else {
                    // no, convert the whole element
                    element = this.converter.makeHtml(element);
                }
                targetData.push( element );
            });
            // console.log(`cacheService.markdownToHtml: converted data ${this.utility.stringify(targetData)}`);
            return(targetData);
        }
        console.error(`cacheService.markdownToHtml: cannot convert from markdown to html`);
        return data;
    }

    htmlToText(data, fields) {
        const targetData = [];
        (data || []).forEach( el => {
            const element = { ...el };
            (fields || []).forEach( field => {
                if ( element[field] && element[field].length ) {
                    element[field] = element[field].replace( /(<([^>]+)>)/ig, '');
                }
            });
            targetData.push( element );
        });
        return(targetData);
    }

}
