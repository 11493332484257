/**
 * @name    Recording Service - record audio and store any reference using audiodb
 * @file    recorder.service.js
 * @date    12/10/2020
 * @version 1.0.0
 * @author  L.Tavolato
 * @Note    TODO: adapt https://github.com/edimuj/cordova-plugin-audioinput since android.media.AudioRecord is required to manage quality audio
 * @see https://github.com/mohamad-wael/cordova-audio-recorder-plugin https://medium.com/analytics-vidhya/cordova-audio-recorder-plugin-tutorial-51217e504bd1
 * @see https://www.npmjs.com/package/cordova-plugin-advanced-nativeaudio
 * @see https://www.npmjs.com/package/cordova-plugin-audio-recorder good candidate
 * @see https://github.com/emj365/cordova-plugin-audio-recorder-api
 * @see https://github.com/apache/cordova-plugin-media
 */
import { Injectable         } from '@angular/core';
import { Platform           } from '@ionic/angular';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { File               } from '@ionic-native/file/ngx';
import { ISong              } from '../models/hermes.models';
import { AudiodbService     } from './audiodb.service';

@Injectable()
export class RecorderService {

    private recording: boolean = false;
    private audioPath: string;
    private audioName: string;
    private audioKey: string;
    private audio: MediaObject;

    constructor(
        private media: Media,
        private file: File,
        private platform: Platform,
        private songdbService: AudiodbService) {
    }

    private makeFileName() {
        const now =  new Date();
        return 'audio' + now.getDay() + '' + now.getMonth() + now.getHours() + '' + now.getMinutes() + '' + now.getSeconds() + '.aac';
    }

    /**
     * Return true if recording is going on, false otherwise
     */
    isRecording() {
        return this.recording;
    }

    /**
     * Start recording a new sound
     */
    startRecord(audioName?: string, audioKey?: string) {
        if ( this.isRecording() ) {
            this.stopRecord();
        }
        if ( audioName && audioName.length ) {
            this.audioName = audioName + '.aac';
        } else {
            this.audioName = this.makeFileName();
        }
        if ( audioKey ) {
            this.audioKey = audioKey;
        } else {
            this.audioKey = this.audioName.split('.')[0];
        }
        if (this.platform.is('ios')) {
            this.audioPath = this.file.documentsDirectory.replace(/file:\/\//g, '') + this.audioName;
        } else if (this.platform.is('android')) {
            this.audioPath = this.file.externalDataDirectory.replace(/file:\/\//g, '') + this.audioName;
        }
        this.audio = this.media.create(this.audioPath);
        this.audio.startRecord();
        this.recording = true;
        console.log(`recorderService.startRecord: START recording ${this.audioName} \nPath: ${this.audioPath}\n\n`);
    }

    /**
     * Stop the recording process, release used resources and update audio db
     */
    async stopRecord(): Promise<ISong> {
        this.audio.stopRecord();
        this.audio.release();
        console.log(`recorderService.stopRecord: STOP recording ${this.audioName} \nPath: ${this.audioPath}\n\n`);
        const song: ISong = {
            id:   null,
            key:  this.audioKey,
            name: this.audioName,
            path: this.audioPath
        };
        // update the song db
        await this.songdbService.setItem(song.key, song);
        this.recording = false;
        return song;
    }

}
