/**
 * @name    Metadata - caches metadata from json files or collections. Sends updated metadata on any change to all listeners
 * @file    metadata.service.js
 * @date    02/10/2019
 * @version 1.0.0
 * @author  L.Tavolato
 */
import { Injectable         } from '@angular/core';
import { Router             } from '@angular/router';
import { HttpClient         } from '@angular/common/http';
import { ToastService       } from './toast.service';
import { UtilityService     } from './utility.service';
import { BehaviorSubject    } from 'rxjs';
import { AuthService        } from './auth.service';
import { StoreService       } from './store.service';
import { Apollo, gql        } from 'apollo-angular';
import { ECollection        } from '../models/hermes.models';
import { EnvironmentService } from './environment.service';

// import Remarkable from 'remarkable/dist/remarkable.js';

// convert MarkDown to HTML
import showdown from 'showdown/dist/showdown.js';

export enum EMetadataType {
    DB   = 'db',
    JSON = 'json'
}

export enum EMetadataFormat {
    MD2HTML = 'markdownToHtml'
}

export enum EMetadataStatus {
    IDLE     = 'idle',
    LOADING  = 'loading',
    COMPLETE = 'complete',
    UPDATE   = 'update',
    NOT_AUTHENTICATED = 'noauth'
}

export interface IMetadataInfo {
    topic: ECollection;
    data: any;
}

export interface IMetadataFile {
    topic: ECollection;
    type: EMetadataType;
    reqAuth?: boolean;
    format?: EMetadataFormat;        // the name of the formatter to use
    loader?: {()};  // function
    info?: any;
    data: any;
}

export interface IMetadataQueueElement {
    retry: number;
    topic: ECollection;
    refreshFlag: boolean;
    loader?: {()};  // function;
    next?: {()};    // function;
}

// data type for sorting
export enum ESortDataType {
    NUMERIC,
    ALPHABETIC,
    ARRAY,
    DATE
}

// direction for sorting
export enum ESortDirectionType {
    ASCENDING,
    DESCENDING
}

export interface IMetadataError {
    error: number;      // 1-n, internal error code
    code: number;       // external error code, service and context dependent
    message: string;    // any message that describes the error
    data: any;          // any detail about the error
}

const GET_DOCUMENTS = gql`
    query GetDocuments {
        documents {
            code
            title
            body
        }
    }
`;

const GET_PATHOLOGIES = gql`
    query GetPathologies {
        pathologies {
            id
            code
            name
            description
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class MetadataService {

    public preloadStatus: BehaviorSubject<EMetadataStatus> = new BehaviorSubject<EMetadataStatus>( EMetadataStatus.IDLE );
    public updateInfo: BehaviorSubject<IMetadataInfo> = new BehaviorSubject<IMetadataInfo>( null );

    // the list of metadata to preload
    private metadataFile: IMetadataFile[] = [
        { topic: ECollection.COUNTRY    , type: EMetadataType.JSON, info: 'assets/data/nazioni.json' , data: null },
        { topic: ECollection.DISTRICT   , type: EMetadataType.JSON, info: 'assets/data/province.json', data: null },
        { topic: ECollection.DOCUMENTS  , type: EMetadataType.DB  , loader: this.loadDocuments       , data: null , format: EMetadataFormat.MD2HTML },
        { topic: ECollection.PATHOLOGIES, type: EMetadataType.DB  , loader: this.loadPathologies     , data: null }
    ];

    private queue: IMetadataQueueElement[] = [];  // queue for trying reloading metadata on error

    private ready = false;              // flag if all metadata is ready
    private filters: any = {};          // any default param to apply
    private metadata = new Map();       // table of loaded metadata
    private listeners: any = {};        // table of listeners
    private subscribers: any = {};      // table of subscribers
    private converter = null;           // markdown to html converter
    private language = 'IT';

    constructor(private router: Router,
                private apollo: Apollo,
                private http: HttpClient,
                private auth: AuthService,
                private store: StoreService,
                private toast: ToastService,
                private utility: UtilityService,
                private environment: EnvironmentService) {
        const that = this;
        this.language  = (environment.getLanguage() || 'it').toUpperCase();
        this.converter = new showdown.Converter();
        try {
            if ( !this.converter || !this.converter.makeHtml ) {
                console.error('metadata: cannot create converter');
            } else {
                // console.log('metadata: converter OK ' + this.converter.makeHtml('\n\n\n# foo\n\n\nbar\n# baz'));
            }
        } catch (e) {
            console.error('metadata: ERROR ' + e.toString());
        }
        that.preload();    // preload metadata
    }

    /**
     * Return synchronously true when all the metadata has been loaded
     * On timeout (i.e. maxAttempts reached), return false
     */
    async isReady() {
        const that = this;
        return new Promise( async (resolve, reject) => {
            const maxAttempts = 20;
            const timeout = 1000;
            let attempts = 0;
            /*
            if ( await that.auth.isAuthenticatedSync() ) {
                return false;
            }
            */
            function refresh() {
                if ( that.ready ) {
                    resolve ( true );   // all data is available
                }
                if ( attempts >= maxAttempts ) {
                    resolve ( false );  // timeout
                }
                attempts++;
                setTimeout(refresh, timeout);
            }
            setTimeout(refresh, 100);
        });
    }

    /**
     * Preload all the metadata
     */
    async preload() {
        const that = this;
        this.ready = false;
        console.log(`metadata.preload: authenticated ${that.auth.isAuthenticatedSync()}`);
        return new Promise( async (resolve, reject) => {
            this.preloadStatus.next(EMetadataStatus.LOADING);
            for ( const element of that.metadataFile ) {
                // check if authentication is required
                if ( element.reqAuth && !that.auth.isAuthenticatedSync() ) {
                    console.error('metadata.preload: SKIP, user not authenticated!');
                    continue;
                }
                if ( element.loader && typeof element.loader === 'function' ) {
                    // console.warn(`metadata.preload: loading DB ${element.topic} ----`);
                    await element.loader.apply(that).then(
                        function onSuccess( response ) {
                            // console.log(`metadata.preload: ${element.topic} response`, response);
                            if ( !response.error ) {
                                that.metadata.set(element.topic, that.format( element.format, response));
                                that.fire(element.topic, that.metadata.get(element.topic));
                                that.updateInfo.next({ topic: element.topic, data: response });
                            }
                        },
                        function onFailure( error ) {
                            console.error(`metadata.preload: ERROR ${error.toString()}\nELEMENT=${JSON.stringify(element, null, 4)}\n`);
                        }
                    );
                } else {
                    // console.warn(`metadata.preload: loading JSON ${element.topic} ----`);
                    // load a local json file
                    that.loadJsonFile(element.topic, element.info, (error, data) => {
                        if (data) {
                            this.metadata.set(element.topic, data);
                        }
                    });
                }
            }
            this.ready = true;
            // console.warn('metadata.preload: preload completed!');
            that.preloadStatus.next(EMetadataStatus.COMPLETE);
            resolve(true);
        });
    }

    /**
     * Returns a unique id
     */
    private getUniqueID() {
        function chr4() {
            return Math.random().toString(16).slice(-4);
        }
        return `${chr4()}${chr4()}`;
    }

    /**
     * Load a json file
     * @param file path and name of the file to load
     * @param callback the callback that shall receivce error or loaded metadata
     */
    private loadJsonFile(topic: ECollection, file: string, next) {
        const that = this;
        that.metadata.set(topic, null);
        this.http.get(file).subscribe( response => {
                const body: any[] = response as any[];
                // console.log(`metadata.loadJsonFile: RESPONSE - topic=${topic}=${body.length} items`);
                // console.warn('metadata.loadJsonFile: response', response, 'JSON', response.json());
                if (response) {
                    that.metadata.set(topic, body);
                    that.fire(topic, body);
                    if ( next && typeof next === 'function') { next(null, response); }
                } else {
                    that.toast.showMessage('toast.unavailable-service', topic);
                    console.error(`metadata.loadJsonFile: cannot load ${file}`);
                    if ( next && typeof next === 'function') {
                        const mError: IMetadataError = {
                            error: 1, code: 0, message: `Errore di lettura del json ${topic}`, data: null
                        };
                        next(mError, null);
                    }
                }
            },
            function failure(error) {
                that.toast.showMessage('toast.unavailable-service', topic);
                console.error(`metadata.loadJsonFile: ${file} load error`, error);
                if ( next && typeof next === 'function') {
                    const mError: IMetadataError = {
                        error: 2, code: error.code || 0, message: `Errore di lettura del topic ${topic}`, data: error
                    };
                    next(mError, null);
                }
            }
        );
    }

    /**
     * Add a new element to the retry queue
     * @param topic the name of the collection to load
     * @param filter any filter to be applied to the collection
     * @param refreshFlag true if it must be refreshed
     * @param next any callback that will receive loaded metadata or error => next(error_descriptor, metadata);
     */
    addToQueue(topic: ECollection, refreshFlag: boolean, next?) {
        this.queue.push({ retry: 1, topic, refreshFlag, next });
    }

    /**
     * read all the documents
     * @returns the list of document or error descriptor
     */
    loadDocuments() {
        const that = this;
        return new Promise( resolve => {
            that.apollo.query<any>({ query: GET_DOCUMENTS }).subscribe(
                ({ data, loading, networkStatus })  => {
                    // console.log(`metadata.getDocuments: loading=${loading} network=${networkStatus} documents`, data.documents);
                    resolve(data.documents);
                },
                error => {
                    console.error(`metadata.getDocuments: ERROR ${error.toString()} ===>>> `, error);
                    resolve({ error: true, message: error.toString(), data: error });
                }
            );
        });
    }


    loadPathologies() {
        const that = this;
        return new Promise( resolve => {
            // console.log(`metadata.loadPathologies: START`);
            that.apollo.query<any>({ query: GET_PATHOLOGIES }).subscribe(
                ({ data, loading, networkStatus })  => {
                    // console.log(`metadata.loadPathologies: loading=${loading} network=${networkStatus} documents`, data.pathologies);
                    resolve(data.pathologies);
                },
                error => {
                    console.error(`metadata.loadPathologies: ERROR ${error.toString()} ===>>> `, error);
                    resolve({ error: true, message: error.toString(), data: error });
                }
            );
        });
    }

    /**
     * Return the whole db or found db item
     * @param topic metadata topic
     * @param key unique item identifier within the topic arrays or the object to return
     * @param value name of the field that contains the value of the identifier (default is 'id')
     */
    get(topic: ECollection, key?: string | any, value?: string) {
        if ( !this.metadata.get(topic) ) {
            console.error(`metadata.get: collection ${topic} does not exist -> ${Array.from(this.metadata.keys()).join(', ')}`);
            return null;
        }
        const data = this.metadata.get(topic);
        if ( !key || !Array.isArray(data) ) {
            console.log(`metadata.get: returning collection ${topic}`, data);
            return data;
        }
        // check if the id is not a string
        if ( typeof key !== 'string' ) {
            return key;  // it is the object itself
        }
        if ( topic === ECollection.DOCUMENTS && this.language !== 'IT' ) {

        }
        const element = data.find( ee => ee[key] === value );
        if ( element ) {
            this.store.set(`${topic}-${key}-${value}`, element);   // save this element into the cache
            return element;
        }
        console.error(`metadata.get: collection ${topic} cannot find ${key}=='${value}' into`, data);
        return null;
    }

    /**
     * Return the whole db or found db items
     * @param topic metadata topic
     * @param key unique field identifier within the topic arrays or the object to return
     * @param values expected values for the field
     */
    gets(topic: ECollection, key?: string | any, values?: string[]) {
        try {
            const data = this.metadata.get(topic);
            if ( !data || !data.length ) {
                console.error(`metadata.get: collection ${topic} does not exist`, data);
                return null;
            }
            if ( !key || !values ) {
                // console.log(`metadata.get: returning collection ${topic}`, data);
                return data;
            }
            // check if the id is an object or a string
            if ( typeof key !== 'string' ) {
                return key;  // it is the object itself
            }
            const elements = [];
            values.forEach( vv => {
                const element = data.find ( ee => ee[key] === vv );
                if ( element ) {
                    this.store.set(`${topic}-${key}-${vv}`, element);   // save this element into the cache
                    elements.push(element);
                }
            });
            if ( elements.length ) {
                return elements;
            }
            console.error(`metadata.gets: collection ${topic} cannot find ${key}=='${values.join(', ')}' into`, data);
        } catch (error) {
            console.error(`metadata.gets: collection ${topic} key=${key} error=${error.toString()}`, error);
        }

        return [];
    }

    /**
     * add or replace an element
     * @param topic the db
     * @param newData the data to add
     * @param idField optional id field for managing replace
     * @return updated db
     */
    add(topic: ECollection, newData: any, idField?: string) {
        const fld  = idField ? idField : 'id';
        const data = this.metadata.get(topic) || [];
        for ( let i = 0; i < data.length; i++  ) {
            const item = data[i];
            if ( newData[fld] && item[fld] && item[fld] === newData[fld] ) {
                data[i] = newData;              // item replace
                this.metadata.set(topic, data); // update
                this.fire(topic, data);
                this.updateInfo.next({topic, data });
                return data;
            }
        }
        data.push(newData);
        this.metadata.set(topic, data); // update
        // send updated collection to any listener
        this.fire(topic, data);
        this.updateInfo.next({topic, data});
        return data;
    }

    /**
     * Remove the first element that contains a field with the define value
     * @param topic the dn
     * @param id the value of the id field
     * @param idField optional name of the field to use (default is 'id');
     * @return updated db
     */
    delete(topic: ECollection, id: any, idField?: string) {
        const fld  = idField ? idField : 'id';
        const data = this.metadata.get(topic) || [];
        for ( let i = 0; i < data.length; i++  ) {
            const item = data[i];
            if ( item[fld] === id ) {
                data.splice(i, 1);
                this.metadata.set(topic, data); // update
                this.fire(topic, data);
                this.updateInfo.next({topic, data});
                break;
            }
        }
        return data;
    }

    /**
     * Return synchronously a filtered array of metadata
     * @param topic metadata topic
     * @param field the name of the field
     * @param value the value of the field that shall be matched
     * @param sortField optional name of the field to be used for sorting
     * @param sortType optional field type
     * @param sortDirection optional sorting direction
     */
    filter(topic: ECollection, field: string, value: string, sortField?: string, sortType?: ESortDataType, sortDirection?: ESortDirectionType ) {
        if ( !field || typeof value === 'undefined' || !this.metadata.get(topic) ) {
            return this.metadata.get(topic);
        }
        let newData = [];
        const data = this.metadata.get(topic);
        for ( const item of data ) {
            if ( item[field] === value ) {
                newData.push(item);
            }
        }
        // shall sort?
        if ( newData.length && sortField && sortType && sortDirection ) {
            newData = this.sort( newData, sortField, sortType, sortDirection );
        }
        return newData;
    }

    /**
     * Send topic and its metadata to all the subscribers
     * @param topic the fired topic
     * @param data the fired data
     */
    private fire(topic: ECollection, data: any) {
        const topicListeners = this.listeners[topic];
        for (let i = 0; topicListeners && i < topicListeners.length; i++) {
            const listener = topicListeners[i];
            if (listener.callback) {
                listener.callback.apply(listener.context, [topic, data]);
            }
        }
    }

    /**
     * Add a topic listener
     * @param context subscriber's context
     * @param topic the subscribed topic
     * @param callback the callback that will receive the data
     */
    subscribe(context: any, topic: ECollection, callback: any) {
        const subscriberId = this.getUniqueID();
        if (typeof callback !== 'function') {
            return false;
        }
        if (!this.listeners[topic]) {
            this.listeners[topic] = [];
        }
        this.listeners[topic].push({ id: subscriberId, context, callback });
        this.subscribers[subscriberId] = topic;
        // metadata already available?
        if (this.metadata[topic]) {
            // yes, provide it
            callback.apply(context, [topic, this.metadata[topic]]);
        }
        return subscriberId;
    }

    /**
     * Removes a topic listener
     * @param id the id of the listener
     * @return true on success, false otherwise
     */
    unsubscribe(id: string) {
        const topic = this.subscribers[id];    // get the topic subscribed by subscriber
        if (!topic) {
            console.error(`metadata.unsubscribe: subscriber '${id}' does not exist`);
            return false;
        }
        const topicListeners = this.listeners[topic];
        for (let i = 0; i < topicListeners.length; i++) {
            if (topicListeners[i].id === id) {
                delete topicListeners[i];
                delete this.subscribers[id];
                this.listeners[topic] = topicListeners;
                return (true);
            }
        }
        console.error(`metadata.unsubscribe: cannot find registration of subscriber '${id}'`);
        return false;
    }

    /**
     * Sorts an array
     * @param data the array to be sorted
     * @param fieldId the name of the field to use for sorting
     * @param type the field type
     * @param direction sorting direction
     */
    sort(data: any[], fieldId: string, type: ESortDataType, direction: ESortDirectionType): any[] {
        switch ( type ) {

            case ESortDataType.ALPHABETIC:
                if (direction === ESortDirectionType.ASCENDING) {
                    data.sort((a, b) => (a[fieldId] < b[fieldId] ? -1 : (a[fieldId] > b[fieldId] ? 1 : 0)));
                } else {
                    data.sort((a, b) => (b[fieldId] < a[fieldId] ? -1 : (b[fieldId] > a[fieldId] ? 1 : 0)));
                }
                break;

            case ESortDataType.NUMERIC:
                    if (direction === ESortDirectionType.ASCENDING) {
                        data.sort( (a, b) =>  (a[fieldId] - b[fieldId]) );
                    } else {
                        data.sort( (a, b) => (b[fieldId] - a[fieldId]) );
                    }
                    break;

            case ESortDataType.DATE:
                    if (direction === ESortDirectionType.ASCENDING) {
                        data.sort( (a, b) => {
                            const aDate = new Date(a[fieldId]);
                            const bDate = new Date(b[fieldId]);
                            return ( aDate.getTime() - bDate.getTime());
                        } );
                    } else {
                        data.sort( (a, b) => {
                            const aDate = new Date(a[fieldId]);
                            const bDate = new Date(b[fieldId]);
                            return ( bDate.getTime() - aDate.getTime());
                        } );
                    }
                    break;

            default:
                console.error(`metadataService.sort: unsupported sort type #${type}`);
                break;
        }
        return data;    // sorted or unchanged
    }

    /**
     * Applies a formatter if any
     * @param formatterType the name of the formatter to apply
     * @param data the data to format
     */
    format(formatterType: EMetadataFormat, data: any[]) {
        if ( formatterType === EMetadataFormat.MD2HTML ) {
            const fields = [ 'title', 'body'];
            return this.markdownToHtml(data, fields);
        }
        return data;    // return as it is
    }

    /**
     * Convert some fields of an array or each element of the array from markdown to html
     * @param data array of strings or json objects with markdown content(s)
     * @param fields optional array of fields whose data shall be converted
     */
    markdownToHtml(data, fields) {
        // console.log(`metadataService.markdownToHtml: fields [${fields ? fields.join(', ') : ''}] of`, data);
        if ( Array.isArray(data) && this.converter && this.converter.makeHtml ) {
            const targetData = [];
            data.forEach( el => {
                let element = Object.assign({}, el);
                if ( fields && Array.isArray(fields) ) {
                    fields.forEach( field => {
                        if ( element[field] && element[field].length ) {
                            element[field] =  this.converter.makeHtml(element[field]);
                        } else {
                            console.error(`metadataService.markdownToHtml: field ${field} does not exist in`, element);
                        }
                    });
                } else {
                    element = this.converter.makeHtml(element);
                }
                targetData.push( element );
            });
            // console.log(`metadataService.markdownToHtml: converted data ${this.utility.stringify(targetData)}`);
            return(targetData);
        }
        console.error(`metadataService.markdownToHtml: cannot convert from markdown to html`);
        return data;
    }

}
