/**
 * Servizi per gestire la configurazione
 */
import { Injectable } from '@angular/core';

export class LoggingLevel {
    public static NONE    = 'none';
    public static VERBOSE = 'verbose';
    public static INFO    = 'info';
    public static WARNING = 'warning';
    public static ERROR   = 'error';
}

@Injectable({
    providedIn: 'root'
})
export class  ConfigService {
    loggingLevel: LoggingLevel = LoggingLevel.NONE;
    constructor() { }
}

