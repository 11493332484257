/**
 * Servizi per notificare che la app è in attesa di dati
 */
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
@Injectable()
export class BusyService {

    private loadingElement = null;
    // private loadingController = null;

    constructor(private loadingController: LoadingController) {
    }

    public async presentLoading(message: string, spinner = 'creascent', duration = 5000) {
        this.dismissLoading();
        this.loadingElement = await this.loadingController.create({
          message:  message,
          // duration: duration
        });
        // console.log('busyService.presentLoading: showing');
        return await this.loadingElement.present();
    }

    public dismissLoading() {
        if ( this.loadingElement && this.loadingController) {
            // console.log('busyService.dismissLoading: dismissing');
            this.loadingController.dismiss(this.loadingElement);
            this.loadingElement = null;
        } else {
            // console.log('busyService.dismissLoading: dismissing');
        }
    }

}
