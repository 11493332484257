/**
 * Simple publish/subscribe service
 *
 * Component that receives messages
 *
 * import { Component, OnDestroy    } from '@angular/core';
 * import { Subscription            } from 'rxjs';
 * import { NotifyService, IMessage } from 'notify.service';
 *
 * @Component({ selector: 'receiver', templateUrl: 'receiver.component.html' })
 * export class AppComponent implements OnDestroy {
 *     subscription: Subscription;
 *
 *     constructor(private notifyService: NotifyService) {
 *         this.subscription = this.notifyService.getChannel().subscribe((message:IMessage) => {
 *           if (message) {
 *             ... do something
 *           }
 *         });
 *     }
 *
 *     ngOnDestroy() {
 *         this.subscription.unsubscribe();
 *     }
 * }
 *
 * Component that sends messages
 *
 * import { Component, OnDestroy } from '@angular/core';
 * import { Subscription  } from 'rxjs';
 * import { NotifyService, IMessage } from 'notify.service';
 *
 * @Component({ selector: 'sender', templateUrl: 'sender.component.html' })
 * export class AppComponent implements OnDestroy {
 *     subscription: Subscription;
 *
 *     constructor(private notifyService: NotifyService) {}
 *
 *     sendTestMessage() {
 *         this.notifyService.send('my topic', 'my message');
 *     }
 * }
 */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IMessage {
    topic: string;
    sender: string;
    data: any;
}

@Injectable()
export class NotifyService {
    private subject = new Subject<IMessage>();

    send(topic: string, sender: string, data: any) {
        this.subject.next({ topic, sender, data });
    }

    clear() {
        this.subject.next();
    }

    getChannel(): Observable<IMessage> {
        return this.subject.asObservable();
    }
}
