import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule       } from '@angular/platform-browser';
import { RouteReuseStrategy  } from '@angular/router';
import { environment  } from '../environments/environment';

/*
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);
*/

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule  } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { IonicModule, IonicRouteStrategy  } from '@ionic/angular';
import { IonicStorageModule, Storage      } from '@ionic/storage';

import { ComponentsModule } from './components/components.module';

import { SplashScreen   } from '@ionic-native/splash-screen/ngx';
import { StatusBar      } from '@ionic-native/status-bar/ngx';
import { File           } from '@ionic-native/file/ngx';
import { Media          } from '@ionic-native/media/ngx';
// import { QRScanner      } from '@ionic-native/qr-scanner/ngx';
import { Device             } from '@ionic-native/device/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { BarcodeScanner     } from '@ionic-native/barcode-scanner/ngx';
import { AppUpdate          } from '@ionic-native/app-update/ngx';

import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

import { AppComponent     } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// add on
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import {NoopAnimationsModule} from '@angular/platform-browser/animations';

// material
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';

// manages localization
import {TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {TranslateHttpLoader              } from '@ngx-translate/http-loader';

// data table
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// graphql
import {APOLLO_OPTIONS} from 'apollo-angular';
import {InMemoryCache } from '@apollo/client/core';
import {HttpLink      } from 'apollo-angular/http';

import { IMqttServiceOptions, MqttModule, MqttService } from 'ngx-mqtt';

// services
import { AuthService           } from './services/auth.service';          // authorization management
import { AuthInterceptor       } from './services/auth.interceptor';      // authorization management
import { EnvironmentService    } from './services/environment.service';   // provides environment variables
import { StrapiService         } from './services/strapi.service';        // not used
import { ApiService            } from './services/api.service';           // provides high level interface to remote services
import { HttpService           } from './services/http.service';          // improves http and token management
import { StoreService          } from './services/store.service';         // manages a simple persistent storage
import { ToastService          } from './services/toast.service';         // manages popup messages
import { TokenService          } from './services/token.service';         // manages tokens
import { MetadataService       } from './services/metadata.service';      // retrieve and provide local data tables (i.e. nazioni, province, ...)
import { DatastoreService      } from './services/datastore.service';     // manages a permanent store for key/value pairs and temporary files
import { UtilityService        } from './services/utility.service';       // provides some utilities
import { ThemeService          } from './services/theme.service';         // activates any theme
import { BusyService           } from './services/busy.service';          // activates busy notification dialog
import { NetworkService        } from './services/network.service';       // monitor network activities
import { SpinnerService        } from './services/spinner.service';       // display spinner
import { Md5Service            } from './services/md5.service';           // calculates MD5 of a buffer
import { NotifyService         } from './services/notify.service';        // simple pub/sub notification service
import { RecorderService       } from './services/recorder.service';      // audio recording services
import { WavRecorderService    } from './services/wavrecorder.service';   // audio recording services
import { PlayerService         } from './services/player.service';        // audio playing services
import { AudiodbService        } from './services/audiodb.service';       // audio playing services
import { LinkService           } from './services/link.service';          // get news
import { MonitorService        } from './services/monitor.service';       // monitor MQTT notifications and acts accordingly if required
import { MqttNotifyService     } from './services/mqttnotify.service';    // manage mqtt services
import { DeviceDetailsService  } from './services/device.service';        // manage device information
import { ConfigurationService  } from './services/configuration.service'; // manage the configuration
import { ConfigService, LoggingLevel } from './services/config.service';  // manage configuration services - dummy
import { CacheService          } from './services/cache.service';         // backend services based on GraphQL
import { SecureStorageService  } from './services/securestore.service';   // backend services based on GraphQL

// modal pages
import { HPatientModalPageModule        } from './modals/hpatient/hpatient.module';
import { LoginModalPageModule           } from './modals/login/login.module';
import { PasswordResetModalPageModule   } from './modals/passwordreset/passwordreset.module';
import { PasswordForgotModalPageModule  } from './modals/passwordforgot/passwordforgot.module';

// pipes
import { MarkedPipe } from './pipes/marked.pipe';

// date and time management
import { MomentModule } from 'ngx-moment';

import { Observable, of} from 'rxjs';
import { map, catchError} from 'rxjs/operators';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
  // return new TranslateHttpLoader(httpClient);
}

// translation loader
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Token
export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get('access_token');
    },
    whitelistedDomains: ['localhost:5000']
  };
}

/**
 * Load the configuration at startup
 * @param http http library
 * @param cfgService configuration management
 */
export function loadConfig(http: HttpClient, cfgService: ConfigService): (() => Promise<boolean>) {
  return (): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      http.get('./assets/config.json')
        .pipe(
          map((cfg: any) => {
            // console.log(`=====>>> OK ${JSON.stringify(cfg)}`)
            cfgService.loggingLevel = cfg.loggingLevel;
            resolve(true);
          }),
          catchError(() => {
            // console.log(`=====>>> ERROR`)
            cfgService.loggingLevel = LoggingLevel.NONE;
            resolve(true);
            return of({});
          })
        ).subscribe();
    });
  };
}

const env: any = environment;
const prodFlag = environment.production;
const project  = env.project;
const config   = env[project];
const envMQTT  = config.mqtt;
const urlGQL   = `${prodFlag ? config.cms.prod : config.cms.dev}/graphql`;

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: env.production ? envMQTT.prod : envMQTT.dev,
  port:     envMQTT.port,
  protocol: (envMQTT.protocol === 'wss') ? 'wss' : 'ws',
  username: envMQTT.username,
  password: envMQTT.password,
  path: ''
};

console.log(`APP.MODULE:\nMQTT=${JSON.stringify(MQTT_SERVICE_OPTIONS)}\nGQL=${urlGQL}`);

@NgModule({
  declarations: [AppComponent, MarkedPipe],
  entryComponents: [
    /*
    StatusRenderComponent, BookRenderComponent, UsageRenderComponent,
    CompanyRenderComponent, EcarRenderComponent, ActionsRenderComponent
    */
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    ScrollingModule,
    MatButtonModule,
    AppRoutingModule,
    MatCheckboxModule,
    NgxDatatableModule,
    IonicModule.forRoot(),
    BrowserAnimationsModule,
    PasswordForgotModalPageModule,

    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    // custom components
    ComponentsModule,

    // modals
    LoginModalPageModule,
    HPatientModalPageModule,
    PasswordResetModalPageModule,

    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59
      }
    }),

    // pages
    // ReportPageModule,

    // local storage
    IonicStorageModule.forRoot({
      name: '__Voice4Health_db',                                       // db name
      driverOrder: ['sqlite', 'indexeddb', 'websql', 'localstorage']  // db engine priority order
    }),

    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),

    // Java Web Tokens
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage],
      }
    }),

    // Localization
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory, // (createTranslateLoader),
        deps: [HttpClient]
      }
    })

  ],

  providers: [
    StatusBar,
    SplashScreen,
    AndroidPermissions,

    // new
    File,
    Media,
    Device,
    StatusBar,
    // QRScanner,
    AppUpdate,
    Md5Service,
    ApiService,
    AuthService,
    BusyService,
    HttpService,
    BarcodeScanner,
    EnvironmentService,

    SplashScreen,
    ToastService,
    TokenService,
    StoreService,
    ThemeService,
    ConfigService,
    NotifyService,
    StrapiService,
    PlayerService,
    AudiodbService,
    SpinnerService,
    UtilityService,
    NetworkService,
    MetadataService,
    RecorderService,
    DatastoreService,
    MqttNotifyService,
    WavRecorderService,
    ConfigurationService,
    DeviceDetailsService,
    SecureStorageService,
    MonitorService,
    CacheService,
    LinkService,

    // setup language
    { provide: LOCALE_ID, useValue: 'it-IT' },

    // setup authorization interceptor
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},

    // setup routing
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    // setup configuration
    { provide: APP_INITIALIZER, useFactory: (envService: EnvironmentService, mqtt: MqttService, secure: SecureStorageService ) => () => {
        console.log('APP.MODULE', envService);
        // secure.init();                              // activate storage encryption/decryption service
        mqtt.connect(envService.getMqttOptions());  // connect to MQTT service
        envService.ready = true;                    // set ready flag
      }, deps: [ EnvironmentService, MqttService, SecureStorageService ], multi: true
    },

    // setup connection to graphql service
    {
      provide: APOLLO_OPTIONS,                    // Apollo client options
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),             // data store
          link: httpLink.create({
            uri: urlGQL,    // graphql link
            // headers: [ 'Access-Control-Allow-Origin': '*' ]
          }),
        };
      },
      deps: [HttpLink]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
