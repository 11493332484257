import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule  } from '@ionic/angular';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HPatientModalPage } from './hpatient.modal';
import { TranslateModule   } from '@ngx-translate/core';

// qrcode
import { QRCodeModule } from 'angularx-qrcode';
import { MomentModule } from 'ngx-moment';

const routes: Routes = [
  {
    path: '',
    component: HPatientModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    QRCodeModule,
    MomentModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    RouterModule.forChild(routes)
  ],
  declarations: [HPatientModalPage]
})
export class HPatientModalPageModule {}
