/**
 * @name    Audio File Playback and Audio File Manager
 * @file    player.service.js
 * @date    12/10/2020
 * @version 1.0.0
 * @author  L.Tavolato
 */
import { Injectable } from '@angular/core';
import { Platform   } from '@ionic/angular';
import { File       } from '@ionic-native/file/ngx';
import { Media, MediaObject, MEDIA_STATUS } from '@ionic-native/media/ngx';

import { AudiodbService     } from './audiodb.service';
import { ISong, ISongInfo   } from '../models/hermes.models';
import { BehaviorSubject    } from 'rxjs';
import { atob               } from 'atob';

export enum EPlayerSeek {
    START,
    END,
    BACKWARD,
    FORWARD
}

export interface IPlayerStatus {
    status: MEDIA_STATUS;
    duration: number;
    position: number;
    song: ISong;
}

@Injectable()
export class PlayerService {

    // the audio file descriptor
    songData: ISong = null;

    // the audio file on play
    fileOnPlay: MediaObject;

    // Initialize playing audio values
    isPlaying = false;
    isOnPlay = false;
    isReady = false;
    duration = -1;                 // the duration of the audio file
    position = 0;                  // current playback position
    status = MEDIA_STATUS.NONE;  // player status

    intervalMonitor: any;

    playerState: BehaviorSubject<IPlayerStatus> = new BehaviorSubject<IPlayerStatus>({ status: MEDIA_STATUS.NONE, duration: 0, position: 0, song: null });

    constructor(
        private file: File,
        private media: Media,
        public platform: Platform,
        private songdbService: AudiodbService) {
    }

    private getPath() {
        if (this.platform.is('ios')) {
            return this.file.documentsDirectory.replace(/file:\/\//g, '');
        } else if (this.platform.is('android')) {
            return this.file.externalDataDirectory.replace(/file:\/\//g, '');
        }
        return '';
    }

    /**
     * Read an audio file and return it as a base64 string
     * @param key the id of the audio file QFbg?Q7T3#
     */
    loadSong(key): Promise<ISongInfo> {
        return new Promise( (resolve, reject) => {
            if ( !key ) {
                console.error(`playerService.loadSong: song key undefined`);
                return reject(new Error(`playerService.loadSong: song key undefined`));
            }
            this.songdbService.getItem(key).then( async (data: ISong) => {
                try {
                    if ( !data ) {
                        const keys: string[] = await this.songdbService.keys();
                        console.error(`playerService.loadSong: cannot find song ${key} keys=${keys.join(', ')}`);
                        return reject(new Error(`Cannot find song ${key}`));
                    }
                    const pp = (data.path || '').split('/');
                    pp.pop();                                   // remove file name
                    const filePath = 'file:' + pp.join('/');    // make path without file name
                    const nn  = data.name.split('.');           // split name
                    const fileExt = nn.pop().trim();            // get file extension
                    const fileInfo: ISongInfo = {
                        name: data.name,
                        type: `audio/${fileExt}`,
                        ext: fileExt,
                        size: 0,
                        data: null
                    };

                    console.log(`playerService.loadSong: going to load\n-> name ${data.name}\n-> path ${filePath}\n--------\n`);

                    if (this.platform.is('ios') || this.platform.is('android')) {
                        this.file.readAsDataURL(filePath, data.name).then((base64File) => {
                            console.log(`playerService.loadSong: SUCCESS - read audio file ${data.name}: ${base64File.substr(0, 50)}...`);
                            const dd = base64File.split(',');
                            fileInfo.type = dd[0].trim();
                            fileInfo.data = dd[1].trim();
                            resolve(fileInfo);
                        }).catch((error) => {
                            console.error(`playerService.loadSong: exception reading audio file ${data.name} from ${filePath}/${data.name}: ${JSON.stringify(error)}`);
                            reject(error);
                        });
                    } else {
                        // TODO: browser version
                        resolve(fileInfo);
                    }
                } catch ( error) {
                    console.error(`playerService.loadSong: exception ${error.toString()}`);
                    reject(new Error(`playerService.loadSong: exception ${error.toString()}`));
                }
            });
        });
    }

    /**
     * Normalize file name and copy to work directory
     * @param id target id
     * @param extension the right extension
     * @param dataURI audio file as data URL
     */
    async saveSong(id: string, extension: string, dataURI: string) {
        const data64  = dataURI.indexOf(',') >= 0 ? dataURI.split(',')[1] : dataURI;    // get the base64 part of the data URL
        const buff    = atob(data64);                                                   // convert from base 64 to binary
        const newName = `${id}.${extension}`;                                           // target file name
        const path    = this.getPath();                                                 // target path
        const that    = this;                                                           // save context
        console.log(`playerService.saveFile: copy audio file ${newName}`);
        return new Promise((resolve, reject) => {
            this.file.writeFile(path, newName, buff).then(
                function success(data) {
                    console.log(`playerService.saveFile: audio file copied ${JSON.stringify(data)}`);
                    const song: ISong = {
                        id: null,
                        key: id,
                        name: newName,
                        path: path + '/' + newName
                    };
                    // update the song db
                    that.songdbService.setItem(song.key, song);
                    resolve(data);
                },
                function failure(error) {
                    console.log(`playerService.saveFile: failed saving ${newName}, ${error.toString()}`);
                    reject(error);
                }
            ).catch((error) => {
                console.log(`playerService.saveFile: failed saving ${newName}, ${error.toString()}`);
                reject(error);
            });
        });
    }

    /**
     * Remove all the songs that are in the audio database
     */
    async removeAllSongs() {
        const songs = await this.songdbService.getAll();    // load all the database of songs

        // delete songs one by one
        songs.forEach((song) => {
            const ss = song.path.split('/');    // get songs path + name
            const fileName = ss.pop();          // get song name
            const filePath = ss.join('/');      // get song path

            // Check if file exists
            this.file.checkFile(filePath, fileName).then(data => {
                console.log(`playerService.removeAllSongs: killing ${data}`);
                // Remove file from device
                this.file.removeFile(filePath, fileName).then( response => {
                    console.log(`playerService.removeAllSongs: ${fileName} killed`);
                    response.fileRemoved.getMetadata((metadata) => {
                        const size     = metadata.size;
                        const name     = response.fileRemoved.name;
                        const fullPath = response.fileRemoved.fullPath;
                        console.log(`playerService.removeAllSongs: deleted ${name} size=${size} path=${fullPath}`);
                        // console.log('playerService.removeAllSongs: Name: ' + name + ' / Size: ' + size);
                    });
                }).catch(error => {
                    // console.log('playerService.removeAllSongs: error deleting file from cache folder: ' + error);
                });
            }).catch(error => {
                // console.error('playerService.removeAllSongs: check file error -> ' + JSON.stringify(error, null, 4));
            });

        });
    }

    /**
     * Load and setup song for playback
     * @param id song id
     */
    play(key) {
        if (this.isOnPlay) {
            this.stop();
        }
        this.fileOnPlay = null;
        this.songdbService.getItem(key).then(data => {
            this.songData = data;
            this.setupPlayback();
        });
        return this.playerState;
    }

    pause() {
        if (this.fileOnPlay) {
            this.fileOnPlay.pause();
            this.isOnPlay = false;
        }
    }

    stop() {
        if (this.fileOnPlay) {
            this.fileOnPlay.stop();
            this.fileOnPlay.release();
            clearInterval(this.intervalMonitor);
            this.isReady = true;
            this.isOnPlay = false;
            this.duration = -1;
            this.position = 0;
        }
    }

    seek(action: EPlayerSeek) {
        const step = 15;
        const pos = this.position;
        if (!this.fileOnPlay || !this.isOnPlay) {
            return;
        }
        this.fileOnPlay.getCurrentPosition().then(msPosition => {
            const position = Math.round(msPosition / 1000);
            let newPosition = 0;
            switch (action) {
                case EPlayerSeek.START:
                    newPosition = 0;
                    break;
                case EPlayerSeek.END:
                    newPosition = this.duration;
                    break;
                case EPlayerSeek.BACKWARD:
                    newPosition = position < step ? 0.001 : position - step;
                    break;
                case EPlayerSeek.FORWARD:
                    newPosition = position + step < this.duration ? position + step : this.duration;
                    break;
                default:
                    break;
            }
            this.fileOnPlay.seekTo(newPosition * 1000);
            this.playerState.next({ status: this.status, duration: this.duration, position: newPosition, song: this.songData });
        });
    }

    /**
     * Setup file path, create media player, subscribe notifications and start playing
     */
    private setupPlayback() {
        const filePath  = this.songData.path;           //  this.getPath() + this.songData.name;  // make file absolute path
        this.fileOnPlay = this.media.create(filePath);  // create a media instance to play the file
        if (!this.fileOnPlay) {
            console.error(`playerService.setupPlayback: failed media create ${filePath}`);
            return -1;
        }
        this.fileOnPlay.onStatusUpdate.subscribe( (mediaStatus) => {
            this.playerState.next({ status: mediaStatus, duration: this.duration, position: this.position, song: this.songData });
            this.status = mediaStatus;
            switch (mediaStatus) {
                case MEDIA_STATUS.NONE:     // 0: cleanup
                    this.isOnPlay = false;
                    this.isPlaying = false;
                    break;
                case MEDIA_STATUS.STARTING: // 1: starting
                    this.isPlaying = false;
                    this.isOnPlay = false;
                    break;
                case MEDIA_STATUS.RUNNING:  // 2: playing
                    this.isPlaying = true;
                    this.isOnPlay = true;
                    break;
                case MEDIA_STATUS.PAUSED:   // 3: pause
                    this.isOnPlay = true;
                    this.isPlaying = false;
                    break;
                case MEDIA_STATUS.STOPPED:  // 4: stop
                default:
                    this.isOnPlay = false;
                    this.isPlaying = false;
                    this.stop();
                    break;
            }
        });

        console.log(`playerService: playing ${filePath}`);

        this.fileOnPlay.play();
        this.fileOnPlay.setVolume(0.8);                             // you don't want users to notice that you are playing the file
        this.intervalMonitor = setInterval(() => {
            if (this.duration === -1) {
                this.duration = +this.fileOnPlay.getDuration();     // update playback duration
            }
            this.fileOnPlay.getCurrentPosition().then(position => {
                this.position = position;                           // update playback position
            });
            this.playerState.next({ status: this.status, duration: this.duration, position: this.position, song: this.songData });
        }, 1000);
    }

}
