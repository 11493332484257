/**
 * Gestione della configurazione e dei dati statici
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

export interface ICurrency {
    code:   string;
    name:   string;
    symbol: string;
}

export interface INazione {
    id:         number; // 1,
    name:       string; // Afghanistan,
    isoAlpha2:  string; // AF,
    isoAlpha3:  string; // AFG,
    isoNumeric: number; // 4,
    currency:   ICurrency;
    flag:       string;
}

export interface IProvincia2 {
    sigla:      string; // BA,
    provincia:  string; // Bari,
    superficie: number; // 3825.41,
    residenti:  number; // 1246297,
    num_comuni: number; // 41,
    id_regione: number; // 13
}

export interface IComune {
    nome:            string;   // Abano Terme,
    codice:          string;   // 028001,
    zona:            string;   // codice zona: 2
    regione:         string;   // codice regione: 05
    provincia:       string;   // sigla provincia: RM
    codiceCatastale: string;   // A001,
    cap?:            number[]; // [35031],
    popolazione?:    number;   // 19349
}

export interface IRegione {
    codice:      string;   // 05,
    nome:        string;   // Veneto
}

export interface IProvincia {
    sigla:      string;   // RM,
    nome:       string;   // Roma
    codice:     string;   // 028,
    regione:    string;   // Lazio
}

export interface IComuneInput {
    nome:            string;   // Abano Terme,
    codice:          string;   // 028001,
    zona: {
        codice:      string;   // 2,
        nome:        string;   // Nord-est
    };
    regione: {
        codice:      string;   // 05,
        nome:        string;   // Veneto
    };
    provincia: {
        codice:      string;   // 028,
        nome:        string;   // Padova
    };
    sigla:           string;   // PD,
    codiceCatastale: string;   // A001,
    cap:             number[]; // [35031],
    popolazione:     string;   // 19349
}

export interface IParameter {
    id:   string;
    name: string;
}

export interface IConfiguration {
    profili:      IParameter[];
    patologie:    IParameter[];
    lingue:       IParameter[];
    microfoni:    IParameter[];
    registratori: IParameter[];
    ambienti:     IParameter[];
    posizioni:    IParameter[];
    eta:          IParameter[];
}

@Injectable()
export class ConfigurationService {

    private nazioni:      INazione  [] = [];
    private regioni:      IRegione  [] = [];
    private province:     IProvincia[] = [];
    private comuni:       IComune   [] = [];

    private zoneHash      = {};
    private regioniHash   = {};
    private provinceHash  = {};
    private comuniHash    = {};

    private profili:      IParameter[] = [];
    private patologie:    IParameter[] = [];
    private lingue:       IParameter[] = [];
    private microfoni:    IParameter[] = [];
    private registratori: IParameter[] = [];
    private ambienti:     IParameter[] = [];
    private posizioni:    IParameter[] = [];
    private eta:          IParameter[] = [];

    constructor(private http: HttpClient) {
        this.loadConfiguration();
        this.loadComuniProvince();
        this.loadNazioni();
    }

    getNazioni(): INazione[] {
        return this.nazioni;
    }

    getRegioni(): IRegione[] {
        return this.regioni;
    }

    getProvince(regione?: string): IProvincia[] {
        if ( regione ) {
            const pro = [];
            for ( let i = 0; i < this.province.length; i++) {
                if ( this.province[i].regione === regione ) {
                    pro.push(this.province[i]);
                }
            }
            return pro;
        }
        return this.province;
    }

    getComuni(sigla: string): IComune[] {
        if ( sigla && this.comuniHash[sigla]) {
            return this.comuniHash[sigla];
        }
        return [];
    }

    getProfili(): IParameter[] {
        return this.profili;
    }
    
    getPatologie(): IParameter[] {
        return this.patologie;
    }

    getLingue(): IParameter[] {
        return this.lingue;
    }

    getMicrofoni(): IParameter[] {
        return this.microfoni;
    }

    getRegistratori(): IParameter[] {
        return this.registratori;
    }

    getAmbienti(): IParameter[] {
        return this.ambienti;
    }

    getPosizioni(): IParameter[] {
        return this.posizioni;
    }

    getFasceEta(): IParameter[] {
        return this.eta;
    }

    private async loadNazioni() {
        const that = this;
        await this.http.get('assets/data/nazioni.json').toPromise().then(
            function success(res) {
                that.nazioni = <INazione[]>res;
                console.log('configuration.loadNazioni: OK, #' + that.nazioni.length + ' nazioni'); // , res); // that.nazioni);
            },
            function failure(error) {
                console.error('configuration.loadNazioni: ERROR', error);
            });
    }

    private async loadComuniProvince() {
        const that = this;
        await this.http.get('assets/data/comuni.json').toPromise().then(
            function success(res) {
                const data: IComuneInput[] = <IComuneInput[]>res;
                that.zoneHash     = {};
                that.regioniHash  = {};
                that.provinceHash = {};
                that.regioni      = [];
                that.province     = [];
                // console.log('configuration.loadComuniProvince: OK, #' + data.length + ' comuni'); // data);
                for ( let i = 0; i < data.length; i++ ) {
                    that.zoneHash[data[i].zona.codice] = data[i].zona;
                    that.regioniHash [data[i].regione.codice] = data[i].regione;
                    that.provinceHash[data[i].sigla] = {
                        sigla:   data[i].sigla,
                        nome:    data[i].provincia.nome,
                        codice:  data[i].provincia.codice,
                        regione: data[i].regione.nome
                    };
                    if ( !that.comuniHash[data[i].sigla] ) {
                        that.comuniHash[data[i].sigla] = [];
                    }
                    // add current comune to the array of comuni within its province
                    that.comuniHash[data[i].sigla].push({
                        nome:            data[i].nome,              // Abano Terme,
                        codice:          data[i].codice,            // 028001,
                        zona:            data[i].zona.codice,       // codice zona: 2
                        regione:         data[i].regione.codice,    // codice regione: 05
                        provincia:       data[i].sigla,             // sigla provincia: RM
                        codiceCatastale: data[i].codiceCatastale,   // A001,
                        cap:             data[i].cap,               // [35031],
                        popolazione:     data[i].popolazione        // 19349
                    });
                }
                // build the array of province
                for ( const key in that.provinceHash) {
                    if ( that.provinceHash[key]) {
                        that.province.push(that.provinceHash[key]);
                    }
                }
                // build the array of regioni
                for ( const key in that.regioniHash) {
                    if ( that.regioniHash[key]) {
                        that.regioni.push(that.regioniHash[key]);
                    }
                }
                console.log('configuration.loadComuniProvince: OK, #' + data.length + ' comuni #' + that.province.length + ' province #' + that.regioni.length + ' regioni');
            },
            function failure(error) {
                console.error('configuration.loadComuniProvince: ERROR', error);
            });
    }

    private async loadConfiguration() {
        const that = this;
        await this.http.get('assets/data/config.json').toPromise().then(
            function success(res) {
                const config: IConfiguration = <IConfiguration>res;
                that.profili      = config.profili;
                that.patologie    = config.patologie;
                that.lingue       = config.lingue;
                that.microfoni    = config.microfoni;
                that.registratori = config.registratori;
                that.ambienti     = config.ambienti;
                that.posizioni    = config.posizioni;
                that.eta          = config.eta;
                console.log('configuration.loadConfiguration: OK, #' + config.profili.length + ' profili #' + config.patologie.length + ' patologie #' + config.lingue.length + ' lingue #' + config.microfoni.length + ' microfoni #' + config.registratori.length + ' registratori #' + config.ambienti.length + ' ambienti #' + config.posizioni.length + ' posizioni'); // , res);
            },
            function failure(error) {
                console.error('configuration.loadConfiguration: ERROR', error);
            });
    }

}
