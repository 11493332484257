/**
 * @name    Audio DB manager - keep track of locally recorded files
 * @file    audiodb.service.js
 * @date    6/12/2020
 * @version 1.0.0
 * @author  L.Tavolato
 */
import { Injectable } from '@angular/core';
import { Storage    } from '@ionic/storage';
import { ISong      } from '../models/hermes.models';
import { File, RemoveResult } from '@ionic-native/file/ngx';

@Injectable({
    providedIn: 'root'
})
export class AudiodbService {

    auxSong: ISong;
    auxSongList: ISong[] = [];

    constructor(private storage: Storage, private file: File) { }

    // Stores a keyed value
    setItem(key: string, value: ISong): Promise<any> {
        return this.storage.set(key, { id: value.id, key: key, name: value.name, path: value.path })
            .then(
                (data)  => console.log(`audiodb.SetItem: stored ${JSON.stringify(data, null, 4)}`),
                (error) => console.error(`audiodb.SetItem: error ${error.toString()} storing item`, error)
            ).catch( reason => {
                console.error(`audiodb.SetItem: exception ${reason.toString()} storing item`, reason);
            });
    }

    // Gets a stored item
    getItem(key: string): Promise<ISong> {
        return this.storage.get(key);
    }

    // remove the file and the stored item
    async removeItem(key: string) {
        const item: ISong = await this.storage.get(key);
        if ( !item || !item.path ) {
            console.error(`audiodb.removeItem: cannot find item ${key}`);
            return false;
        }
        // file:///storage/emulated/0/616fcc2d5316da0ae41bec80_6172812d5316da0ae41bece2_RID7_raw.wav
        const path: string = `file://${item.path.split(item.name)[0]}`;
        console.log(`path: ${path}`);
        console.log(`name: ${item.name}`);
        try {
            const result: RemoveResult = await this.file.removeFile(path, item.name);
            if ( result.fileRemoved ) {
                await this.remove(key);
                return true;
            }
        } catch (error) {
            console.error(`audiodb.removeItem: ERROR ${error.toString()}`, error.stack);
        }
        console.error(`audiodb.removeItem: cannot remove item ${key}`);
        return false;
    }

    // remove all the files and stored items
    async removeAllItems() {
        const items: ISong[] = await this.getAll();
        const totalItems = items.length;
        let totalRemoved = 0;
        for ( const item of items ) {
            // file:///storage/emulated/0/616fcc2d5316da0ae41bec80_6172812d5316da0ae41bece2_RID7_raw.wav
            const path: string = `file://${item.path.split(item.name)[0]}`;
            // console.log(`path: ${path}`);
            // console.log(`name: ${item.name}`);
            try {
                // some files might not exist
                this.file.checkFile(path, item.name).then(async data => {
                    const result: RemoveResult = await this.file.removeFile(path, item.name);
                    if ( result.fileRemoved ) {
                        totalRemoved++;
                    }
                });
                await this.remove(item.key);
                console.log(`audiodb.removeAllItems: removed ${item.name}`);
            } catch (error) {
                console.error(`audiodb.removeAllItems: ERROR ${error.toString()}`, error.stack);
            }
        }
        console.log(`audiodb.removeAllItems: #${totalRemoved} items removed, expected ${totalItems}`);
        return totalRemoved;
    }

    // check if it is empty
    async isEmpty() {
        return await this.storage.keys()
            .then(
                (data)  => { return true  },
                (error) => { return false }
            ).catch( reason => {
                console.error(`audiodb.isEmpty: exception ${reason.toString()} storing item`, reason);
                return false;
            });
    }

    // Retrieving all keys
    keys(): Promise<string[]> {
        return this.storage.keys();
    }

    // Retrieving all values
    getAll(): Promise<ISong[]> {
        return new Promise( (resolve) => {
            // Clear any previous values
            this.auxSongList = [];
            return this.storage.keys().then(async (keys) => {
                for ( const key of keys) {
                    if ( key.length > 48 ) {
                        this.auxSongList.push(await this.getItem(key));
                    }
                }
                resolve(this.auxSongList.reverse());
            });
        });
    }

    // Removes a single stored item
    async remove(key: string) {
        await this.storage.remove(key)
            .then(
                data => {
                    console.log(data);
                },
                error => {
                    console.error(error);
                }
            );
    }

    // Removes all stored values.
    clear() {
        this.storage.clear()
            .then(
                data => console.log(data),
                error => console.error(error)
            );
    }

    // Return the number of stored items
    items(): Promise<number>  {
        return this.storage.length();
    }

}
