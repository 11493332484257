import { NgModule     } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule  } from '@angular/forms';
import { IonicModule  } from '@ionic/angular';

import { RecorderMobileComponent } from './recorder-mobile/recorder-mobile.component';
import { RecorderWebComponent    } from './recorder-web/recorder-web.component';
import { PlayerMobileComponent   } from './player-mobile/player-mobile.component';
import { PlayerWebComponent      } from './player-web/player-web.component';
import { QuestionComponent       } from './question/question.component';

// from the outside
import { HoldableDirective        } from './audio-recording/holdable.directive';
import { AudioRecordingComponent  } from './audio-recording/audio-recording.component';
import { AudioPlayerComponent     } from './audio-recording/audio-player.component';

import { SoundRecorderPage        } from './sound-recorder/sound-recorder.component';

const components = [
  QuestionComponent,
  PlayerWebComponent,
  RecorderWebComponent,
  PlayerMobileComponent,
  RecorderMobileComponent,
  AudioRecordingComponent,
  AudioPlayerComponent,
  SoundRecorderPage,
  HoldableDirective
];

@NgModule({
  imports: [
    IonicModule,
    FormsModule,
    CommonModule,
  ],
  declarations: [...components],
  exports:      [...components]
})
export class ComponentsModule {}
