/***
 * NON USATO
 */
import { Injectable        } from '@angular/core';
import { JwtHelperService  } from '@auth0/angular-jwt';
import { EKeys, ITokenData } from '../models/global.models';

@Injectable()
export class TokenService {

  constructor(private helper: JwtHelperService) {
    const token = this.token;
    console.log('tokenService: START ' + (token ? (token.substring(0, 20) + '...') : '--no token--'));
  }

  /**
   * Returns the encoded token
   */
  get token() {
    const tkn = localStorage.getItem(EKeys.TKN_CACHE_KEY);
    if ( !tkn ) {
      // console.log('tokenService: get token - NO TOKEN');
      return null;
    }
    // console.log('tokenService: get token ' + tkn.substring(0, 20) + '...');
    return tkn;
  }

  /**
   * Update the token
   */
  set token(value: string) {
    // console.log('tokenService: set token ' + value.substring(0, 20) + '...');
    localStorage.setItem(EKeys.TKN_CACHE_KEY, value);
  }

  /**
   * Return the decoded token
   */
  get tokenData(): ITokenData {
    const token = localStorage.getItem(EKeys.TKN_CACHE_KEY);
    if (token) {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch (error) {
        console.error('tokenService.tokenData: EXCEPTION ', error, '---TOKEN---', token, '------');
      }
    }
    return null;
  }

  /**
   * Removes the token (i.e. logout user)
   */
  deleteToken() {
    localStorage.removeItem(EKeys.TKN_CACHE_KEY);
  }

  /**
   * Return true if toexpired, false otherwise
   */
  expired() {
    if (!this.tokenData) {
      // console.error('tokenService.expired: NO TOKEN');
      return true;
    }
    const expired = this.helper.isTokenExpired(this.token);
    if ( expired ) {
      console.error(`tokenService: TOKEN EXPIRED!`);
    }
    return expired;
  }

  isAuthenticated() {
    return !this.expired();
  }

}
