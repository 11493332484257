import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Platform, AlertController, ToastController } from '@ionic/angular';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { File } from '@ionic-native/file/ngx';
import { Router } from '@angular/router';
import { ISong } from '../../models/hermes.models';
import { AudiodbService } from '../../services/audiodb.service';

@Component({
    selector:    'sound-recorder',
    templateUrl: 'sound-recorder.component.html',
    styleUrls: [ 'sound-recorder.component.scss'],
})
export class SoundRecorderPage implements OnInit {

    @Input() filename: string = 'audio';
    @Input() seconds: number  = 60;                   // maximum length in seconds of the recording, 0=no limit

    @Output() record: EventEmitter<ISong> = new EventEmitter<ISong>();

    filePath: string;
    fileName: string;

    // record
    isRecording: boolean = false;
    fileOnRecord: MediaObject;

    audioCurrent: ISong = null;
    audioList: ISong[] = [];

    private start = 0;
    time = '00:00:00';

    // play
    fileOnPlay: MediaObject = null;
    intervalDuration = null;
    intervalPosition = null;
    duration = null;
    isOnPlay = false;
    isPlaying = false;
    isReady = false;
    playStatus = null;
    position = 0;

    constructor(
        private file: File,
        private media: Media,
        private route: Router,
        public platform: Platform,
        public alertController: AlertController,
        private songdbService: AudiodbService) { }

    ngOnInit() { }

    ionViewWillEnter() {
        // Clear previous values. We might came from player page after changing an audio name
        this.audioList = [];
        this.getAudioList();
    }

    updateTime() {
        if (!this.start) {
            this.time = '00:00:00';
            return;
        }
        const now = (new Date()).getTime();
        const elapsed = Math.trunc((now - this.start) / 1000);
        const hours = Math.trunc(elapsed / (60 * 60));
        const value = elapsed - hours * (60 * 60);
        const minutes = Math.trunc(value / 60);
        const seconds = value - (minutes * 60);
        this.time = (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds);
    }

    monitor() {
        const nextTick = () => {
            if (this.isRecording) {
                this.updateTime();
                setTimeout(nextTick, 1000);
            }
        };
        nextTick();
    }

    getAudioList() {
        this.songdbService.getAll().then(
            data => {
                this.audioList = data;
                console.log(this.audioList);
            });
    }

    /**
     * Start recording a new audio file
     */
    onStartRecording() {
        if (this.platform.is('ios')) {
            this.fileName = this.filename + new Date().getDay() + '' + new Date().getMonth() + new Date().getHours() + '' + new Date().getMinutes() + '' + new Date().getSeconds() + '.3gp';
            const filePath = this.file.documentsDirectory.replace(/file:\/\//g, '') + this.fileName;
            this.fileOnRecord = this.media.create(filePath);
        } else if (this.platform.is('android')) {
            this.fileName = this.filename + new Date().getDay() + '' + new Date().getMonth() + new Date().getHours() + '' + new Date().getMinutes() + '' + new Date().getSeconds() + '.3gp';
            const filePath = this.file.externalDataDirectory.replace(/file:\/\//g, '') + this.fileName;
            this.fileOnRecord = this.media.create(filePath);
        }
        this.start = (new Date()).getTime();
        this.fileOnRecord.startRecord();
        this.isRecording = true;
        this.monitor();
    }

    /**
     * Stop recording the audio file
     */
    onStopRecording() {
        this.fileOnRecord.stopRecord();
        // IMP!! Need to be released for later remove
        this.fileOnRecord.release();
        console.log('soundRecorder.onStopRecording: done recording' + this.fileName);
        let filePath: string = '';
        if (this.platform.is('ios')) {
            filePath = this.file.documentsDirectory.replace(/file:\/\//g, '') + this.fileName;
        } else if (this.platform.is('android')) {
            filePath = this.file.externalDataDirectory.replace(/file:\/\//g, '') + this.fileName;
        }
        this.audioCurrent = {
            id: null,
            key: this.fileName,
            name: this.fileName,
        };
        this.songdbService.setItem(this.audioCurrent.key, this.audioCurrent);
        this.isRecording = false;
        this.audioList.push(this.audioCurrent);
    }

    enterAudio(fileKey, idx) {
        this.route.navigate(['/player', fileKey]);
    }

    async deleteAudioConfirm(fileKey, idx) {
        const alert = await this.alertController.create({
            header: 'Conferma',
            message: 'Elimino il file?',
            buttons: [
                {
                    text: 'Annulla',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (info) => {
                        console.log(`Conferma Annulla ${info}`);
                    }
                }, {
                    text: 'Elimina',
                    handler: () => {
                        let songInfo = null;
                        console.log('Conferm Elimina');
                        this.songdbService.getItem(fileKey).then(
                            song => {
                                // Delete element from list
                                this.audioList.forEach((item, index) => {
                                    if (item.key === fileKey) {
                                        this.audioList.splice(index, 1);
                                    }
                                });
                                songInfo = song;
                            }
                        );
                        // Delete element from database
                        this.songdbService.remove(fileKey);
                        // Remove audio file from device
                        this.deleteAudio(songInfo.name, idx);
                    }
                }
            ]
        });

        await alert.present();
    }

    deleteAudio(fileName, idx) {

        // Get location of audio files in device depending on device platform
        if (this.platform.is('ios')) {
            this.filePath = this.file.documentsDirectory;
        } else if (this.platform.is('android')) {
            this.filePath = this.file.externalDataDirectory;
        }

        // Check if file exists
        this.file.checkFile(this.filePath, fileName).then(data => {
            console.log(`soundRecorder.deleteAudio: killing ${data}`);

            // Remove file from device
            this.file.removeFile(this.filePath, fileName).then(data => {
                console.log(`soundRecorder.deleteAudio: ${fileName} killed`);
                data.fileRemoved.getMetadata((metadata) => {
                    const name = data.fileRemoved.name;
                    const size = metadata.size;
                    const fullPath = data.fileRemoved.fullPath;
                    console.log('soundRecorder.deleteAudio: deleted file ' + name + size + fullPath);
                    console.log('soundRecorder.deleteAudio: Name: ' + name + ' / Size: ' + size);
                });
            }).catch(error => {
                console.log('soundRecorder.deleteAudio: error deleting file from cache folder: ' + error);
            });

        }).catch(error => {
            console.error('soundRecorder.deleteAudio: File not found: ' + error);
        });

    }

    // **** ====== *****

    onStartPlaying(audioFile) {
        let filePath: any;
        if (this.platform.is('ios')) {
            filePath = this.file.documentsDirectory.replace(/file:\/\//g, '') + audioFile;
        } else if (this.platform.is('android')) {
            filePath = this.file.externalDataDirectory.replace(/file:\/\//g, '') + audioFile;
        }
        this.fileOnPlay = this.media.create(filePath);  // open the audio file
        this.fileOnPlay.play();                         // start play
        this.fileOnPlay.setVolume(0.8);                 // set the volume
        const self = this;
        this.intervalDuration = setInterval(() => {
            if (self.duration === -1) {
                self.duration = +self.fileOnPlay.getDuration(); // make it an integer
            } else {
                self.fileOnPlay.stop();
                self.fileOnPlay.release();
                self.setRecordingToPlay();
                clearInterval(self.intervalDuration);
            }
        }, 100);
    }

    setRecordingToPlay() {
        this.fileOnPlay.onStatusUpdate.subscribe(status => {
            // 2: playing
            // 3: pause
            // 4: stop
            this.playStatus = status;
            switch (status) {
                case 1:
                    this.isOnPlay = false;
                    break;
                case 2: // 2: playing
                    this.isOnPlay = true;
                    this.isPlaying = true;
                    break;
                case 3: // 3: pause
                    this.isOnPlay = true;
                    this.isPlaying = false;
                    break;
                case 4: // 4: stop
                default:
                    this.isOnPlay = false;
                    this.isPlaying = false;
                    break;
            }
        });
        console.log('audio file set');
        this.playStatus = 'audio file set';
        this.isReady = true;
        //
        this.isOnPlay = false;
        this.getAndSetCurrentAudioPosition();
    }

    getAndSetCurrentAudioPosition() {
        const diff = 1;
        const self = this;
        this.intervalPosition = setInterval(() => {
            const lastPos = self.position;
            self.fileOnPlay.getCurrentPosition().then(position => {
                if (position >= 0 && position < self.duration) {
                    if (Math.abs(lastPos - position) >= diff) {
                        self.fileOnPlay.seekTo(lastPos * 1000);   // set position
                    } else {
                        self.position = position; // update position for display
                    }
                } else if (position >= self.duration) {
                    self.onStopPlaying();
                    self.setRecordingToPlay();
                }
            });
        }, 100);
    }

    playRecording() {
        this.fileOnPlay.play();
        console.log('soundRecorder.playRecording: PLAY')
    }

    pausePlayRecording() {
        this.fileOnPlay.pause();
        console.log('soundRecorder.pausePlayRecording: PAUSE')
    }

    onStopPlaying() {
        this.fileOnPlay.stop();
        this.fileOnPlay.release();
        clearInterval(this.intervalPosition);
        this.position = 0;
    }

    controlSeconds(action) {
        const step = 15;
        const pos = this.position;
        switch (action) {
            case 'back':
                this.position = pos < step ? 0.001 : pos - step;
                console.log(`soundRecorder.controlSeconds: ${step} seconds backward`);
                break;
            case 'forward':
                this.position =
                    pos + step < this.duration ? pos + step : this.duration;
                    console.log(`soundRecorder.controlSeconds: ${step} seconds forward`);
                break;
            default:
                break;
        }
    }

}