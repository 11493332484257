
import { Injectable                     } from '@angular/core';
import { MqttService, IMqttMessage      } from 'ngx-mqtt';
import { EnvironmentService, IEnvInfo   } from './environment.service'; // provides environment variables
import { StoreService                   } from './store.service';
import { EKeys                          } from '../models/hermes.models';

export enum ETopic {
    ALL         = `#`,         // all the topics
    AGENT       = `agent`,     // all the agent topics
    SYSTEM      = `system`,    // all the system topics
    CLIENT      = `client`,    // all the client (app) topics
    CONTROL     = `control`    // all the control topics
}

export interface IHermesMessage {
    action: string;
    from: string;
    to: string;
    timestamp: string;
    data?: any;
}

@Injectable({
    providedIn: 'root'
})
export class MqttNotifyService {
    private identity  = 'testClient';
    private rootTopic = 'vo1c3w1se3'; // default root topic;
    topic = {
        ALL:     `${this.rootTopic}/${ETopic.ALL}`,       // all the topics
        AGENT:   `${this.rootTopic}/${ETopic.AGENT}`,     // all the agent topics
        SYSTEM:  `${this.rootTopic}/${ETopic.SYSTEM}`,    // all the system topics
        CLIENT:  `${this.rootTopic}/${ETopic.CLIENT}`,    // all the client (app) topics
        CONTROL: `${this.rootTopic}/${ETopic.CONTROL}`    // all the control topics
    };

    constructor(private mqttService: MqttService, private envService: EnvironmentService, private store: StoreService) {
        this.rootTopic     = envService.getMqttRootTopic();
        this.rootTopic     = store.get(EKeys.MQTT_ROOT_KEY);
        this.topic.ALL     = `${this.rootTopic}/${ETopic.ALL}`;       // all the topics
        this.topic.AGENT   = `${this.rootTopic}/${ETopic.AGENT}`;     // all the agent topics
        this.topic.SYSTEM  = `${this.rootTopic}/${ETopic.SYSTEM}`;    // all the system topics
        this.topic.CLIENT  = `${this.rootTopic}/${ETopic.CLIENT}`;    // all the client (app) topics
        this.topic.CONTROL = `${this.rootTopic}/${ETopic.CONTROL}`;   // all the control topics
        this.identity      = this.getUniqueID(4);
        console.log(`monitorMQTT: topics`, this.topic);
        this.catchNotification();

        // subscribe to all messages
        this.mqttService.observe(this.topic.ALL).subscribe((message: IMqttMessage) => {
            // console.log('mqttService.all: got', message);
        });

        // subscribe messages that are for all clients
        this.mqttService.observe(this.topic.CLIENT).subscribe((message: IMqttMessage) => {
            console.log('mqttService.client: got', message);
            this.execClientAction(message);
        });

        // subscribe messages that are exclusively for this client
        this.mqttService.observe(`${this.topic.CLIENT}/${this.identity}`).subscribe((message: IMqttMessage) => {
            console.log('mqttService.identity: got a message for me', message);
            this.execClientAction(message);
        });

        console.log(`mqttService.construct: root=${this.rootTopic} topics=${JSON.stringify(this.topic)}`);
    }

    /**
     * Generates a unique id
     * @param segments the number of 4 bytes blocks to return
     * @param separator true if a separator must be added between blocks
     */
    private getUniqueID(segments = 1, separator = false) {
        function chr4() {
            return Math.random().toString(16).slice(-4);
        }
        let uuid = '';
        for (let i = 0; i < segments; i++) {
            if ( separator && uuid.length) {
                uuid += '-';
            }
            uuid += chr4();
        }
        return uuid;
    }

    /**
     * Catch and log status notification from MQTT client
     */
    private catchNotification() {
        this.mqttService.onConnect.subscribe(data => {
            console.log('mqttService: MQTT connected', data);
            const message: IHermesMessage = {
                action: 'announce',
                from: this.identity,
                to: null,
                timestamp: (new Date()).toISOString(),
                data: {
                    name: this.identity,
                    role: 'client'
                }
            };
            this.mqttService.publish(this.topic.SYSTEM, JSON.stringify(message));
        });
        this.mqttService.onError.subscribe(error => {
            console.log('mqttService: MQTT error', error);
        });
        this.mqttService.onEnd.subscribe(data => {
            console.log('mqttService: MQTT end', data);
        });
        this.mqttService.onOffline.subscribe(data => {
            console.log('mqttService: MQTT offline');
        });
        this.mqttService.onReconnect.subscribe(data => {
            console.log('mqttService: MQTT reconnect');
        });
    }

    private execClientAction(message: IMqttMessage) {
        console.log(`mqttService.execClientAction: MESSAGE=${message.payload}`);
    }


}

