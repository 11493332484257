/**
 * Audio Recorder based on Cordova Libs
 *
 * For Android Permission
 * @see https://github.com/marcusbelcher/ionic-getUserMedia-test/blob/master/src/app/home/home.page.ts
 * @see https://github.com/didinj/ionic3-angular5-cordova-audio-recorder-player/blob/master/src/pages/home/home.ts
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { File     } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { ToastService } from '../../services/toast.service';      // manages popup messages
import { IMediaRecord } from '../../models/global.models';

const AUDIO_FOLDER_NAME = 'voicewise';

export enum EMediaStatus {
  MEDIA_NONE = 0,
  MEDIA_STARTING = 1,
  MEDIA_RUNNING = 2,
  MEDIA_PAUSED = 3,
  MEDIA_STOPPED = 4,
}

@Component({
  selector:      'recorder-mobile',
  templateUrl: './recorder-mobile.component.html',
  styleUrls: [ './recorder-mobile.component.scss'],
})
export class RecorderMobileComponent implements OnInit {

  @Input() filename: string = 'recorder-mobile';
  @Input() seconds: number  = 60;                   // maximum length in seconds of the recording, 0=no limit

  @Output() record: EventEmitter<IMediaRecord> = new EventEmitter<IMediaRecord>();   // bas64 encoded audio file or empty/null string

  private recordStart = 0;
  private recorder = null;
  private recordInfo: IMediaRecord = {
    duration: 0,
    name:  '',
    ext: '',
    audio: '',
    path: ''
  };

  recordingFlag = false;
  time = '00:00:00';

  constructor(  private media: Media,
                private file: File,
                private platform: Platform,
                private toast: ToastService,
                private androidPermissions: AndroidPermissions ) {
    console.log(`recorderMobile.constructor`);
    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        if ( this.platform.is('android') ) {
          this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
            result => {
              console.log('Has camera permission?', result.hasPermission);
              toast.showMessage(`Permission=${result.hasPermission}`, 'CAMERA');
            },
            err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
          );
          this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA]);

          console.log(`recorderMobile.constructor: making directory folder`);

          // create audio directory if required
          this.file.createDir(this.file.externalRootDirectory, AUDIO_FOLDER_NAME, false);
        } else { /*  iOS */
          this.file.createDir(this.file.tempDirectory, AUDIO_FOLDER_NAME, false);
        }
      });
    }
  }

  ngOnInit() {
    if (this.platform.is('ios')) {
      this.recordInfo.ext += '.m4a';
    } else {
      this.recordInfo.ext += '.3gp';
    }
    this.recordInfo.name = this.filename;
    console.log(`recorderMobile.ngOnInit: name=${this.recordInfo.name}`);

  }

  updateTime() {
    console.log(`recorderMobile.updateTime`);
    if ( !this.recordStart ) {
      this.time = '00:00:00';
      return;
    }
    const now     = (new Date()).getTime();
    const elapsed = Math.trunc((now - this.recordStart) / 1000);
    const hours   = Math.trunc( elapsed / (60 * 60));
    const value   = elapsed - hours * (60 * 60);
    const minutes = Math.trunc( value / 60 );
    const seconds = value - (minutes * 60);
    this.time = (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds);
  }

  nextLoop( ) {
    const that = this;
    const nextTick = function() {
      if ( that.recordingFlag ) {
        that.updateTime();
        setTimeout(nextTick, 1000);
      }
    };
    nextTick();
  }

  onStartRecordingOld() {
    this.recordStart = (new Date()).getTime();
    this.recordingFlag = true;
    this.nextLoop();
  }

  onStopRecordingOld() {
    this.recordingFlag = false;
  }

  /**
   * Start recording using proper file format
   */
  onStartRecording() {
    console.log(`recorderMobile.onStartRecording`);
    this.toast.showMessage(`toast.recording-start`, 'common.audio');
    if (this.platform.is('ios')) {
        this.file.createFile(this.file.tempDirectory, this.recordInfo.name, true).then(() => {
            this.recordInfo.path = this.file.tempDirectory.replace(/^file:\/\//, '') + this.recordInfo.name +  this.recordInfo.ext;
            console.error(`recorderMobile.onStartRecording: iOS file ${this.recordInfo.path}`);
            this.recorder = this.media.create(this.recordInfo.path);
            this.recorder.onStatusUpdate.subscribe(this.onAudioStatusUpdate);
            this.recorder.onSuccess.subscribe(this.onAudioSuccess);
            this.recorder.onError.subscribe(this.onAudioError);
            this.recordStart = (new Date()).getTime();
            this.recordingFlag = true;
            this.recorder.startRecord();
            this.nextLoop();
          }).catch((error) => {
            console.error('recorderMobile.onStartRecording: file error', error);
        });
      } else if (this.platform.is('android') ) {
          this.recordInfo.path = (this.file.externalRootDirectory ? this.file.externalRootDirectory : '') + this.recordInfo.name +  this.recordInfo.ext;
          console.error(`recorderMobile.onStartRecording: Android file ${this.recordInfo.path}`);
          this.recorder = this.media.create(this.recordInfo.path);
          this.recorder.onStatusUpdate.subscribe(this.onAudioStatusUpdate);
          this.recorder.onSuccess.subscribe(this.onAudioSuccess);
          this.recorder.onError.subscribe(this.onAudioError);
          this.recordStart = (new Date()).getTime();
          this.recordingFlag = true;
          this.recorder.startRecord();
          this.nextLoop();
        }
  }

  /**
   * Stop recording and emit recorded file
   */
  onStopRecording() {
    console.log(`recorderMobile.onStopRecording`);
    const that = this;
    const now  = (new Date()).getTime();
    this.toast.showMessage(`toast.recording-end`, 'common.audio');

    that.recordInfo.duration = Math.round ((now - this.recordStart) / 1000);

    this.recorder.stopRecord();

    if (this.platform.is('ios')) {
        this.file.readAsDataURL(this.file.tempDirectory, this.filename).then((base64File) => {
            that.recordInfo.audio = base64File;
            that.record.emit( that.recordInfo );
            console.log(base64File);
        }).catch((error) => {
            console.log('recorderMobile.onStopRecording: iOS file error', error);
        });
    } else if (this.platform.is('android')) {
        this.file.readAsDataURL(this.file.externalRootDirectory, this.filename).then((base64File) => {
            that.recordInfo.audio = base64File;
            that.record.emit( that.recordInfo );
            console.log(base64File);
          }).catch((error) => {
            console.error('recorderMobile.onStopRecording: Android file error', error);
        });
    }
  }


  /**
   * fires when file status changes
   * @param status the status of current media file
   */
  private onAudioStatusUpdate(status: number) {
    console.log(`recorderMobile.onAudioStatusUpdate: ${JSON.stringify(status)}`);
    switch (status) {
    case EMediaStatus.MEDIA_NONE: break;
    case EMediaStatus.MEDIA_PAUSED: break;
    case EMediaStatus.MEDIA_RUNNING: break;
    case EMediaStatus.MEDIA_STARTING: break;
    case EMediaStatus.MEDIA_STOPPED:
        this.recordingFlag = false;
        if (this.recorder && this.recorder.release) {
            this.recorder.release();
        }
        break;
    }
  }

  /**
   * Fires on success
   */
  private onAudioSuccess() {
      console.log('recorder.onAudioSuccess: successful action');
  }

  /**
   * Fires when an error is detected
   * @param error the error
   */
  private onAudioError(error) {
      console.error(`recorder.onAudioError: ${JSON.stringify(error.json())}`);
      this.toast.showMessage(`${JSON.stringify(error.json())}`, 'common.error', 10000);
  }

}
