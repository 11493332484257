/**
 * @name    BACKEND_API
 * @file    api.service.js
 * @date    24/02/2018
 * @version 1.0.0
 * @author  L.Tavolato
 */
import { Injectable     } from '@angular/core';
import { HttpService    } from './http.service';
import { SpinnerService } from './spinner.service';
import { environment    } from '../../environments/environment';
import { TProvider, IQDate    } from '../models/global.models';

// the names of MongoDB collections (or SQL tables) the application uses
export enum ECollection {
    USER            = 'user',
    ANALYSES        = 'analyses',
    DOCUMENTS       = 'documents',
    DATAMODELS      = 'data-models',
    EVALUATIONS     = 'evaluations',
    LOCATIONS       = 'locations',
    ORGANIZATIONS   = 'organizations',
    PATHOLOGIES     = 'pathologies',        //
    PATIENTS        = 'patients',           // patient data
    QUEUES          = 'queues',             // evaluation processing queue
    RECORDINGS      = 'recordings',         // recording
    SESSIONS        = 'sessions',
    DEVICES         = 'devices',
    RULES           = 'rules',
    SURVEYS         = 'surveys',
    USERS           = 'users',

    COUNTRY         = 'nazioni',            // local json
    DISTRICT        = 'district',           // local json
    QUESTION        = 'questions',          // OLD
    QUESTIONS       = 'questions',          // OLD
    NEWS            = 'news',               // OLD
    PHRASE          = 'phrases'             // OLD
}


export enum EOperator {
    EQUAL              = '',            // Equals
    NOT_EQUAL          = 'ne',          // Not equals
    LOWER_THAN         = 'lt',          // Lower than
    GREATER_THAN       = 'gt',          // Greater than
    LOWER_EQUAL_THAN   = 'lte',         // Lower than or equal to
    GREATER_EQUAL_THAN = 'gte',         // Greater than or equal to
    INCLUDED           = 'in',          // include in array: matches any value in the array of values
    EXCLUDED           = 'nin',         // excluded in array: doesn't matche any value in the array of values
    CONTAINS           = 'contains',    // Contains
    NOT_CONTAINS       = 'ncontains',   // Does not contain
    CONTAINS_SENS      = 'containss',   // Contains case sensitive
    NOT_CONTAINS_SENS  = 'containss'    // Does not contains case sensitive
}

// simple query rules descriptors
export interface IQueryRule {
    field: string;          // the field name
    operator: EOperator;    // the operator to apply
    value: string;          // the value to match
}

export interface ISortRule {
    field: string;          // the name of the field
    order: 'ASC'|'DESC';    // ascending or descending
}

export interface IFilter {
    start?: number;         // the positional index of the first record to load
    limit?: number;         // upper limit to the records to load
    sort?: ISortRule[];     // any sorting rule
    where: IQueryRule[];    // query rules
}

export interface IResponseError {
    statusCode: number; // 400
    error: string;      // "Bad Request",
    message: string;    // "ValidationError",
    data: {             // optional data that details the error
        errors: {
            status: string[];   // ["status must be one of the following values: Waiting, Queued, Started, Completed, Failed, "]
        }
    }
}

@Injectable()
export class ApiService {

    private endpoint = {
        count:       '/count',
        searchfiles: '/upload/search',
        getfiles:    '/upload/files',
        getfile:     '/upload/files',
        upload:      '/upload',
        providerurl: '/connect'
    };

    private requests = 0;
    // private source = 'source=users-permissions';

    constructor(private httpService: HttpService, private spinnerService: SpinnerService) {
        // this.pollHealthCheck();
    }

    private startRequest() {

    }

    private endRequest() {

    }

    private pollHealthCheck() {
        const checkConnection = async () => {
            this.healthCheck().catch((error) => {
                console.log('api.healthCheck: error ' + error.toString());
            });
        };
        checkConnection();
        setInterval(checkConnection, 30000);
    }

    /**
     * Add filtering rules to a query
     * @param url the url to which append filtering rules
     * @param filter filtering rules to apply
     * @return the original url with filtering rules appended
     * @see https://strapi.io/documentation/3.x.x/guides/filters.html#available-operators
     *
     * OPTIONAL FILTER FORMAT
     *
     * filter = {
     *      where: [
     *          { field: <filed name>, operator: <operator name>, value: <value to match> },
     *          ...
     *      ],
     *      start: <optional index of the first record/document in the set to load, 0=default>,
     *      limit: <optional maximum number of records/documents to load>,
     *      sort: <optional name of the field for sorting>:<sort direction, ASC or DESC>
     * }
     *
     * OPTIONAL ALTERNATIVE FILTER FORMAT
     *
     * filter: { <fieldname1>: "<field1 value>", <fieldname2>: "<field2 value>", .... start: <start value>, limit: <limit value>, sort: "<field name>:<ASC | DESC>" }
     *
     */
    private addFilter(url, filter: IFilter) {
        if ( filter ) {
            const qr = [];  // list of query rules
            // if the searching rules are defined, transform them in the target format
            if ( filter.where && Array.isArray(filter.where) ) {
                filter.where.forEach( ff => {
                    qr.push( `${encodeURIComponent(ff.field + (ff.operator.length ? '_' + ff.operator : '' ))}=${encodeURIComponent(ff.value)}` );
                });
            } else {
                // Transform search rules are defined in the form { id1: value1, id2: value, ... }. The results of each rule is ANDed with any previous result
                const skipword = ['start', 'limit', 'sort'];    // the rules for managing the results
                for (const [key, value] of Object.entries(filter)) {
                    if ( skipword.indexOf(key.toLowerCase()) < 0 ) {
                        const qq = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                        qr.push( qq );
                    }
                    console.log(`${key}: ${value}`);
                }
            }
            // build the query string
            let qs = '?' + qr.join('&');
            // shall define the index of the first record/document to load?
            if ( filter.start ) {
                qs += '&_start=' + filter.start;
            }
            // shall define an upper limit in the number of records/documents to load
            if ( filter.limit ) {
                qs += '&_limit=' + filter.limit;
            }
            // shall sort the resulting set using a field? (example:  ...sort: "email:ASC"...)
            if ( filter.sort ) {
                qs += '&_sort=' + filter.sort;
            }
            url += qs; // concatenate the query string to the url
        }
        return url;
    }

    /**
     * List entries, each one contains all non private data
     * @param contentType the name of the collection to query
     * @param filter filtering rules in json format (i.e. {color: blue, size: small } are converted into parameters ?color=blue&size=small)
     */
    public async getEntries( contentType: any | ECollection, filter?: any | IFilter) {
        try {
            let url = `/${contentType}`;
            url = this.addFilter(url, filter);
            // console.log(`api.getEntries: url=${url}`);
            return this.httpService.get(url);
        } catch (exception) {
            console.error(`apiService.getEntries: EXCEPTION ${exception.toString()}`);
        }
        return [];
    }

    /**
     * List cleaned up entries - non standard api, collection dependent
     * @param contentType the name of the collection to query
     * @param filter filtering rules in json format (i.e. {color: blue, size: small } are converted into parameters ?color=blue&size=small)
     */
    public async listEntries( contentType: ECollection, filter?: IFilter) {
        try {
            let url = `/${contentType}/list`;
            url = this.addFilter(url, filter);
            console.log(`api.listEntries: url=${url}`);
            return this.httpService.get(url);
        } catch (exception) {
            console.error(`apiService.listEntries: EXCEPTION ${exception.toString()}`);
        }
        return [];
    }

    /**
     * Get the total count of entries with the provided criteria
     * @param contentType the name of the collection to count
     * @param filter filtering rules in json format (i.e. {color: blue, size: small } are converted into parameters ?color=blue&size=small)
     */
    public async getEntryCount(contentType: ECollection, filter?: IFilter) {
        let url = `/${contentType}${this.endpoint.count}`;
        url = this.addFilter(url, filter);
        console.log(`api.getEntryCount: url=${url}`);
        return this.httpService.get(url);
    }

    /**
     * Get a specific entry or a list of entries from the same collection
     * @param contentType the name of the collection to query
     * @param id ID of entry (single entry required) or list of ids (multiple entries required)
     */
    public async getEntry(contentType: ECollection, id: string|string[] ) {
        try  {
            if ( Array.isArray(id) ) {
                id = '?id=' + id.join('&id=');
            }
            const url = `/${contentType}/${id}`;
            console.log(`api.getEntry: url=${url}`);
            return this.httpService.get(url);
        } catch (exception) {
            console.error(`apiService.getEntry: EXCEPTION ${exception.toString()}`);
        }
        return null;
    }

    /**
     * Create data
     * @param contentType the name of the collection where the entry shall be created
     * @param data New entry
     */
    public async createEntry(contentType: ECollection, data: any) {
        const url = `/${contentType}`;
        console.log(`api.createEntry: url=${url} data=`, data);
        return this.httpService.post(url, data);
    }

    /**
     * Update or create data
     * @param contentType he name of the collection where the entry shall be updated
     * @param id ID of entry
     * @param data the data to store
     */
    public async updateEntry(contentType: ECollection, id: string, data: any) {
        if ( !data ) {
            return new Promise((resolve) => resolve( { error: true, msg: `Dati non disponibili per salvataggio o aggiornamento${id ? ' ID=' + id : ''}` }));
        }
        if ( data && data.id && data.id.length) {
            id = data.id;
        }
        if ( id && id.length > 1 ) {
            console.log(`api.updateEntry: update url=/${contentType}/${id} data=${JSON.stringify(data).substr(0, 80)}...`);
            return this.httpService.put(`/${contentType}/${id}`, data);
        }
        console.log(`api.updateEntry: create url=/${contentType} data=${JSON.stringify(data).substr(0, 80)}...`);
        return this.httpService.post(`/${contentType}`, data);
        // todo: use PATCH for update
    }

    /**
     * Delete an entry
     * @param contentType he name of the collection where the entry shall be deleted
     * @param id ID of the entry to delete
     */
    public async deleteEntry(contentType: ECollection, id: string) {
        const url = `/${contentType}/${id}`;
        console.log(`api.deleteEntry: url=${url}`);
        return this.httpService.delete(url);
    }

    /**
     * Checks the _health route and expects a status code of 204.
     * @see https://github.com/strapi/strapi-docker/pull/8
     */
    public async healthCheck() {
        const url = `/_health`;
        const response = await this.httpService.head(url);
        console.log(`api.heathCheck:`, response);
        return true;
    }

    /**
     * Search for files
     * @param query Keywords
     */
    public async searchFiles(query: string) {
        return this.httpService.get(`${this.endpoint.searchfiles}/${decodeURIComponent(query)}`);
    }

    /**
     * Get files
     * @param params Filter and order queries
     * @returns Object[] Files data
     */
    public async getFiles(params?: any) {
        return this.httpService.get(`${this.endpoint.getfiles}/${decodeURIComponent(params)}`);
    }

    /**
     * Get file
     * @param id ID of entry
     */
    public async getFile(id: string) {
        return this.httpService.get(`${this.endpoint.getfile}/${id}`);
    }

    /**
     * Upload files
     *
     * To use this route, you have to submit a HTML form with multipart/* enctype (or fake it if you are
     * using a web front-end framework like AngularJS).
     *
     * ### Browser example
     * ```js
     * const form = new FormData();
     * form.append('files', fileInputElement.files[0], 'file-name.ext');
     * form.append('files', fileInputElement.files[1], 'file-2-name.ext');
     * const files = await api.upload(form);
     * ```
     *
     * ### Node.js example
     * ```js
     * const FormData = require('form-data');
     * const fs = require('fs');
     * const form = new FormData();
     * form.append('files', fs.createReadStream('./file-name.ext'), 'file-name.ext');
     * const files = await api.upload(form);
     * ```
     *
     * @param data FormData
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/FormData
     */
    public async upload(data: FormData): Promise<object> {
        return this.httpService.post(`${this.endpoint.upload}`, data);
    }

    /**
     * Retrieve the connect provider URL
     * @param provider
     *
     * Example: Redirect your user to the provider's authentication page
     *
     * window.location = api.getProviderAuthenticationUrl('facebook');
     */
    public getProviderAuthenticationUrl(provider: TProvider): string {
        return `${this.endpoint.providerurl}/${provider}`;
    }

    /**
     * Authenticate the user with the token present on the URL (for browser) or in `params` (on Node.js)
     * @param provider
     * @param params
     */
        /* TODO: complete porting
    public async authenticateProvider( provider: TProvider, params?: IProviderToken): Promise<ILoginResponse> {
        this.clearToken();
        // Handling browser query
        if (this.isBrowser()) {
            params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        }
        const authentication: ILoginResponse = await this.httpService.get(`${this.url}/auth/${provider}/callback`,{ params });
        this.setToken(authentication.jwt);
        return authentication;
    }
        */


    /**
     * Return timestamp elements
     * @param date elements (2019-02-25T12:19:50.068Z)
     */
    splitTimeStamp(timestamp: string): IQDate {
        const qdate: IQDate = {
            day:      '',
            month:    '',
            year:     '',
            hour:     '',
            minute:   '',
            numDay:    0,
            numMonth:  0,
            numYear:   0,
            numHour:   0,
            numMinute: 0
        };
        if (!timestamp || !timestamp.length) {
            return null;
        }
        const dt = timestamp.indexOf('T') ? timestamp.split('T') : timestamp.split(' ');  // split date from time
        let d = dt[0].split('-');
        if (dt[0].indexOf('/') >= 0) {
            d = dt[0].split('/');
        }
        if ( d.length < 3 ) {
            console.error(`api.service.splitTimeStamp: no date in ${timestamp}`, d);
            return qdate;
        }
        if (dt.length > 1) {
            const t = dt[1].split(':');
            qdate.hour      = t[0];
            qdate.minute    = t[1];
            qdate.numHour   = parseInt(qdate.hour, 10 );
            qdate.numMinute = parseInt(qdate.minute, 10 );
        }
        qdate.year  = d[0];
        qdate.month = d[1];
        qdate.day   = d[2];
        qdate.numYear  = parseInt(qdate.year , 10);
        qdate.numMonth = parseInt(qdate.month, 10);
        qdate.numDay   = parseInt(qdate.day  , 10);
        // console.warn(`api.service.splitTimeStamp: IN=${timestamp} OUT=`, qdate);
        return qdate;
    }
}
