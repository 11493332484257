/**
 * @see https://www.freakyjolly.com/ionic-4-how-to-use-ionic-modal-popovers-and-pass-data-and-receive-response/#more-1885
 */
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { EAction, IAction } from '../../models/global.models';

import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router        } from '@angular/router';
import { StrapiService } from '../../services/strapi.service';
import { AuthService   } from '../../services/auth.service';
import { ApiService    } from '../../services/api.service';
import { ToastService  } from '../../services/toast.service';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login.modal.html',
    styleUrls: ['./login.modal.scss']
})
export class LoginModalPage implements OnInit {

    credentialsForm: FormGroup;

    logo = 'assets/images/voicewise-logo-tr-2.png';

    errorFlag    = false;
    errorMessage = 'toast.invalid-credentials';

    username = '';  // 'administrator@tavolato.it';
    password = '';  // '4dm1n1str4t0r!';
    terms = false;

    constructor(
        private router: Router,
        private navParams: NavParams,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private modalController: ModalController,
        private toast: ToastService,
        private strapi: StrapiService) {
        console.log('loginModal.constructor');
    }

    ngOnInit() {
        const date = new Date();
        console.table(this.navParams);
        this.credentialsForm = this.formBuilder.group({
            email:  new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ])),
            password: ['', [Validators.required, Validators.minLength(6)]],
            terms: new FormControl(true, Validators.pattern('true'))
        });
    }

    toggleAccept(terms) {
        this.terms = !this.terms;
        console.log(`loginModal.toggleAccept ${terms} => ${this.terms}`);
    }

    isValid() {
        return (this.username.length > 5 && this.password.length > 5 && this.terms === true);
    }

    /**
     * Login required
     */
    async onSubmit() {
        console.log('loginModal.onSubmit');
        const that = this;
        const value = this.credentialsForm.value;
        await this.authService.login({ username: this.username, password: this.password}).then(
            async function success(response) {
                console.log('loginModal.onSubmit: SUCCESS');
                that.toast.showMessage('toast.login-success');
                await that.modalController.dismiss({
                    type: EAction.SUCCESS,
                    data: response }, 'login');
            },
            function failure(error) {
                that.errorFlag = true;
                if ( error.code === 401 ) {
                    console.error('loginModal.onSubmit: TOKEN ERROR', error);
                    that.toast.showMessage('toast.token-expired');
                    that.authService.logout();  // reset token and status
                } else {
                    console.error('loginModal.onSubmit: ERROR', error);
                    that.toast.showMessage(error.message ? error.message : 'toast.login-failed');
                }
            }
        ).catch( exception => {
            that.errorFlag = true;
            console.error('loginModal.onSubmit: EXCEPTION', exception);
            that.toast.showMessage(exception.message ? exception.message : 'toast.login-failed');
        });
    }

    /**
     * Cancel modal
     */
    async onCancel() {
        console.log('loginModal.onCancel:');
        await this.modalController.dismiss({
            type: EAction.CANCEL,
            data: null }, 'login');
    }

    async onViewRules() {
        console.log('loginModal.onViewRules:');
        await this.modalController.dismiss({
            type: EAction.CANCEL,
            data: null }, 'login');
        this.router.navigate(['termsconditions']);
    }

}
