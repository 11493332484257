/**
 * Audio Recorder based on WebRTC
 * @see https://stackoverflow.com/questions/52449392/ionic-4-getusermedia-notallowed-in-ionic-webview
 * @see https://medium.com/@moneychaudhary/how-to-implement-audio-recording-using-record-rtc-in-angular-2-de1967328c38
 * @see https://github.com/moneychaudhary/RecordRTCDemo
 *
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from '../../services/toast.service';      // manages popup messages
import { IMediaRecord } from '../../models/global.models';

import * as RecordRTC from 'recordrtc';

@Component({
  selector:      'recorder-web',
  templateUrl: './recorder-web.component.html',
  styleUrls: [ './recorder-web.component.scss'],
})
export class RecorderWebComponent implements OnInit {

  @Input() filename: string = 'recorder-web';
  @Input() seconds: number  = 60;                                                   // maximum length in seconds of the recording, 0=no limit

  @Output() record: EventEmitter<IMediaRecord> = new EventEmitter<IMediaRecord>();  // bas64 encoded audio file or empty/null string

  recordingFlag = false;
  time = '00:00:00';
  recordStart = 0;

  private stream;
  private recorder;

  recordInfo: IMediaRecord = {
    duration: 0,
    name:  '',
    ext: '',
    audio: '',
    path: ''
  };

  private mediaConstraints = {
    video: false,
    audio: true
  };

  private audioOptions = {
    type: 'audio',
    mimeType: 'audio/wav',        // valid values: audio/wav, audio/webm
    numberOfAudioChannels: 1,     // 1 or 2
    sampleRate: 96000,            // range 22050 to 96000
    desiredSampRate: 16000,       // 16khz - range 22050 to 96000
    bufferSize: 16384,            // 16Mb - valid values: 256, 512, 1024, 2048, 4096, 8192, 16384
    bitsPerSecond: 128000,        // 128 kbps
    audioBitsPerSecond: 128000    // 128 kbps
  };

  constructor(private toast: ToastService) {
  }

  ngOnInit() {
    console.log(`recorderWeb.ngOnInit: ${this.filename}`, navigator.getUserMedia(this.mediaConstraints, this.onMediaSuccess, this.onMediaError));
    this.recordInfo.ext  = '.wav';
    this.recordInfo.name = this.filename;
  }

  updateTime() {
    if ( !this.recordStart ) {
      this.time = '00:00:00';
      return;
    }
    const now     = (new Date()).getTime();
    const elapsed = Math.trunc((now - this.recordStart) / 1000);
    const hours   = Math.trunc( elapsed / (60 * 60));
    const value   = elapsed - hours * (60 * 60);
    const minutes = Math.trunc( value / 60 );
    const seconds = value - (minutes * 60);
    this.time = (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds);
  }

  nextLoop( ) {
    const that = this;
    const nextTick = function() {
      if ( that.recordingFlag ) {
        that.updateTime();
        setTimeout(nextTick, 1000);
      }
    };
    nextTick();
  }

  onStartRecording() {
    const that = this;
    if ( this.recordingFlag ) {
      return;
    }
    navigator.mediaDevices
      .getUserMedia(this.mediaConstraints)
      .then(this.onUserMediaSuccess.bind(this), this.onUserMediaFailure.bind(this))
      .catch(error => {
        that.toast.showMessage(error.toString(), 'common.error');
    });

    /*
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      that.stream = stream;
      that.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
        type: 'audio',
        mimeType: 'audio/webm'
      });
      that.recorder.record();
    }).catch(error => {
      that.toast.showMessage(error.toString(), 'common.error');
    });
    */
  }

  /**
   * Start recording on stream setup success
   * @param source the source stream to use for recording
   */
  onUserMediaSuccess(source) {


    // Start Actual Recording
    const StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.recorder = new StereoAudioRecorder(source, this.audioOptions);
    this.recorder.record(); // this.recorder.blob will hold resulting record
    this.recordStart = (new Date()).getTime();
    this.recordingFlag = true;
    this.nextLoop();
  }

  /**
   * Receives the notification of an error on stream setup
   * @param error the detected error
   */
  onUserMediaFailure(error) {
    this.toast.showMessage(error.toString(), 'common.error');
  }

  /**
   * Stops audio recording
   */
  onStopRecording() {
    const that = this;
    if ( !this.recordingFlag ) {
      return;
    }
    this.recordingFlag = false;
    if (this.recorder) {
      this.recorder.stop(this.onStopRecordingSuccess.bind(this), this.onStopRecordingFailure.bind(this));
    }
  }

  /**
   * On successful recording, save the record, perform cleanup and send results
   * @param blob the recorded audio
   */
  onStopRecordingSuccess(blob) {
    this.stopMedia();   // cleanup
    this.toast.showMessage('toast.recording-complete');
    const now  = (new Date()).getTime();
    // set the encoding type
    this.recordInfo.ext = '.wav';
    this.recordInfo.name = this.filename + this.recordInfo.ext;
    // calculate the duration
    this.recordInfo.duration = now - this.recordStart;
    // create a reference to the recorded audio (wav or webm) to play and upload it. It MUST be revoked by revokeObjectURL()
    this.recordInfo.audio = URL.createObjectURL(blob);
    // send back the result
    this.record.emit(this.recordInfo);
  }

  onStopRecordingFailure(error) {
    this.recordInfo.audio = null;  // audio recorded
    this.recordInfo.duration = 0;   // recording duration
    this.stopMedia();               // cleanup
    this.toast.showMessage(error.toString(), 'common.error');
  }

  /**
   * Release memory buffers
   */
  stopMedia() {
    if ( this.recorder ) {
      this.recorder = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }

  onMediaSuccess(stream) {
    console.log(`recorderWeb.onMediaSuccess:`, stream);
  }

  onMediaError(error) {
    console.log(`recorderWeb.onMediaError:`, error);
  }

}
