import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { IQuestion, IQuestionOption, EQuestionType, IReply } from '../../models/global.models';


@Component({
    selector:      'question',
    templateUrl: './question.component.html',
    styleUrls:  ['./question.component.scss'],
})
export class QuestionComponent implements OnInit, OnChanges, OnDestroy {

    @Input() question: IQuestion = null;
    @Input() value: any = null;

    @Output() reply: EventEmitter<IReply> = new EventEmitter<IReply>();

    values = {};

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
        if ( !this.value ) {
            this.value = this.question.type === EQuestionType.MULTIPLE ? '' : {};
        }
    }

    ngOnDestroy(): void {
        this.reply.emit({
            code: this.question.code,
            type: this.question.type,
            value: this.value
        });
    }

    radioSelect(option: IQuestionOption) {
        const oo = this.question.options.find(opt => opt.label === option.label );
        if ( oo ) {
            oo.selected = true;
            this.value = oo.label;
            console.log(`question.radioSelect: selected option ${option.label}`);
        } else {
            console.error(`question.radioSelect: cannot find option ${option.label}`);
        }
    }

    radioIsSelect(option: IQuestionOption) {
        const oo = this.question.options.find(opt => opt.label === option.label );
        return oo ? oo.selected : false;
    }

    toggleCheckBox(question: IQuestion, option: IQuestionOption) {
        console.warn(`question.toggleCheckBox: question=${question.code} option=${option.label} selected=${this.value[option.label] ? 'YES' : 'NO'}`);

        if ( this.question.type === EQuestionType.MULTIPLE ) {
            if (this.value[option.label]) {
                delete this.value[option.label];
            } else {
                this.value[option.label] = true;
            }
        } else {
            const oo = this.question.options.find(opt => opt.label === option.label );
            if ( oo ) {
                oo.selected = true;
                this.value = oo.label;
            } else {
                console.error(`question.toggleCheckBox: cannot find option`);
                return;
            }
        }
        this.reply.emit({
            code: this.question.code,
            type: this.question.type,
            value: this.value
        });
    }

}
