/**
 * Servizi per gestire le popup di notifica
 */
import { Injectable         } from '@angular/core';
import { ToastController    } from '@ionic/angular';
import { TranslateService   } from '@ngx-translate/core';
import { EnvironmentService } from './environment.service'; // provides environment variables
@Injectable()
export class ToastService {

    private loadingElement = null;
    private loadingController = null;

    constructor(private toastController: ToastController, private translate: TranslateService, private environment: EnvironmentService) {
        this.loadingController = document.querySelector('ion-loading-controller');
        this.translate.use(environment.getLanguage() || 'it');
        this.translate.get(['common.error', 'toast.unavailable-service']).subscribe((translation: [string]) => {
            console.log(`TRANSLATE TEXT: ${JSON.stringify(translation)}`);
        });
    }

    /**
     * Display a message for a limited time
     * @param msg the message to display
     */
    public async showMessage(msg: string, header?: string, delay?: number) {
            const toast = this.toastController.create({
                header: header ? await this.getMessage(header) : null,
                message: await this.getMessage(msg),
                duration: delay ? delay : 5000
            });
            toast.then(toastMsg => toastMsg.present());
    }

    getMessage(msg: string): Promise<string> {
        return new Promise( resolve => {
            const message = this.translate.instant(msg);
            console.log(`instant: IN=${msg} OUT=${message}`);
            resolve(message);
            this.translate.get([msg]).subscribe((res: [string]) => {
                console.log(`translate: IN=${msg} OUT=${JSON.stringify(res)}`);
                // resolve(res[msg]);
            });
        });
    }

    public async presentLoading(message: string, spin = 'crescent', ms = 2000) {
        await this.loadingController.componentOnReady();
        this.dismissLoading();
        this.loadingElement = await this.loadingController.create({
            message:  this.getMessage(message),
            spinner:  spin,
            duration: ms
        });
        await this.loadingElement.present();
    }

    public dismissLoading() {
        if ( this.loadingElement ) {
            this.loadingController.dismiss(this.loadingElement);
            this.loadingElement = null;
        }
    }


}
