import { Injectable      } from '@angular/core';
import { AuthService     } from './auth.service';
import { Observable, of  } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService) {
    }

    /**
     * Add the authorization field to the header of any http request when required
     * @param req the request on going
     * @param next the callback for moving to next step
     * @returns next handle
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.auth.token;
        const start = new Date().getTime();
        // console.log('INTERCEPT =====-----======------=====-----======------=====-----======------=====-----======------=====-----======------');
        // console.log(`INTERCEPT: ${req.url}\n${JSON.stringify(req.body, null, 4)}\n------\n`);
        if ( token && token.length && !req.headers.get('Authorization') ) {
            req = req.clone({
                setHeaders: {
                    // tslint:disable-next-line:object-literal-key-quotes
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*'
                }
            });
        }
        // return next.handle(req);
        return next.handle(req).pipe(
            tap(evt => {
                const elapsed = (new Date()).getTime() - start;
                if ( req.body && req.body.operationName && elapsed > 10 )  {
                    console.log(`------\nTELEMETRY: '${req.body.operationName}' elapsed ${elapsed}ms ${req.url}\n------\n`);
                }
                if (evt instanceof HttpResponse) {
                    if ( evt.body && evt.body.success) {
                        // evt.body.success.message, evt.body.success.title
                        // console.log(`INTERCEPT: SUCCESS ${evt.body.success.title} - ${evt.body.success.message}\n------\n`);
                    }
                } else {
                    try {
                        // console.log(`INTERCEPT: SUCCESS ${JSON.stringify(evt, null, 4)}\n------\n`);
                    } catch ( err ) {}
                }
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    console.log(`INTERCEPT: HTTP ERROR ${err.error.title || err.name } - ${err.error.message || err.message}\n------${JSON.stringify(err, null, 4)}\n--------\n`);
                    // err.error.message, err.error.title
                    // log error
                } else {
                    try {
                        // console.log(`INTERCEPT: ERROR ${JSON.stringify(err, null, 4)}\n------\n`);
                    } catch ( err ) {}
                }
                return of(err);
        }));
    }

}
