import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { File       } from '@ionic-native/file/ngx';
import { Platform   } from '@ionic/angular';
import { formatDate } from '@angular/common';

const AUDIO_FOLDER_NAME = 'voicewise';
let objectsCache = [];  // used by stringify to manage circular references

@Injectable()
export class UtilityService {

    private encodeChars = [];
    private alphabet = 'abcdefghijklmnopqrstuvwxyz';
    private numbers = '0123456789';

    constructor(private file: File, private platform: Platform, @Inject(LOCALE_ID) private locale: string) {
    }

    /**
     * Replaces all the occurrence of a string with another
     * @param {string} str the string to modify
     * @param {string} find the string to search
     * @param {string} replace the string that shall replace the string to search
     * @return {string} the resulting string
     */
    replace(str, find, replace) {
        // case sensitive replace with regular expressions
        function escapeRegExp(text) {
            return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
        }
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    /**
     * Return the error information
     * @param error GraphQL Error
     * @return error text
     */
    getQueryError(error) {
        let message = '';
        if ( error && error.graphQLErrors ) {
            for ( const err of error.graphQLErrors ) {
                if ( err.extensions && err.extensions.exception && err.extensions.exception.data ) {
                    const exception = err.extensions.exception;
                    const messages  = exception.message;
                    for ( const msg of messages ) {
                        const msgs = msg.messages || [];
                        for ( const m of msgs ) {
                            message += m.message + ' ';
                        }
                    }
                } else {
                    message += (err.message || '?') + ' ';
                }
            }
        } else {
            try {
                message = error.toString();
            } catch (err) {
                return null;
            }
        }
        return message;
    }

    getEncodeChars() {
        if ( !this.encodeChars.length ) {
            if (!this.encodeChars.length) {
                // numbers at first
                for (let i = 0; i < this.numbers.length; i++) {
                    this.encodeChars.push(this.numbers.substr(i, 1));
                }
                // uppercase letters
                for (let i = 0; i < this.alphabet.length; i++) {
                    this.encodeChars.push(this.alphabet.substr(i, 1).toUpperCase());
                }
                // lowercase letters
                for (let i = 0; i < this.alphabet.length; i++) {
                    this.encodeChars.push(this.alphabet.substr(i, 1));
                }
            }
        }
        return this.encodeChars;
    }

    /**
     * Generates a unique id
     * @param segments the number of 4 bytes blocks to return
     * @param separator true if a separator must be added between blocks
     */
    getUniqueID(segments = 1, separator: boolean = false) {
        function chr4() {
            return Math.random().toString(16).slice(-4);
        }
        let uuid = '';
        for (let i = 0; i < segments; i++) {
            if ( separator && uuid.length) {
                uuid += '-';
            }
            uuid += chr4();
        }
        return uuid;
    }

    /**
     * Return the character corresponding to an index
     * @param index the index of the code to get
     * @return string the resulting char
     */
    getCode(index) {
        const data = this.getEncodeChars();
        index = Math.round(index);
        while (index >= data.length ) {
            index -= data.length;
        }
        return data[index];
    }

    /**
     * calculates the checksum of a string and return its hex representation
     */
    checksum(s: string) {
        let chk = 0x12345678;
        for (let i = 0; i < s.length; i++) {
            chk += (s.charCodeAt(i) * (i + 1));
        }
        // tslint:disable-next-line:no-bitwise
        return (chk & 0xffffffff).toString(16);
    }

    crc8(message: string) {
        let c = 0;
        const byteArray = message.split('').map( x => x.charCodeAt(0) );
        const table = [
            0x00, 0x07, 0x0e, 0x09, 0x1c, 0x1b, 0x12, 0x15, 0x38, 0x3f, 0x36, 0x31, 0x24, 0x23, 0x2a, 0x2d,
            0x70, 0x77, 0x7e, 0x79, 0x6c, 0x6b, 0x62, 0x65, 0x48, 0x4f, 0x46, 0x41, 0x54, 0x53, 0x5a, 0x5d,
            0xe0, 0xe7, 0xee, 0xe9, 0xfc, 0xfb, 0xf2, 0xf5, 0xd8, 0xdf, 0xd6, 0xd1, 0xc4, 0xc3, 0xca, 0xcd,
            0x90, 0x97, 0x9e, 0x99, 0x8c, 0x8b, 0x82, 0x85, 0xa8, 0xaf, 0xa6, 0xa1, 0xb4, 0xb3, 0xba, 0xbd,
            0xc7, 0xc0, 0xc9, 0xce, 0xdb, 0xdc, 0xd5, 0xd2, 0xff, 0xf8, 0xf1, 0xf6, 0xe3, 0xe4, 0xed, 0xea,
            0xb7, 0xb0, 0xb9, 0xbe, 0xab, 0xac, 0xa5, 0xa2, 0x8f, 0x88, 0x81, 0x86, 0x93, 0x94, 0x9d, 0x9a,
            0x27, 0x20, 0x29, 0x2e, 0x3b, 0x3c, 0x35, 0x32, 0x1f, 0x18, 0x11, 0x16, 0x03, 0x04, 0x0d, 0x0a,
            0x57, 0x50, 0x59, 0x5e, 0x4b, 0x4c, 0x45, 0x42, 0x6f, 0x68, 0x61, 0x66, 0x73, 0x74, 0x7d, 0x7a,
            0x89, 0x8e, 0x87, 0x80, 0x95, 0x92, 0x9b, 0x9c, 0xb1, 0xb6, 0xbf, 0xb8, 0xad, 0xaa, 0xa3, 0xa4,
            0xf9, 0xfe, 0xf7, 0xf0, 0xe5, 0xe2, 0xeb, 0xec, 0xc1, 0xc6, 0xcf, 0xc8, 0xdd, 0xda, 0xd3, 0xd4,
            0x69, 0x6e, 0x67, 0x60, 0x75, 0x72, 0x7b, 0x7c, 0x51, 0x56, 0x5f, 0x58, 0x4d, 0x4a, 0x43, 0x44,
            0x19, 0x1e, 0x17, 0x10, 0x05, 0x02, 0x0b, 0x0c, 0x21, 0x26, 0x2f, 0x28, 0x3d, 0x3a, 0x33, 0x34,
            0x4e, 0x49, 0x40, 0x47, 0x52, 0x55, 0x5c, 0x5b, 0x76, 0x71, 0x78, 0x7f, 0x6a, 0x6d, 0x64, 0x63,
            0x3e, 0x39, 0x30, 0x37, 0x22, 0x25, 0x2c, 0x2b, 0x06, 0x01, 0x08, 0x0f, 0x1a, 0x1d, 0x14, 0x13,
            0xae, 0xa9, 0xa0, 0xa7, 0xb2, 0xb5, 0xbc, 0xbb, 0x96, 0x91, 0x98, 0x9f, 0x8a, 0x8d, 0x84, 0x83,
            0xde, 0xd9, 0xd0, 0xd7, 0xc2, 0xc5, 0xcc, 0xcb, 0xe6, 0xe1, 0xe8, 0xef, 0xfa, 0xfd, 0xf4, 0xf3
        ];
        for (const b of byteArray) {
            c = table[(c ^ b) % 256];
        }
        return ('00' + c.toString(16)).substr(-2);
    }

    /**
     * Generate a random 6 bytes string and concatenates an 8 bit checksum in hex
     */
    makeUserCode() {
        const code = this.getUniqueID(2).substring(0, 6);
        return code + this.crc8(code);
    }

    /**
     * Calculates the 32 bit hash code of a string and returns its 4 bytes aligned hex value
     * @param text the text to evaluate
     */
    hashCode(text: string) {
        let hash = 0, chr;
        for (let i = 0; i < text.length; i++) {
            chr   = text.charCodeAt(i);
            // tslint:disable-next-line: no-bitwise
            hash  = ((hash << 5) - hash) + chr;
            // tslint:disable-next-line: no-bitwise
            hash |= 0; // Convert to 32bit integer
        }
        const code = hash.toString(16); // hexadecimal representation of the hash
        return '0000'.substr(0, 4 - code.length) + code;
    }

    /**
     * Return a compact timestamp (i.e. 6 bytes long)
     */
    getCompactTimeStamp() {
        const now = new Date();
        const ec  = this.getEncodeChars();
        return ec[now.getFullYear() - 2000] + ec[now.getMonth()] + ec[now.getDate()] + ec[now.getHours()] + ec[now.getMinutes()] + ec[now.getSeconds()];
    }

    /**
     * Return the 32bit hash value of the string as hex value
     * @param text the string to hash
     * Note: JavaScript does bitwise operations (like XOR, above) on 32-bit signed integers
     */
    fastHash(text: string) {
        let hash = 5381,
            i    = text.length;
        while (i) {
            // tslint:disable-next-line:no-bitwise
            hash = (hash * 33) ^ text.charCodeAt(--i);
        }
        /* . Since we want the results to be always positive, . */
        // tslint:disable-next-line: no-bitwise
        hash = (hash >>> 0);    // convert the signed int to an unsigned by doing an unsigned bit shift
        const code = hash.toString(16); // hexadecimal representation of the hash
        return '0000000'.substr(0, 8 - code.length) + code;
    }

    /**
     * Generates a base file name (no extension) using user code, current time, a random code and the resulting hex hash code
     * @param userCode the code that uniquely identifies a user
     * @return the resulting file name
     */
    makeBaseUserFileName(userCode: string) {
        const name  = `V4C-${userCode}-${this.getCompactTimeStamp()}-${this.getUniqueID()}`;
        // name += '-' + this.hashCode(name);  // add the hash code
        return name;
    }

    makeUserFileName(baseFileName: string, id: string, ext?: string) {
        baseFileName += '-' + id;
        return ( baseFileName + '-' + this.fastHash(baseFileName) + (ext ? '.' + ext : '') );
    }

    /**
     * Return current timestamp
     * @param valuesOnlyFlag return ony values, no separator
     */
    getTimeStamp(_date: string = null, valuesOnlyFlag: boolean = false) {
        const now   = _date ? new Date(_date) : new Date();
        const date  = [now.getFullYear(), now.getMonth() + 1, now.getDate()];
        const time  = [now.getHours(), now.getMinutes(), now.getSeconds()];
        const sdate = [String(now.getFullYear()), String(now.getMonth() + 1), String(now.getDate())];
        const stime = [String(now.getHours()), String(now.getMinutes()), String(now.getSeconds())];
        for (let i = 1; i < 3; i++) {
            if (time[i] < 10) {
                stime[i] = `0${stime[i]}`;
            }
            if (date[i] < 10) {
                sdate[i] = `0${sdate[i]}`;
            }
        }
        if (valuesOnlyFlag) {
            return sdate.join('') + stime.join('');
        }
        return sdate.join('/') + ' ' + stime.join(':');
    }

    /**
     * Return current timestamp
     * @param valuesOnlyFlag return ony values, no separator
     */
    formatTimeStamp(_date: string = null) {
        const now   = _date ? new Date(_date) : new Date();
        const date  = [now.getDate(), now.getMonth() + 1, now.getFullYear()];
        const time  = [now.getHours(), now.getMinutes()];
        const sdate = [String(now.getDate()), String(now.getMonth() + 1), String(now.getFullYear())];
        const stime = [String(now.getHours()), String(now.getMinutes())];
        for (let i = 1; i < 3; i++) {
            if (time[i] < 10) {
                stime[i] = `0${stime[i]}`;
            }
            if (date[i] < 10) {
                sdate[i] = `0${sdate[i]}`;
            }
        }
        return sdate.join('/') + ' ' + stime.join(':');
    }

    /**
     * 16 bit hash using fletcher
     */
    fletcherHash(text: string) {
        let sum1 = 0xff, sum2 = 0xff;
        let i = 0;
        let len = text.length;

        while (len) {
            let tlen = len > 20 ? 20 : len;
            len -= tlen;
            do {
                sum2 += sum1 += text.charCodeAt[i++];
            } while (--tlen);
            sum1 = (sum1 & 0xff) + (sum1 >> 8);
            sum2 = (sum2 & 0xff) + (sum2 >> 8);
        }
        sum1 = (sum1 & 0xff) + (sum1 >> 8);
        sum2 = (sum2 & 0xff) + (sum2 >> 8);
        return ((sum2 << 8 | sum1) & 0xFFFF);
    }

    /**
     * Return month short name
     * @param date date in the format YYYY-MM-DDTHH:MM:SS.MS => 2019-07-24T21:00:00.000Z
     */
    formatTime(date: any) {
        const fmtTime = 'HH:mm';
        if ( !date || !date.length ) {
            return '-';
        }
        if ( typeof date === 'string' ) {
            date = new Date ( date );
        }
        if ( date instanceof Date ) {
            try {
                return formatDate(date, fmtTime, this.locale);
            } catch (exception) {
                console.error('utility.formatTime: EXCEPTION ' + exception.toString(), date);
                return '?';
            }
        }
        return JSON.stringify(date);    // boh, try this
    }

    /**
     * Return month short name
     * @param date date in the format YYYY-MM-DDTHH:MM:SS.MS => 2019-07-24T21:00:00.000Z
     */
    formatMonth(date: string, shortFlag: boolean = true) {
        if (!date || !date.length || date.indexOf('T') < 0) {
            return '';
        }
        const d = date.split('T');
        const dd = d[0].split('-');
        const month = parseInt(dd[1], 10) - 1;
        return shortFlag ? this.getMonthShortName(month) : this.getMonthName(month);
    }

    /**
     * Return formatted day
     * @param date date in the format YYYY-MM-DDTHH:MM:SS.MS => 2019-07-24T21:00:00.000Z
     */
    formatDay(date: string) {
        if (!date || !date.length || date.indexOf('T') < 0) {
            return '';
        }
        const d = date.split('T');
        const dd = d[0].split('-');
        const day = parseInt(dd[2], 10);
        return day > 9 ? '' + day : '0' + day;
    }

    /**
     * Clone an object using a trick
     * @param obj the object to clone
     */
    jsonClone(obj: any) {
        try {
            return JSON.parse(JSON.stringify(obj));
        } catch (error) {}
        return obj; // cannot duplicate
    }

    getMonthName(monthNumber: number) {
        const months = [
            'Gennaio', 'Febbraio', 'Marzo'    ,
            'Aprile' , 'Maggio'  , 'Giugno'   ,
            'Luglio' , 'Agosto'  , 'Settembre',
            'Ottobre', 'Novembre', 'Dicembre'
        ];
        monthNumber = (monthNumber >= 0 && monthNumber < 12) ? monthNumber : 0;
        return months[monthNumber];
    }

    getMonthShortName(monthNumber: number) {
        const months = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dec'];
        monthNumber = (monthNumber >= 0 && monthNumber < 12) ? monthNumber : 0;
        return months[monthNumber];
    }

    /**
     * A simple JSON.stringify extender that blocks circular references, avoiding errors
     * @param object the object to be stringified
     * @param maxLength optional length limit in the returned string
     */
    stringify(object, maxLength: number = 0) {
        let objectsCounter = 0;
        const objectsLimit = 10000;
        objectsCache = [];
        if ( !object ) {
            return '';
        }
        if (typeof object === 'string' || object instanceof String) {
            if (maxLength && !isNaN(maxLength) && object.length > maxLength) {
                return object.substr(0, maxLength) + '...';
            }
            return object;
        }
        // convert an object to a string avoiding circular references and objects limit
        const str = JSON.stringify(object,
            // custom replacer fxn - gets around "TypeError: Converting circular structure to JSON"
            (key, value) => {
                // check objects limit
                if ( objectsCounter > objectsLimit ) {
                    return; // skip this object
                }
                objectsCounter++;
                if (typeof value === 'object' && value !== null) {
                    if (objectsCache.indexOf(value) !== -1) {
                        // Circular reference found, discard key
                        return;
                    }
                    // Store value in our collection
                    objectsCache.push(value);
                }
                return value;
            }, 4);
        objectsCache = null; // activates garbage collection
        if (maxLength && !isNaN(maxLength) && str.length > maxLength) {
            return str.substr(0, maxLength) + '...';
        }
        return str;
    }

}
