import { Injectable          } from '@angular/core';
import { environment as env  } from '../../environments/environment';
import { IMqttServiceOptions } from 'ngx-mqtt';

export interface IEnvInfo  {
    ts: string;
    branch: string;
    version: string;
    project: string;
    language?: string;
    production: boolean;
    env: string;
    cms: string;
    mqtt: string;
}

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {

    ready = false;
    private env = env;
    private production = false;

    private project   = env.project;
    private cms       = env[this.project].cms;
    private mqtt      = env[this.project].mqtt;
    private cmsURL    = env.production ? this.cms.prod :  this.cms.dev;
    private mqttURL   = env.production ? this.mqtt.prod :  this.mqtt.dev;
    private updateURL = env[this.project].update;

    constructor() {
        console.log(`ENV: project=${this.project} production=${env.production} cmsURL=${this.cmsURL} mqttURL=${this.mqttURL} updateURL=${this.updateURL}`);
    }

    isProduction() {
        return this.production;
    }

    getUpdateUrl() {
        return this.updateURL;
    }

    getLanguage() {
        return (this.env && this.env.language) ? this.env.language : 'it';
    }

    /**
     * Provides pathology code
     * @returns pathology code
     */
    getPathologyCode() {
        return (this.env && this.env.pathology) ? this.env.pathology : 'covid';
    }

    /**
     * Provides pahology+language code
     * @returns pahology+language code
     */
    getPathologyLanguageCode() {
        const code = this.getPathologyCode();
        const lang = this.getLanguage().toUpperCase();
        return (lang !== 'IT') ? `${code}-${lang}` : code;
    }

    getCmsUrl() {
        return this.cmsURL;
    }

    geProject() {
        return this.project;
    }

    getMqttUrl() {
        return this.mqttURL;
    }

    getMqttRootTopic() {
        return this.mqtt.rootTopic || 'vo1c3w1se3';
    }

//     getMqttOptions(): IMqttServiceOptions {
    getMqttOptions() {
        const data: IMqttServiceOptions =  {
            connectOnCreate: true,
            hostname: this.mqttURL,
            port: this.mqtt.port || 9001,
            path: '/',
            protocol: (this.mqtt.protocol === 'wss') ? 'wss' : 'ws',
            username: this.mqtt.username || null, // 'test',
            password: this.mqtt.password || null, // 'qbergqpoint00!'
        };
        // console.log(`environment.getMqttConfiguration: ${JSON.stringify(data)}`);
        return data;
    }

    getCmsConfiguration() {
        const data = {
            url: this.cmsURL || 'http://46.101.170.156'
        };
        // console.log(`environment.getCmsConfiguration: ${JSON.stringify(data)}`);
        return data;
    }

    getInfo(): IEnvInfo {
        const data = {
            ts: this.env.timeStamp,
            project: this.project,
            branch: this.env.branch,
            version: this.env.version || '',
            production: this.production,
            env: this.env.production ? 'pro' : 'dev',
            cms: this.getCmsConfiguration().url.split('//')[1],
            mqtt: this.getMqttOptions().hostname
        };
        // console.log(`environment.getInfo: ${JSON.stringify(data)}`);
        return data;
    }

}

