/**
 * Backend Services
 *
 * username voicewiseapp
 * password V01c3w1s3!App
 *
 * NON USATO
 * 
 */
import { Platform, AlertController } from '@ionic/angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

// import { tap, catchError } from 'rxjs/operators';

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import * as Cookies from 'js-cookie';
import * as qs from 'qs';

export interface Authentication {
    user: object;
    jwt: string;
}

export type Provider = 'facebook' | 'google' | 'github' | 'twitter';

export interface ProviderToken {
    access_token?: string;
    code?: string;
    oauth_token?: string;
}

export interface CookieConfig {
    key: string;
    options: object;
}

export interface LocalStorageConfig {
    key: string;
}

export interface StoreConfig {
    cookie?: CookieConfig | false;
    localStorage?: LocalStorageConfig | false;
}

@Injectable({
    providedIn: 'root'
})
export class StrapiService {
    public axios: AxiosInstance;
    public storeConfig: StoreConfig;
    private token: string;
    private user = null;

    private project = environment.project;
    private cms     = environment[this.project].cms;
    private url     = environment.production ? this.cms.prod :  this.cms.dev;

    authenticationState = new BehaviorSubject(false);

    constructor(
        private helper: JwtHelperService,
        private storage: Storage,
        private plt: Platform,
        private alertController: AlertController) {
        console.log(`strapiService: project=${this.project} production=${environment.production} url=${this.url}`);
        this.axios = axios.create({
            baseURL: this.url,
            paramsSerializer: qs.stringify
        });
        this.storeConfig = {
            cookie: {
                key: 'jwt',
                options: {
                    path: '/'
                }
            },
            localStorage: {
                key: 'jwt'
            }
        };
        this.token = null;
        if (this.isBrowser()) {
            let existingToken;
            if (this.storeConfig.cookie) {
                existingToken = Cookies.get(this.storeConfig.cookie.key);
            } else if (this.storeConfig.localStorage) {
                existingToken = JSON.parse(window.localStorage.getItem(
                    this.storeConfig.localStorage.key
                ) as string);
            }
            if (existingToken) {
                this.setToken(existingToken, true);
            }
        }
    }

    /**
     * Check if it runs on browser
     * @returns true if browser, false otherwise
     */
    private isBrowser(): boolean {
        return typeof window !== 'undefined';
    }

    /**
     * Check if current user is authenticated and the token is not expired
     * @returns true if authenticated, false otherwise
     */
    isAuthenticated(): boolean {
        if (this.token && this.helper.isTokenExpired(this.token) === true) {
            this.clearToken();
            this.showAlert('Rights expired. You have to login again');
        }
        console.log(`strapiService.isAuthenticated:  ${this.authenticationState.value}`);
        return this.authenticationState.value;
    }

    /**
     * Modifies current configuration
     * @param storeConfig
     * @param requestConfig
     */
    setConfiguration(storeConfig?: StoreConfig, requestConfig?: AxiosRequestConfig) {
        const baseURL = this.url;
        this.axios = axios.create({
            baseURL,
            paramsSerializer: qs.stringify,
            ...requestConfig
        });
        this.storeConfig = {
            cookie: {
                key: 'jwt',
                options: {
                    path: '/'
                }
            },
            localStorage: {
                key: 'jwt'
            },
            ...storeConfig
        };
    }

    /**
     * Axios request
     * @param method Request method
     * @param url Server URL
     * @param requestConfig Custom Axios config
     */
    public async request(
        method: Method,
        url: string,
        requestConfig?: AxiosRequestConfig
    ): Promise<any> {
        /*
        if (  this.helper.isTokenExpired(this.token) === true ) {
            this.logout();
            throw new Error('Token expired!');
        }
        */
        console.log(`strapiService.request:  URL ${url}`);
        try {
            const response: AxiosResponse = await this.axios.request({
                url,
                method,
                ...requestConfig
            });
            console.error(`strapiService: ${url} SUCCESS`, response.data);
            return response.data;
        } catch (error) {
            console.error('strapiService: ERROR', error);
            if (error.response) {
                throw new Error(error.response.data.message);
            } else {
                throw error;
            }
        }
    }

    /**
     * Register a new user.
     * @param username
     * @param email
     * @param password
     * @returns Authentication User token and profile
     */
    public async register(
        username: string,
        email: string,
        password: string
    ): Promise<Authentication> {
        this.clearToken();
        console.log(`strapiService.register:  username=${username} email=${email} password=${password}`);
        const authentication: Authentication = await this.request(
            'post',
            '/auth/local/register',
            {
                data: {
                    email,
                    password,
                    username
                }
            }
        );
        this.setToken(authentication.jwt);
        return authentication;
    }

    /**
     * Login by getting an authentication token.
     * @param identifier Can either be an email or a username.
     * @param password
     * @returns Authentication User token and profile
     */
    public async login(
        username: string,
        password: string
    ): Promise<Authentication> {
        this.clearToken();
        console.log(`strapiService.login:  username=${username} password=${password}`);
        const authentication: Authentication = await this.request(
            'post',
            '/auth/local',
            {
                data: {
                    username,
                    password
                }
            }
        );
        console.log(`strapiService.login:  authentication=`, authentication);
        this.setToken(authentication.jwt);
        return authentication;
    }

    /**
     * Logs out
     */
    public logout() {
        console.log(`strapiService.logout:`);
        this.clearToken();
    }

    /**
     * Sends an email to a user with the link of your reset password page.
     * This link contains an URL param code which is required to reset user password.
     * Received link url format https://my-domain.com/rest-password?code=privateCode.
     * @param email
     * @param url Link that user will receive.
     */
    public async forgotPassword(email: string, url: string): Promise<void> {
        this.clearToken();
        await this.request('post', '/auth/forgot-password', {
            data: {
                email,
                url
            }
        });
    }

    /**
     * Reset the user password.
     * @param code Is the url params received from the email link (see forgot password).
     * @param password
     * @param passwordConfirmation
     */
    public async resetPassword(
        code: string,
        password: string,
        passwordConfirmation: string
    ): Promise<void> {
        this.clearToken();
        await this.request('post', '/auth/reset-password', {
            data: {
                code,
                password,
                passwordConfirmation
            }
        });
    }

    /**
     * Retrieve the connect provider URL
     * @param provider
     */
    public getProviderAuthenticationUrl(provider: Provider): string {
        return `${this.axios.defaults.baseURL}/connect/${provider}`;
    }

    /**
     * Authenticate the user with the token present on the URL (for browser) or in `params` (on Node.js)
     * @param provider
     * @param params
     */
    public async authenticateProvider(
        provider: Provider,
        params?: ProviderToken
    ): Promise<Authentication> {
        this.clearToken();
        // Handling browser query
        if (this.isBrowser()) {
            params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        }
        const authentication: Authentication = await this.request(
            'get',
            `/auth/${provider}/callback`,
            {
                params
            }
        );
        this.setToken(authentication.jwt);
        return authentication;
    }

    /**
     * List entries
     * @param contentTypePluralized
     * @param params Filter and order queries.
     */
    public getEntries(
        contentTypePluralized: string,
        params?: AxiosRequestConfig['params']
    ): Promise<object[]> {
        return this.request('get', `/${contentTypePluralized}`, {
            params
        });
    }

    /**
     * Get the total count of entries with the provided criteria
     * @param contentType
     * @param params Filter and order queries.
     */
    public getEntryCount(
        contentType: string,
        params?: AxiosRequestConfig['params']
    ): Promise<object[]> {
        return this.request('get', `/${contentType}/count`, {
            params
        });
    }

    /**
     * Get a specific entry
     * @param contentTypePluralized Type of entry pluralized
     * @param id ID of entry
     */
    public getEntry(contentTypePluralized: string, id: string): Promise<object> {
        return this.request('get', `/${contentTypePluralized}/${id}`);
    }

    /**
     * Create data
     * @param contentTypePluralized Type of entry pluralized
     * @param data New entry
     */
    public createEntry(
        contentTypePluralized: string,
        data: AxiosRequestConfig['data']
    ): Promise<object> {
        return this.request('post', `/${contentTypePluralized}`, {
            data
        });
    }

    /**
     * Update data
     * @param contentTypePluralized Type of entry pluralized
     * @param id ID of entry
     * @param data
     */
    public updateEntry(
        contentTypePluralized: string,
        id: string,
        data: AxiosRequestConfig['data']
    ): Promise<object> {
        return this.request('put', `/${contentTypePluralized}/${id}`, {
            data
        });
    }

    /**
     * Delete an entry
     * @param contentTypePluralized Type of entry pluralized
     * @param id ID of entry
     */
    public deleteEntry(
        contentTypePluralized: string,
        id: string
    ): Promise<object> {
        return this.request('delete', `/${contentTypePluralized}/${id}`);
    }

    /**
     * Search for files
     * @param query Keywords
     */
    public searchFiles(query: string): Promise<object[]> {
        return this.request('get', `/upload/search/${decodeURIComponent(query)}`);
    }

    /**
     * Get files
     * @param params Filter and order queries
     * @returns Object[] Files data
     */
    public getFiles(params?: AxiosRequestConfig['params']): Promise<object[]> {
        return this.request('get', '/upload/files', {
            params
        });
    }

    /**
     * Get file
     * @param id ID of entry
     */
    public getFile(id: string): Promise<object> {
        return this.request('get', `/upload/files/${id}`);
    }

    /**
     * Upload files
     *
     * ### Browser example
     * ```js
     * const form = new FormData();
     * form.append('files', fileInputElement.files[0], 'file-name.ext');
     * form.append('files', fileInputElement.files[1], 'file-2-name.ext');
     * const files = await strapi.upload(form);
     * ```
     *
     * ### Node.js example
     * ```js
     * const FormData = require('form-data');
     * const fs = require('fs');
     * const form = new FormData();
     * form.append('files', fs.createReadStream('./file-name.ext'), 'file-name.ext');
     * const files = await strapi.upload(form, {
     *   headers: form.getHeaders()
     * });
     * ```
     *
     * @param data FormData
     * @param requestConfig
     */
    public upload(
        data: FormData,
        requestConfig?: AxiosRequestConfig
    ): Promise<object> {
        return this.request('post', '/upload', {
            data,
            ...requestConfig
        });
    }

    /**
     * Set token on Axios configuration
     * @param token Retrieved by register or login
     */
    public setToken(token: string, comesFromStorage?: boolean): boolean {
        if (!this.checkToken(token)) {
            return false;
        }
        this.token = token;
        this.authenticationState.next(true);    // set the user as authenticated
        this.axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        if (this.isBrowser() && !comesFromStorage) {
            if (this.storeConfig.localStorage) {
                window.localStorage.setItem(
                    this.storeConfig.localStorage.key,
                    JSON.stringify(token)
                );
            }
            if (this.storeConfig.cookie) {
                Cookies.set(
                    this.storeConfig.cookie.key,
                    token,
                    this.storeConfig.cookie.options
                );
            }
        }
        return true;
    }

    /**
     * Remove token from Axios configuration
     */
    public clearToken(): void {
        this.token = null;
        this.authenticationState.next(false);    // set the user as NOT authenticated
        delete this.axios.defaults.headers.common.Authorization;
        if (this.isBrowser()) {
            if (this.storeConfig.localStorage) {
                window.localStorage.removeItem(this.storeConfig.localStorage.key);
            }
            if (this.storeConfig.cookie) {
                Cookies.remove(
                    this.storeConfig.cookie.key,
                    this.storeConfig.cookie.options
                );
            }
        }
    }

    /**
     * Verifies that the token is valid
     * @returns true if valid, false otherwise
     */
    public checkToken(token: string): boolean {
        if (token) {
            const decoded = this.helper.decodeToken(token);
            const isExpired = this.helper.isTokenExpired(token);
            if (!isExpired) {
                this.user = decoded;
                this.authenticationState.next(true);
                return (true);
            } else {
                this.clearToken();
            }
        }
        return false;
    }

    /**
     * Display a message in a popoup
     * @param msg the message to display
     */
    private showAlert(msg) {
        const alert = this.alertController.create({
            message: msg,
            header: 'Error',
            buttons: ['OK']
        });
        alert.then(alertMsg => alertMsg.present());
    }

}
