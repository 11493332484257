/**
 * Voicewsise data models, interfaces and enums definitions
 */

// keys for local storage management
export enum EKeys {
    TOKEN_KEY       = 'access_token',   // current token if any
    TKN_CACHE_KEY   = 'cache_token',    // cached token
    JWT_DATA_KEY    = 'jwt_data',       // java web token data
    USERCODE_KEY    = 'user_code',      // the unique code associated to current user
    PATIENT_KEY     = 'patient',        // current patient if any
    ROLES_KEY       = 'roles',          // available user roles
    USER_KEY        = 'user',           // current user if any
    NEWS_KEY        = 'news',           // available news
}

export enum EUserRole {
    SUPER    = 'superadmin',          // can change any low level logics, any data model and relation
    ADMIN    = 'admin',               // can access to any information and add/delete/modify any type data but not change model and relations
    EDITOR   = 'editor',              // can add, delete or modify some specific types of data used by the services
    GROUP    = 'group',               // a group manager: its account is shared between patients
    MEMBER   = 'member',              // generic authenticated user that belongs to any group or the service itself
    GUARDIAN = 'guardian',            // responsible of checking that everything is going fine
    AUTHENTICATED = 'authenticated',  // generic authenticated user
    PUBLIC = 'public'                 // not authenticated
}

export type TProvider = 'facebook' | 'google' | 'github' | 'twitter';

export enum EMediaStatus {
    MEDIA_NONE = 0,
    MEDIA_STARTING = 1,
    MEDIA_RUNNING = 2,
    MEDIA_PAUSED = 3,
    MEDIA_STOPPED = 4,
}

export interface IMediaRecord {
    name:     string;   // file name
    ext:      string;   // file extension (i.e. mp3, m4a, ...)
    audio:    string;   // base64 representation of recoded audio
    path:     string;   // recording path
    duration: number;   // duration in milliseconds
}

// authentication credentials
export interface IUserCredentials {
    username: string;
    password: string;
    email?: string;
}

// user profile
export interface IUser {
    id?:        string;
    _id?:       string;
    firstname:  string;
    lastname:   string;
    username:   string;
    email:      string;
    telephone:  string;
    provider?:  string;
    password?:  string;
    confirmed:  boolean;
    blocked:    boolean;
    role:       IRole | any;
    resetPasswordToken?: string;
}

// user role descriptor as returned by getRoles()
export interface IRole {
    id:          string; // role unique id: 5d1a0eff0b344e7e5b9d697c
    _id:         string; // role unique id: 5d1a0eff0b344e7e5b9d697c
    __v:         number; // 0,
    name:        string; // The name of the role, i.e.: Authenticated
    type:        string; // The name of the role, id: authenticated
    description: string; // The description of the role, i.e.: Default role given to authenticated user
    nb_users:    number; // How many users has this profile: 2
}

// user data as returned by login
export interface IUserData {
    id?: string;
    _id?: string;
    firstname?: string;
    lastname?: string;
    username: string;
    blocked: boolean;
    confirmed: boolean;
    email: string;
    provider: string;
    role: EUserRole;
    company: string;        // company id
    companyId: string;      // company id, replicated since company can also contain company data
    createdAt: string;
    updatedAt: string;
}

// type of question
export enum EQuestionType {
    SINGLE = 'single',      // several options, one accepted, radio mode
    MULTIPLE = 'multiple',  // several options, multiple accepted, radio mode
    DROPDOWN = 'dropdown',  // several options, one accepted, dropdown mode
    NUMBER = 'number',      // a numeric value must be typed
    TEXT = 'text'           // a text must be entered
}

export interface ISurvey {
    id?: string;
    _id?: string;
    title: string;
    code: string;
    note?: string;
}

export interface IReply {
    code: string;
    type: string;
    value: any;
}

// question option
export interface IQuestionOption {
    label: string;
    selected: boolean;
}

// Question within the app
export interface IQuestion {
    id?: string;                // database id
    _id?: string;               // database id
    code: string;               // unique id of the question, user defined
    limit?: number;             // any limit to apply in the size of a textual answer
    text: string;               // the question's text
    order: number;              // the display order
    required: boolean;          // mandatory or not
    type: EQuestionType;        // the type of question
    options: IQuestionOption[]; // the list of options
    incomplete: boolean;        // UI flag to show if a required question has been asked or not
    surveyCode?: string;        // the code of the survey to which the question belongs
    questionCode?: string;      // optional upper level question
}

export interface ICompany {
    name: string;
    address: string;
}

// the definition of the question within the database
export interface IQuestionTable {
    id?: string;
    _id?: string;
    code: string;
    limit?: number;
    text: string;
    order: number;
    required: boolean;
    type: EQuestionType;
    options: string;
    incomplete: boolean;
    surveycode?: string;
    questioncode?: string;
}

export interface IAnswer {
    id?: string;
    _id?: string;
    usercode: string;       // unique id of the user that answered
    surveycode: string;     // the group of answered questions (survey)
    answers: any;           // hashed pairs of question code/answer
}

// voice sample that is based on a specific phrase
export interface IVoiceSample {
    name: string;           // the file name: it contains detailed information about owner, date, time, phrase, ....
    type: string;           // the file type
    size: number;           // the length of the file
    hash: string;           // hash value of the file
    base64: string;         // base 64 encoded file
    media: any;             // reference to cloud repository if any
    usercode: string;       // unique id of the user
    phrasecode: string;     // unique id of the phrase
}

export interface IPhrase {
    id?: string;
    _id?: string;
    code: string;
    title: string;
    text: string;
    help: string;
    order: number;
    position?: number;
}

// News
export interface IInformation {
    id?: string;
    _id?: string;
    title: string;
    subtitle?: string;
    body: string;
    published: boolean;
    type: 'news'|'info';
    filename: string;
    b64image: string;
    videourl: string;
    publishdate: string;
}

export enum EAction {
    CANCEL  = 0,
    CREATE  = 1,
    UPDATE  = 2,
    DELETE  = 3,
    SUCCESS = 4,
    FAILURE = 5
}

export interface IAction {
    type: EAction;
    data: any;
}

export interface IResponse {
    data: IAction;
    role: string;
}

export interface IProviderToken {
    access_token?: string;
    code?: string;
    oauth_token?: string;
}

export enum EStatus {
    OK = 0,
    ERROR = -1
}

export enum EStatusCode {
    OK,
    LOGIN_ERROR,
    LOGIN_EXCEPTION
}

export interface ITokenData {
    _id: string;    // userid
    iat: number;
    exp: number;                              // number of milliseconds since 1970 representing exporation date
}

export interface ILoginResponse {
    jwt: string;
    user: IUserData;
}

export interface IPatient {
    id: string;
    code: string;
    firstname: string;
    lastname: string;
    birthdate: string;
    sex: 'M' | 'F';
    phone: string;
    email: string;
    address: string;
    city: string;
    cap: string;
    provinceCode: string;
    countryCode: string;
    languageCode: string;
    note: string;
    user?: any;
    examinations?: any;
    anamnesi?: any;
}

// sample audio file store
export interface ISampleStore {
    id?: string;                        // unique id of the store
    filename: string;                   // the name of the file (i.e. patient id + rule id + timestamp)
    originalname?: string;              // original name if any
    size: number;                       // length of the original file (i.e. before base64)
    md5: string;                        // file md5
    base64file: string;                 // audio file base64 encoded
    cloudfile?: any;                    // referece to cloud stored file or data
    voicesample: any;                   // reference to the sample to which the file belongs to or data
    patient: any;                       // reference to sample file owner or data
    user: any;                          // reference to who produced it or data
}

// group of audio files referrig to the same sample rule
export interface IVoiceSample {
    id?: string;                     // unique id
    description: string;             // any note
    patient?: IPatient|string;       // the owner
    samplerule?: any;                // the rule applied (ISampleRule) or its id
    samplestores?: any[];            // one or more sample audio file
    samples?: any[];                 // any other sample in cloud
    timestamp: string;               // when it has been created
    user?: any;                      // who recorded the information
}

export interface IQDate {
    day:       string;
    month:     string;
    year:      string;
    hour:      string;
    minute:    string;
    numDay:    number;
    numMonth:  number;
    numYear:   number;
    numHour:   number;
    numMinute: number;
}

export interface IAudioRecord {
    ts: string;
    blob: any;
    playing: boolean;
}

export interface ISampleRule {
    id: string;
    uuid: string; // short unique identifier
    title: string;
    rules: string;
    notes: string;
}
