/**
 * Http services
 */
import { Injectable        } from '@angular/core';
import { BehaviorSubject   } from 'rxjs';

// import { AuthService       } from './auth.service';
import { TokenService      } from './token.service';
import { SpinnerService    } from './spinner.service';
import { LoadingController, Platform  } from '@ionic/angular';
import { EnvironmentService, IEnvInfo } from './environment.service'; // provides environment variables
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { UtilityService    } from './utility.service';

import { Observable } from 'rxjs';

export enum HttpServiceStatus {
  INIT     = 'init',      // notify init status
  START    = 'start',     // notify a new request is starting
  COMPLETE = 'complete',  // notify a request completed successfully
  FAILURE  = 'failure'    // notify a request failed
}

export enum HttpServiceType {
  GET    = 'GET',
  POST   = 'POST',
  PUT    = 'PUT',
  DELETE = 'DELETE',
  PATCH  = 'PATCH',
  HEAD   = 'HEAD'
}

export interface HttpServiceInfo {
  status: HttpServiceStatus;
  code: number;           // http error code -> 401 = unauthorized access
  online: boolean;        // true if online, false otherwise
  type: HttpServiceType;
  spinner: boolean;
  message?: string;
  url?: string;
}

@Injectable()
export class HttpService {

  public  loadingStatus: BehaviorSubject<HttpServiceInfo> = new BehaviorSubject<HttpServiceInfo>({ status: HttpServiceStatus.INIT, code: 0, online: true, type: null, spinner: false });
  private loadingElement = null;
  private platforms: string[] = [];
  private keepAlive = 'status/keepalive';
  private serverURL = '';
  onlineFlag = true;
  config = null;

  constructor(private http: HttpClient,
              // private auth: AuthService,
              private platform: Platform,
              private utility: UtilityService,
              private loading: LoadingController,
              private tokenService: TokenService,
              private envService: EnvironmentService,
              private spinnerService: SpinnerService) {
    this.platform.ready().then(() => {
      this.platforms = this.platform.platforms();
      console.log('httpService: platforms=' + this.platforms.join(', '));
    });
    this.serverURL = envService.getCmsUrl();
    console.log('httpService: URL=' + this.serverURL);
    this.pollConnection();  // start polling connection status
  }

  /**
   * Return REST service URL
   * http://localhost:1337/content-manager/explorer/company?source=content-manager
   */
  url(endPoint: string) {
    // console.error(`httpService.url: ${endPoint}`);
    // check if environment url is required
    if ( endPoint.indexOf('http') === 0 ) {
      // console.log(`httpService: as is URL=${endPoint}`);
      return endPoint;
    }
    let contentManager =  ''; // '/content-manager/explorer';
    const asIs = endPoint.indexOf('/^') >= 0;
    if ( asIs ) {
      contentManager = '';
      endPoint = `/${endPoint.substr(2)}`;  // remove /^ at the beginning
    }
    // cannot use content manager if not authentcated
    if ( !this.tokenService.isAuthenticated() ) {
      contentManager = '';
    }
    let url = this.serverURL + contentManager + endPoint;
    const isMobile = this.platforms.find( element => {
        return element === 'mobile';
    });
    if ( isMobile ) {
      url = this.serverURL + contentManager + endPoint;
    }
    // console.log(`httpService.url: URL=${url}`);
    return url;
  }

  async get(endPoint: string, skipSpinnerFlag: boolean = false) {
    return this.executeRequest(async () => {
      // console.log(`httpService.get: ${this.url(endPoint)}`);
      return this.http.get(this.url(endPoint), await this.getHttpOptions(endPoint, null, 'GET')).toPromise();
    }, endPoint, HttpServiceType.GET, skipSpinnerFlag);
  }

  async delete(endPoint: string, body?: any, skipSpinnerFlag?: boolean) {
    return this.executeRequest(async () => {
      const headerOptions =  await this.getHttpOptions(endPoint, null, 'DELETE');
      if ( body ) {
        headerOptions.body = body;
      }
      // console.log(`httpService.delete: ${this.url(endPoint)}`);
      return this.http.delete(this.url(endPoint), headerOptions).toPromise();
    }, endPoint, HttpServiceType.DELETE, skipSpinnerFlag);
  }

  async post(endPoint: string, body, skipSpinnerFlag: boolean = false) {
    return this.executeRequest(async () => {
      const url = this.url(endPoint);
      console.log(`httpService.post: ${url}`);
      return this.http.post(url, body, await this.getHttpOptions(endPoint, null, 'POST')).toPromise();
    }, endPoint, HttpServiceType.POST, skipSpinnerFlag);
  }

  async patch(endPoint: string, body, skipSpinnerFlag: boolean = false) {
    return this.executeRequest(async () => {
      const url = this.url(endPoint);
      console.log(`httpService.post: ${url}`);
      return this.http.patch(url, body, await this.getHttpOptions(endPoint, null, 'POST')).toPromise();
    }, endPoint, HttpServiceType.PATCH, skipSpinnerFlag);
  }

  async put(endPoint: string, body, skipSpinnerFlag: boolean = false) {
    return this.executeRequest(async () => {
      // console.log(`httpService.put: ${this.url(endPoint)}`);
      return this.http.put(this.url(endPoint), body, await this.getHttpOptions(endPoint, null, 'PUT')).toPromise();
    }, endPoint, HttpServiceType.PUT, skipSpinnerFlag);
  }

  async head(endPoint: string, skipSpinnerFlag: boolean = false) {
    return this.executeRequest(async () => {
      // console.log(`httpService.head: ${this.url(endPoint)}`);
      return this.http.head(this.url(endPoint), await this.getHttpOptions(endPoint, null, 'HEAD')).toPromise();
    }, endPoint, HttpServiceType.HEAD, skipSpinnerFlag);
  }

  /**
   * Creates a request and covert response to json
   * @param request request promise
   */
  private async executeRequest(request: () => Promise<any>, endPoint, requestType, skipSpinnerFlag) {
    const defaultErrorMessage = 'Servizio momentaneamente non disponibile';
    const that = this;
    let result = null;
    try {
      this.loadingStatus.next({ status: HttpServiceStatus.START, code: 0, online: that.onlineFlag, type: requestType, spinner: !skipSpinnerFlag });
      result = await request().then(
        function success(data) {
          // console.info(`httpService: service '${that.url(endPoint)}' response ${JSON.stringify(data).substr(0,100)}...`);
          that.loadingStatus.next({ status: HttpServiceStatus.COMPLETE, online: true, code: 0, type: requestType, spinner: !skipSpinnerFlag, url: endPoint, message: 'OK'  });
          that.onlineFlag = true;
          return data;
        },
        function failure(error) {
          const serviceName = endPoint.split('/').pop().split('?')[0];
          let message = `Service '${serviceName}' ERROR: ${defaultErrorMessage}`;
          if ( error instanceof HttpErrorResponse ) {
            that.onlineFlag = error.status >= 200 && error.status < 300; // either true or false
            console.error(`httpService: service '${that.url(endPoint)}' ERROR\n${that.utility.stringify(error)}`);
            let errorMessage = `${error.statusText} [${error.status}] ${error.message || error.error.message || error.toString()}`;
            if ( error.status === 0 && error.statusText.indexOf('Unknown') >= 0 ) {
              errorMessage = 'momentaneamente non disponibile';
            }
            message = `${serviceName}: ${errorMessage}`;
          } else {
            console.error(`httpxService: ${message}`, error);
          }
          that.loadingStatus.next({
            spinner: !skipSpinnerFlag,
            status: HttpServiceStatus.FAILURE,
            online: that.onlineFlag,
            code: error.status,
            type: requestType,
            url: endPoint,
            message
          });
          return {error: true, code: error.statusCode, msg: message, description: message, response: error};
        }
      ).catch( error => {
        let message = `ERROR in service '${that.url(endPoint)}'`;
        that.loadingStatus.next({ status: HttpServiceStatus.FAILURE, code: 0, online: that.onlineFlag, type: requestType, spinner: !skipSpinnerFlag, url: endPoint, message: error.toString() });
        if ( error instanceof HttpErrorResponse ) {
          that.onlineFlag = error.status >= 200 && error.status < 300; // either true or false
          message = `${error.message} status=${error.status} text=${error.statusText}`;
        } else {
          console.error(`httpxService: ${message}`, error);
        }
        return {error: true, code: 0, msg: error.message ? error.message : defaultErrorMessage, description: message, response: error};
      });

      if (result && result.error) {
        return result;
      }

//      console.warn(`httpService.executeRequest: RESULT=${JSON.stringify(result).substr(0, 50) + '...'}`);
//      console.warn(`httpService.executeRequest: RESULT=`, result);
      if (!result || result.ERROR || (result.code === -2 && result.message)) {
        console.error('httpService: DATA FORMAT ERROR', result, '\n\n');
        result = result || {};
        return {error: true, code: 0, msg: defaultErrorMessage, description: result.message ? result.message : 'httpService error', response: result, ...result};
      }

      return result;
    } catch (e) {
      console.error('httpService.executeRequest: PROTOCOL EXCEPTION ' + e.toString(), e);
      if (e.json) {
        return { error: true, code: 0, msg: defaultErrorMessage, online: that.onlineFlag, description: 'httpService: EXCEPTION ' + e.toString(), response: result, ...e.json()};
      }
      return {error: true, code: 0, msg: defaultErrorMessage, online: that.onlineFlag, description: 'httpService: EXCEPTION ' +  + e.toString(), response: result, ...e};
    }
  }

  /**
   * setup header options
   */
  private async getHttpOptions(endPoint?: string, body?: any, method: string = 'POST' ) {
    const token = this.tokenService.token;
    const headerSettings = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      'Referrer-Policy': 'no-referrer',
      // 'Access-Control-Request-Method': method,
      // 'Access-Control-Request-Headers': 'Content-Type'
    };
    if ( token ) {
      headerSettings['Authorization'] = 'Bearer ' + token;
    } else {
      // console.error(`httpService: TOKEN not available for ${endPoint}`);
    }
    const headers = new HttpHeaders(headerSettings);
    // console.log(`httpService.getHttpOptions: ${this.url(endPoint)}\nHEADERS=${this.utility.stringify(headers)}`, headers.keys());
    if ( body ) {
      return { 'headers': headers, 'body': body };
    }
    return { 'headers': headers };
  }

  /**
   * Convert a json structure to a query string
   * @param json input
   */
  jsonToQueryString(json) {
    return '?' +
      Object.keys(json).map((key) => {
        return encodeURIComponent(key) + '=' +
          encodeURIComponent(json[key]);
      }).join('&');
  }

  /**
   * Fetch periodically a small info to check connection status
   */
  private pollConnection() {
    const checkConnection = async () => {
      this.get(`/${this.keepAlive}`).catch(() => {});
    };
    checkConnection();
    setInterval(checkConnection, 30000);
  }

}
