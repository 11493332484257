/**
 *
 * usage:
 *
 * app.component.ts
 * ...
 * public markdownString: string = 'This is text with **markdown**';
 * ...
 *
 * app.component.html
 * ...
 * <div [innerHTML]="markdownString | marked"></div>
 * ...
 */
import { Pipe, PipeTransform } from '@angular/core';
import * as marked from 'marked';

@Pipe({
  name: 'marked'
})
export class MarkedPipe implements PipeTransform {
  transform(value: any): any {
    if (value && value.length > 0) {
      return marked(value);
    }
    return value;
  }
}

