// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  project: 'pavia',
  language: 'it',
  pathology: 'covid',
  timeStamp: '30/12/2021 12:05',
  version: '1.2.6',
  branch: 'main',
  hermes: {
    update: 'http://138.68.98.131/apk/',
    cms: {
      local:  'http://127.0.0.1:1337',          // local server
      dev:    'http://hermesdev.voicewise.me',  // development server
      prod:   'http://hermes.voicewise.me',     // production server
    },
    mqtt: {
      local:     '127.0.0.1',
      dev:       '46.101.170.156',
      prod:      '64.227.127.240',
      protocol:  'ws',
      username:  'hermes',
      password:  'H3rm3s!',
      rootTopic: 'vo1c3w1se3',
      port: 9001
    }
  },
  pavia: {
    update: 'http://138.68.98.131/apk/',  // app update management
    cms: {
      local: 'http://127.0.0.1:1337',     // local server
      dev:   'http://167.172.108.180',    // development server
      prod : 'http://167.172.108.180',    // production server
    },
    mqtt: {
      local:     '127.0.0.1',
      dev:       '167.172.108.180',
      prod:      '167.172.108.180',
      protocol:  'ws',
      username:  'hermes',
      password:  'H3rm3s!',
      rootTopic: 'p4v14',
      port: 9001
    }
  }

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
