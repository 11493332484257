/**
 * Gestione cambio password
 */
import { Component, OnInit, Input } from '@angular/core';
import { Router            } from '@angular/router';
import { Storage           } from '@ionic/storage';
import { AlertController   } from '@ionic/angular';
import { TranslateService  } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ModalController, NavParams } from '@ionic/angular';

import { ApiService   } from '../../services/api.service';
import { AuthService  } from '../../services/auth.service';
import { ToastService } from '../../services/toast.service';

import { EKeys, IUser, IRole, EAction, IAction } from '../../models/global.models';

@Component({
  selector: 'app-passwordreset-modal',
  templateUrl: './passwordreset.modal.html',
  styleUrls: ['./passwordreset.modal.scss'],
})
export class PasswordResetModalPage implements OnInit {

  user: IUser  = null;
  userId: string = null;

  passResetForm: FormGroup;

  code: string = '';              // the received code
  password: string = '';          // the new password
  passwordConfirm: string = '';   // password confirn

  constructor(
    private router: Router,
    private api: ApiService,
    private storage: Storage,
    private auth: AuthService,
    private navParams: NavParams,
    private alert: AlertController,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private modalController: ModalController,
    private translateService: TranslateService) {
  }


  /**
   * initialise the modal
   */
  ngOnInit() {
    console.warn(`passwordResetModal.ngOnInit:`);
    console.table(this.navParams);
    this.user   = this.navParams.data.user;         // the user to edit
    this.userId = this.navParams.data.userId;       // the id of the user to edit

    console.warn(`passwordResetModal.ngOnInit: userid=${this.userId}`);
    console.table(this.user);

    this.passResetForm = this.formBuilder.group({
      code:             ['', [Validators.required, Validators.minLength(4)]],
      password:         ['', [Validators.required, Validators.minLength(4)]],
      passwordConfirm:  ['', [Validators.required, Validators.minLength(4)]]
    });

  }


  /**
   * Verifies that the user can be saved
   */
  verifyPassword() {
    if (this.code.length < 3 ||
        this.password.length  < 3 ||
        this.passwordConfirm.length  < 8 ||
        this.password.length !== this.passwordConfirm.length ||
        this.password !== this.passwordConfirm ) {
      return false;
    }
    return true;
  }


  /**
   * Close the modal
   */
  async onGoBack() {
    console.warn('passwordResetModal.onGoBack');
    await this.modalController.dismiss({
      type: EAction.CANCEL,
      data: null}, 'passwordreset');
  }


  /**
   * Ask if the user shall be deleted
   */
  async onSendResetRequest() {
    console.warn(`passwordResetModal.onSendResetRequest: ${this.code} ${this.password} ${this.passwordConfirm}`, this.user);
    const that  = this;
    const alert = await this.alert.create({
      header: 'Cancellazione',
      message: `Vuoi inviare una richiesta di modifica password?`,
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Skip password change');
          }
        }, {
          text: 'SI',
          handler: () => {
            that.sendResetRequest();
          }
        }
      ]
    });
    await alert.present();
  }


  /**
   * Send the request to change the password
   */
  sendResetRequest() {
    const that = this;
    console.warn(`passwordResetModal.sendResetRequest: ${this.code} ${this.password} ${this.passwordConfirm}`, this.user);
    if ( !this.verifyPassword() ) {
      that.toastService.showMessage('Informazioni incomplete');
      return;
    }
    this.auth.resetPassword( this.code, this.password, this.passwordConfirm ).then(
      function success(response) {
        console.log('passwordResetModal.sendResetRequest: SUCCESS');
        that.toastService.showMessage('Richiesta modifica password eseguita con successo');
        that.modalController.dismiss({
          type: EAction.SUCCESS,
          data: response}, 'passwordreset');
      },
      function failure(error) {
        console.error('passwordResetModal.sendResetRequest: ERROR', error);
        that.toastService.showMessage('Invio richiesta modifica password fallita');
      }
    ).catch(function exception(exc) {
      console.error('passwordResetModal.sendResetRequest: EXCEPTION', exc);
      that.toastService.showMessage('Invio richiesta modifica password fallita');
    });
  }


}
