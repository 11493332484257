/**
 * Servizi di monitoring dei messaggi MQTT su system, client e logger
 */
import { Injectable, EventEmitter  } from '@angular/core';
import { UtilityService            } from './utility.service';
import { StoreService              } from './store.service';
import { ConfigService             } from './config.service';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { ILoggerMessage            } from './logger.service';
import { EnvironmentService        } from './environment.service';
import { EKeys                     } from '../models/hermes.models';

const moment = require('moment');

export interface IMessage {
    topic: string;          // system, controller, agent, client...
    from: string;           // name or unique id of the emitter
    ts: string;             // timestamp
    level: string;          // logging level: none|verbose|info|warning|error
    message: string;        // message,
}

@Injectable({
    providedIn: 'root'
})
export class  MonitorService {
    private rootTopic     = 'vo1c3w1se3';   // default root topic, configuration driven
    private loggerTopic   = 'logger';
    private systemTopic   = 'system';
    private clientTopic   = 'client';
    private clientId      = 'abcdefg';
    private messageCache  = new Map();
    private clientChannel = '';
    private systemChannel = '';
    private loggerChannel = '';
    private _onMessage: EventEmitter<IMessage> = new EventEmitter<IMessage>();
    private _onLogMessage: EventEmitter<IMessage> = new EventEmitter<IMessage>();

    constructor(private config: ConfigService, private mqttService: MqttService,
            private envService: EnvironmentService, private utility: UtilityService, private store: StoreService) {
        // this.rootTopic  = envService.getMqttRootTopic();
        this.rootTopic     = store.get(EKeys.MQTT_ROOT_KEY);
        this.clientChannel = `${this.rootTopic}/${this.clientTopic}`;
        this.systemChannel = `${this.rootTopic}/${this.systemTopic}`;
        this.loggerChannel = `${this.rootTopic}/${this.loggerTopic}`;
        this.mqttService.observe(this.systemChannel).subscribe((message: IMqttMessage) => {
            // console.log(`monitorService: system ${message.payload}`);
            this.handleMessage(this.systemTopic, message.payload);
        });
        this.mqttService.observe(this.loggerChannel).subscribe((message: IMqttMessage) => {
            // console.log(`monitorService: logger ${message.payload}`);
            this.handleMessage(this.loggerTopic, message.payload);
        });
        this.mqttService.observe(this.clientChannel).subscribe((message: IMqttMessage) => {
            console.log(`monitorService: client ${message.payload}`);
            this.handleMessage(this.clientTopic, message.payload);
        });
        console.log(`monitorService: system=${this.systemTopic} logger=${this.loggerChannel} client=${this.clientChannel}`);
    }

    private handleMessage(topic: string, payload: Uint8Array) {
        try {
            const data: ILoggerMessage   = JSON.parse(payload.toString());
            const topicCache: IMessage[] = this.messageCache.get(topic) || [];
            const message: IMessage = {
                topic: topic,               // system, controller, agent, client...
                from: data.from,            // name or unique id of the emitter
                ts: data.ts,                // timestamp
                level: data.data.level,     // logging level: none|verbose|info|warning|error
                message: data.data.message  // message,
            };
            topicCache.push(message);
            this._onMessage.emit(message);
            if( topic === this.loggerTopic ) {
                this._onLogMessage.emit(message);
            }
        } catch (e) {
            console.error(`monitor.handleMessage: ERROR ${e.toString()}`);
        }
    }

    getTopic(topic: string): IMessage[] {
        return this.messageCache.get(topic) || [];
    }

    /** An EventEmitter to listen to incoming messages */
    public get onMessage(): EventEmitter<IMessage> {
        return this._onMessage;
    }

    /** An EventEmitter to listen to incoming log messages */
    public get onLogMessage(): EventEmitter<IMessage> {
        return this._onLogMessage;
    }
}
