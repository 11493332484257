/**
 * Hermes data models, interfaces and enums definitions
 */

// keys for local storage management
export enum EKeys {
    DEVICE_KEY      = 'device_token',   // device unique id
    TOKEN_KEY       = 'access_token',   // current token if any
    SESSION_KEY     = 'session_token',  // current session if any
    TKN_CACHE_KEY   = 'cache_token',    // cached token
    MQTT_ROOT_KEY   = 'mqtt_root_key',  // the root key to use for channel communication
    JWT_DATA_KEY    = 'jwt_data',       // java web token data
    USERCODE_KEY    = 'user_code',      // the unique code associated to current user
    PATHOLOGY_KEY   = 'pathology',      // current pathology
    SHORT_EVAL_KEY  = 'short_eval',     // short version of the evaluation descriptor
    EVALUATION_KEY  = 'evaluation',     // current evaluation
    RECORDING_KEY   = 'recording',      // recording texts key
    PATIENT_KEY     = 'patient',        // current patient if any
    SURVEY_KEY      = 'survey',         // survey texts
    RULES_KEY       = 'rules',          // available pathology rules
    ROLES_KEY       = 'roles',          // available user roles
    USER_KEY        = 'user',           // current user if any
    NEWS_KEY        = 'news',           // available news
    SELECTED_USER_KEY = 'selected_user' // user selected for admin operations
}

export enum ECollection {
    USER            = 'user',
    ANALYSES        = 'analyses',
    DOCUMENTS       = 'documents',
    DATAMODELS      = 'data-models',
    EVALUATIONS     = 'evaluations',
    LOCATIONS       = 'locations',
    ORGANIZATIONS   = 'organizations',
    PATHOLOGIES     = 'pathologies',        //
    PATIENTS        = 'patients',           // patient data
    QUEUES          = 'queues',             // evaluation processing queue
    RECORDINGS      = 'recordings',         // recording
    SESSIONS        = 'sessions',
    DEVICES         = 'devices',
    RULES           = 'rules',
    SURVEYS         = 'surveys',
    USERS           = 'users',

    COUNTRY         = 'nazioni',            // local json
    DISTRICT        = 'district',           // local json
    QUESTION        = 'questions',          // OLD
    QUESTIONS       = 'questions',          // OLD
    NEWS            = 'news',               // OLD
    PHRASE          = 'phrases'             // OLD
}


export interface IHermesMessage {
    action: string;
    from: string;
    to: string;
    timestamp: string;
    data: any;
}

export enum EHermesChannel {
    ANY = '#',                          // channel to receive any notification
    AGENT = 'agent',                    // channel to communicate with the agent
    EMAIL = 'email',                    // channel to manage email delivery
    CLIENT = 'client',                  // channel to communicate with client applications
    SYSTEM = 'system',                  // channel for controlling the overall platform
    CONTROL = 'control'                 // channel to communicate with the controller
}

export enum EClientAction {
    NEW = 'new',                        // a new client application entered the arena
    INFO = 'info',                      // Requires info from client
    MESSAGE = 'message',                // Display a message
    RESPONSE = 'response',              // provides the response to an evaluation
    LOGOUT = 'logout',                  // forces client logout
    TEST = 'test',                      // test action
    STARTED = 'started',                // an analysis has started
    LOADING = 'loading',                // loading data to use for the process
    PROCESSING = 'processing',          // an analysis stage is on going
}

export enum ESystemAction {
    ANNOUNCE = 'announce',              // Notifies the availability of the component (client|controller|agent|cms) and provides a profile
    KEEPALIVE = 'keepalive',            // Requires an announce message from each component of the architecture to verify availability and status (an announce shall be sent)
    FAILURE = 'failure',                // Notifies the CMS that some kind of failure or critical situation occurred
    ALIVE = 'alive',                    // A component notifies that is alive and ready
    EMAIL = 'email',                    // A component requires sending an email
    TEST = 'test'                       // test action
}

export enum EControlAction {
    NEW = 'new',                        // a new element has been added to the queue
    WORKING = 'working',                // A queue element is on processing
    FAILED = 'failed',                  // Processing failed for a queue element
    SUCCESS = 'success',                // Processing completed successfully for a queue element
    TEST = 'test',                      // test action
    PAUSE = 'pause',                    // pause any activity as soon as possible
    RESTART = 'restart'                 // restart activities
}

export enum EAgentAction {
    READY = 'ready',                    // the agent is ready to start a new process
    ASSIGN = 'assign',                  // The controller assigned a jpb to the agent
    ACCEPTED = 'accepted',              // The agent accepted the job and is starting the workflow
    REJECTED = 'rejected',              // The agent rejected the job (why?)
    START = 'start',                    // The agent can start the execution of jobs for accepted evaluation
    STAGE = 'stage',                    // The agent is executing a stage out of a list of stages
    FAILED = 'failed',                  // The workflow failed
    SUCCESS = 'success',                // The workflow has been completed successfully
    TEST = 'test',                      // test action
    PAUSE = 'pause',                    // pause any activity as soon as possible
    RESTART = 'restart',                // restart activities
    KEEPALIVE = 'keepalive',            // ask to each agent to send an announce
}

export enum EJobState {
    STARTED    = 'started',             // an analysis has started
    LOADING    = 'loading',             // the data for the analysis is on load
    PROCESSING = 'processing',          // the analysis process is on going
    RESPONSE   = 'response'             // an analysis response has been delivered
}

export enum EUserRole {
    SUPER    = 'superadmin',            // can change any low level logics, any data model and relation
    ADMIN    = 'admin',                 // can access to any information and add/delete/modify any type data but not change model and relations
    EDITOR   = 'editor',                // can add, delete or modify some specific types of data used by the services
    DOCTOR   = 'doctor',                // doctor: his/her account is used to manage patients
    SUPER_DOCTOR  = 'super_doctor',     // super-doctor: his/her account is used to manage patients and perfect patients
    AUTHENTICATED = 'authenticated',    // generic authenticated user
    PARTNER  = 'thirdPartyAPI',         // any partner
    PUBLIC   = 'public'                 // not authenticated
}

export enum EEvaluationStatus {
    STARTED     = 'started',            // patient voice acquisition has started
    READY       = 'ready',              // patient voice recordings has been completed, waiting to be queued by the CMS
    QUEUED      = 'queued',             // voice records has been collected and ready to be evaluated
    WORKING     = 'working',            // evaluation on going
    EXECUTED    = 'executed',           // evaluation executed
    FAILED      = 'failed'              // evaluation failed
}

export enum EQueueState {
    WAITING     = 'waiting',            // the queue element is waiting for processing
    WORKING     = 'working',            // queue element is being processed
    COMPLETED   = 'completed',          // queue element process has been successfully completed
    FAILED      = 'failed'              // queue element process has failed
}

export enum EPriority {
    LOW         = 0,                    // low priority: it will considered asap
    NORMAL      = 1,                    // normal: FIFO processing
    MEDIUM      = 2,                    // medium:
    HIGH        = 3,                    // high: it will processed in the next round
}

export type TProvider = 'facebook' | 'google' | 'github' | 'twitter';

export enum EMediaStatus {
    MEDIA_NONE = 0,
    MEDIA_STARTING = 1,
    MEDIA_RUNNING = 2,
    MEDIA_PAUSED = 3,
    MEDIA_STOPPED = 4,
}

export interface IMediaRecord {
    name: string;       // file name
    ext: string;        // file extension (i.e. mp3, m4a, ...)
    audio: string;      // base64 representation of recoded audio
    path: string;       // recording path
    duration: number;   // duration in milliseconds
}

// authentication credentials
export interface IUserCredentials {
    username: string;
    password: string;
    email?: string;
}

// user profile
export interface IUser {
    // tslint:disable-next-line: typedef-whitespace
    id?: string;
    _id?: string;
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    telephone: string;
    provider?: string;
    password?: string;
    confirmed: boolean;
    blocked: boolean;
    role: IRole | any;
    updatedAt?: string;
    createdAt?: string;
    resetPasswordToken?: string;
}

// user role descriptor as returned by getRoles()
export interface IRole {
    id: string;             // role unique id: 5d1a0eff0b344e7e5b9d697c
    _id: string;            // role unique id: 5d1a0eff0b344e7e5b9d697c
    __v: number;            // 0,
    name: string;           // The name of the role, i.e.: Authenticated
    type: string;           // The name of the role, id: authenticated
    description: string;    // The description of the role, i.e.: Default role given to authenticated user
    nb_users: number;       // How many users has this profile: 2
}

export interface IDevice {
    deviceid: string;       // deviceid,
    enabled: boolean;       //
    user: string | IUser;   // userid,
    ts: string;             // time stamp
}

export interface ISession {
    id: string;             // the database id
    username: string;       // user.username,
    deviceid: string;       // deviceid,
    active: boolean;        // true if active
    geocode: any;           // geocode info
    token: string;          // token,
    user: string | IUser;   // user id
    ts: string;             // time stamp
}

// user data as returned by login
export interface IUserData {
    id?: string;
    _id?: string;
    firstname?: string;
    lastname?: string;
    username: string;
    phone: string;
    blocked: boolean;
    confirmed: boolean;
    enabled: boolean;
    validated: boolean;
    email: string;
    provider: string;
    status: number;
    role: EUserRole;
    sessions: ISession[] | string[];
    devices: IDevice[] | string[];
    createdAt: string;
    updatedAt: string;
    patients?: any[];
    profile: 'admin'|'user'|'doctor'|'thirdPartyAPI';
}

export interface IDocument {
    code: string;
    title: string;
    body: string;
}

export interface IPathology {
    id?: string;
    _id?: string;
    code: string;
    name: string;
    title: string;                      // localization tag
    description: string;
    evaluations?: string | IEvaluation; // link to evaluation
    data_model?: string | any;          // link to data model
    survey?: string | ISurvey;          // link to survey
    rules?: string | IRules;            // link to rules
    error?: boolean;
    message?: string;
    data?: any;
}

// type of question
export enum EQuestionType {
    SINGLE = 'single',                  // several options, one accepted, radio mode
    MULTIPLE = 'multiple',              // several options, multiple accepted, radio mode
    DROPDOWN = 'dropdown',              // several options, one accepted, dropdown mode
    NUMBER = 'number',                  // a numeric value must be typed
    TEXT = 'text'                       // a text must be entered
}

// question option - used for managing the question
export interface IQuestionOption {
    label: string;
    selected: boolean;
}

export interface IQuestion {
    code: string;                           // unique id to map the answer
    order: number;                          // the diplay order
    text: string;                           // the question tex
    type: EQuestionType;                    // question type
    limit: number;                          // optional upper limit in bytes when type is text
    required: boolean;                      // mandatory or not
    options: string;                        // comma separated options, default is * prefixed, if any

    questionOptions?: IQuestionOption[];    // UI options
    incomplete?: boolean;                   // UI flag to show if a required question has been asked or not
    surveyCode?: string;                    // UI code of the survey to which the question belongs
}

export interface ISurvey {
    id?: string;
    _id?: string;
    code: string;
    name: string;
    questions: IQuestion[];
    pathology: string|IPathology;   // link: pathology id or pathology description
    note?: string;
}

export interface IAnswer {
    code: string;   // question code
    data: string;   // answer data
}

// describes the results for the evaluation of a single audio file, the overall result shall be in IEvaluation
export interface IAnalysis {
    id?: string;                        // unique record id
    _id?: string;                       // unique record id
    response: any;                      // the json of the response
    features: number[];                 // positional array of values
    selectedFeatures: any[];            // array of selected features
    evaluation: string | IEvaluation;   // link to evaluation
    pathology: string | IPathology;     // link to pathology
    patient: string | IPatient;         // link to patient
}

// the information for the evaluation of a patient
export interface IEvaluation {
    id?: string;                        // unique record id
    _id?: string;                       // unique record id
    code: string;                       // unique short code used in audio file name, generated server side
    longitude: number;                  // longitude of the device when evaluation started
    latitude: number;                   // latitude of the device when evaluation started
    note: string;                       // optional note
    deviceInfo: any;                    // optional information about the device
    device: string;                     // device fingerpring
    patientCode: string;                // uuid of the patient
    response?: any;                     // optional json of the overall response
    status: EEvaluationStatus;          // 'waiting'|'ready'|'queued'|'started'|'completed'|'failed';
    surveyAnswers: IAnswer[];           // the answers to the survey
    survey: string | ISurvey;           // the survey definition
    pathology: string | IPathology;     // the pathology under analysis
    patient: string | IPatient;         // the evaluated patient
    analysis: IAnalysis;                // link to corresponding results
    user: string | IUser;               // user that recorded evaluation
    rule: string | IRules;              // the rules applied
    recordings?: IRecording[];          // patient's voice recordings
    queue?: string | IQueue;            // link to queue element for processing this evaluation
    createdAt?: string;                 // generated automatically by backend
    updatedAt?: string;                 // generated automatically by backend
    publishedAt?: string;               // generated automatically by backend
    result?: string;                    // 0=sano, 1=malato
    score?: string;                     //
    // error management
    error?: boolean;
    message?: string;
    data?: any;
}
export interface IEvalShort {
    id: string;
    code: string;
    status: EEvaluationStatus;
    longitude: number;
    latitude: number;
    analysisId: string;                 // analysis id
    pathology: string;                  // name
    patient: string;                    // code
    result: string;                     // 0=sano, 1=malato
    score: string;                      // %
    date: string;                       // last update
    user: string;                       // user id
    note?: string;                      // any note
    recordings: IRecording[];           // recording short info
    totalRecordings: number;            // total recordings
    survey?: ISurvey;                   // optional survey
    surveyAnswers?: IAnswer[];          // optional answers to survey
}

export interface IRecording {
    id?: string;                        // unique record id
    _id?: string;                       // unique record id
    code: string;                       // audio recording code based on patient code, evaluation code and direction code
    name: string;                       // name (id) and extension of the file
    size: number;                       // the size of the source file
    MD5: string;                        // MD5 value of the file
    audio64?: string;                   // audio file in base64 (optional when low memory usage is required)
    analysis?: string | IAnalysis;      // the result of the analysis
    evaluation?: string | IEvaluation;  // the evaluation to which it belongs (optional when low memory usage is required)
    user?: string | IUser;              // optional logged in user
    audiofile?: string | IAudioFiles;   // link to audio file in the new CMS
    createdAt?: string;
    updatedAt?: string;
    date?: string;
}

export interface IAudioFiles {
    id?: string;                        // unique record id
    _id?: string;                       // unique record id
    name: string;                       // name (id) and extension of the file
    size: number;                       // the size of the source file
    MD5: string;                        // MD5 value of the file
    audio64?: string;                   // audio file in base64 (optional when low memory usage is required)
    audioCode: string;                  // audio recording code based on patient code, evaluation code and direction code
    recording: string | IRecording;     // link to recording
    createdAt?: string;
    updatedAt?: string;
    date?: string;
}

export interface IQueue {
    id?: string;
    _id?: string;
    status: EQueueState;
    statusMessage: string;
    priority: number;
    patient: string | IPatient;         // link to patient
    pathology: string | IPathology;     // link to pathology
    evaluation: string | IEvaluation;   // link to evaluation
}

export interface IOrganization {
    id?: string;
    _id?: string;
    name: string;
    description: string;
}

export interface ILocation {
    id?: string;
    _id?: string;
    name: string;
    description: string;
    address: string;
    city: string;
    cap: string;
    province: string;
    nation: string;
    longitude: number;
    latitude: number;
    evaluation: string[] | IEvaluation[];
}

export enum EAction {
    CANCEL  = 0,
    CREATE  = 1,
    UPDATE  = 2,
    DELETE  = 3,
    SUCCESS = 4,
    FAILURE = 5,
    GOTO    = 6
}

export interface IAction {
    type: EAction;
    data: any;
}

export interface IResponse {
    data: IAction;
    role: string;
}

export interface IResult {
    error: boolean;
    code?: number;
    message: string;
    data: any;
}

export interface IProviderToken {
    access_token?: string;
    code?: string;
    oauth_token?: string;
}

export enum EStatus {
    OK = 0,
    ERROR = -1
}

export enum EStatusCode {
    OK,
    LOGIN_ERROR,
    LOGIN_EXCEPTION
}

export interface ITokenData {
    _id: string;    // userid
    iat: number;
    exp: number;                              // number of milliseconds since 1970 representing exporation date
}

export interface ILoginResponse {
    jwt: string;
    user: IUserData;
}

export interface IPatient {
    id?: string;
    _id?: string;
    code: string;
    age: number;
    perfect: boolean;                       // true if patient's vocal samples can be used to feed Machine Learning process
    birthdate: string;
    sex: 'M' | 'F';
    height: number;
    weight: number;
    ethnicity: string;                      // italian, caucasian, ...
    note: string;                           // any note about the patient
    evaluations: string[] | IEvaluation[];  // one or more evaluations
    user: string | IUser;                   // the user that registered the patient
    createdAt?: string;                     // read by backend but not required for store
    updatedAt?: string;                     // read by backend but not required for store
    publishedAt?: string;                   // read by backend but not required for store
    date?: string;                          // used by UI
}
export interface IEvaluate {
    id: string;
    _id?: string;
    code: string;
    status: EEvaluationStatus;
    longitude: number;
    latitude: number;
    deviceInfo: any;            // json
    surveyAnswers: IAnswer[];   // all the answers to pathology questions
    note: string;               // any note
    location: any;              // link
    pathology: any;             // link
    patient: any;               // link
    recordings: any;            // link
    queue: any;                 // link
    survey: any;                // link
    rule: any;                  // link
    result?: string;            // 0=sano, 1=malato
    score?: string;             // %
    createdAt: string;
}

export interface IQDate {
    day: string;
    month: string;
    year: string;
    hour: string;
    minute: string;
    numDay: number;
    numMonth: number;
    numYear: number;
    numHour: number;
    numMinute: number;
}

// the direction for recording audio files
export interface IDirection {
    code: string;                   // short unique identifier to be used in file name concatenated with rules code
    order: number;                  // the positing in the sequence
    title?: string;                 // optional title
    text: string;                   // the phrase to be pronounced
    type: 'record' | 'message';
    guidelines: string;             // synthetic explanation of what to do
}

// groups one or more directions for recording audio files
export interface IRules {
    id?: string;
    _id?: string;
    name: string;                   // the name of the group of direction
    code: string;                   // short unique identifier to be used in file name with direction code (???)
    language: 'IT' | 'EN';          // the language of the ryle
    messageStart: string;
    messageEnd: string;
    audioMinSeconds?: number;       // minimum recording time in seconds
    audioMaxSeconds?: number;       // maximum recording time in seconds
    directions: IDirection[];       // the array of directions to apply
    guidelines: string;
    pathology: string | IPathology; // link to pathology
}


export interface IAudioRecord {
    ts: string;
    blob: any;
    playing: boolean;
}

export interface ISong {
    id: string;         // the id of the song withing the CMS
    key: string;        // the unique key of the song
    name: string;       // the name of the song plus extension
    path?: string;      // full path and name of the song
}

export interface ISongInfo {
    name: string;
    type: string;
    data: string;
    size: number;
    ext: string;
}

export interface IMediaRecord {
    name: string;       // file name
    ext: string;        // file extension (i.e. mp3, m4a, ...)
    audio: string;      // base64 representation of recoded audio
    path: string;       // recording path
    duration: number;   // duration in milliseconds
}

