/**
 * Manages User Authorizations
 * @note cannot be used administration accounts but user accounts only
 * admin: maglianoinrete@gmail.com / M4gl14n0! -> does not work 
 * user: administrator@tavolato.it / 4dm1n1str4t0r! -> work
 */
import { Platform, AlertController } from '@ionic/angular';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable       } from '@angular/core';
import { Storage          } from '@ionic/storage';
import { BehaviorSubject  } from 'rxjs';

import { HttpService      } from './http.service';
import { TokenService     } from './token.service';
import { ToastService     } from './toast.service';
import { environment      } from '../../environments/environment';
import { StoreService     } from '../services/store.service';

import { Apollo, gql } from 'apollo-angular';

import { ITokenData, IUser, ISession, EUserRole, IUserCredentials, IRole,  EKeys } from '../models/hermes.models';

export enum AuthServiceStatus {
  INIT,               // notify initialization
  AUTH_START,         // notify a new authentication request is starting
  AUTH_SUCCESS,       // notify a request completed successfully
  AUTH_ERROR,         // notify cannot authenticate user
  AUTH_FAILURE,       // notify authentication failed
  REGISTER_START,     // notify a registration request is starting
  REGISTER_SUCCESS,   // notify a new registration request is starting
  REGISTER_ERROR,     // notify cannot register the new user
  REGISTER_FAILURE,   // notify registration failed
  TOKEN_SET,
  TOKEN_EXPIRED,
  TOKEN_DELETED,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
}

const LOGIN = gql`
    mutation login( $identifier: String!, $password: String!, $device: String! ) {
        login(
            input: {
                identifier: $identifier
                password: $password
                provider: "local"
                device: $device
            }
        ) {
            jwt
            user {
                id
                username
                firstname
                lastname
                email
                role {
                    name
                    type
                }
            }
        }
    }
`;

const SESSION_DELETE = gql`
    mutation DeleteSession {
        deleteSession( input: { where: { id: $id } } ) {
            session {
                id
                username
                active
                geocode
                token
                ts
            }
        }
    }
`;


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private endpoint = {
    login:          '/auth/local',
    register:       '/auth/local/register',
    forgotpassword: '/auth/forgot-password',
    resetpassword:  '/auth/reset-password',
    roles:          '/users-permissions/roles'
  };

  token: string  = null;

  private adminPlugin = '/admin/plugins';

  private userData:   IUser = null;
  private userRole:   IRole = null;
  private session:    ISession = null;
  private tokenData:  ITokenData = null;
  private roles:      IRole[] = [];
  private allRoles:   IRole[] = [];
  private loggedIn:   boolean = false;

  private project = environment.project;
  private cms     = environment[this.project].cms;
  private url     = environment.production ? this.cms.prod :  this.cms.dev;

  authenticationState: BehaviorSubject<AuthServiceStatus> = new BehaviorSubject<AuthServiceStatus>(AuthServiceStatus.INIT);

  constructor(private apollo: Apollo,
              private storage: Storage,
              private platform: Platform,
              private store: StoreService,
              private helper: JwtHelperService,
              private httpService: HttpService,
              private tokenService: TokenService,
              private alertController: AlertController) {
    const that = this;
    console.log(`AUTH: project=${this.project} production=${environment.production} url=${this.url}`);
    this.platform.ready().then(() => {
      that.checkToken();
      that.pollToken();
    });
  }

  /**
   * Polls the token to see if expired
   */
  pollToken() {
    const that = this;
    setInterval( function() {
      that.storage.get(EKeys.TOKEN_KEY).then(token => {
        if (token) {
          if ( that.helper.isTokenExpired(token) ) {
            console.warn('auth.pollToken: TOKEN EXPIRED - Logging Out');
            that.logout();
          } else {
            that.token = token;
            that.loggedIn = true;
            that.tokenService.token = token;
          }
        } else {
          if ( that.loggedIn ) {
            that.logout();
          }
        }
      });
    }, 60000);
  }

  /**
   * Check if the user is authenticated and the token is not expired
   */
  async isAuthenticated() {
    const that = this;
    return new Promise( (resolve, reject) => {
      that.storage.get(EKeys.TOKEN_KEY).then(token => {
        if (token) {
          if ( that.helper.isTokenExpired(token) ) {
            console.warn('auth.isAuthenticated: TOKEN EXPIRED');
            resolve(false);
          } else {
            that.token = token;
            that.tokenService.token = token;
            resolve(true);
          }
        } else {
          resolve(false);
        }
      });
    });
  }

  isAuthenticatedSync() {
    if ( this.token && !this.helper.isTokenExpired(this.token) ) {
      return true;
    }
    return false;
  }

  /**
   * Check if logged in user has the required role
   * @param role required role
   */
  hasRole( role: EUserRole ) {
    if ( this.isAuthenticated() && this.userRole  && this.userRole.type === role ) {
      return true;
    }
    return false;
  }

  /**
   * Return role type if logged
   */
  getRoleType( ) {
    if ( this.isAuthenticated() && this.userRole) {
      return this.userRole.type;
    }
    return null;
  }

  /**
   * Super admin has full control over the application
   */
  isSuperAdmin() {
    if ( this.hasRole(EUserRole.SUPER) ) {
      return true;
    }
    return false;
  }

  /**
   * An administrator can operate on the whole platform
   */
  isAdministrator() {
    if ( this.hasRole(EUserRole.ADMIN) || this.isSuperAdmin() ) {
      return true;
    }
    return false;
  }

  /**
   * A super doctor can manage patients and perfect patients (i.e. patients whose voice samples can be used to feed ML)
   */
  isSuperDoctor() {
    if ( this.hasRole(EUserRole.SUPER_DOCTOR) ) {
      return true;
    }
    return false;
  }

  /**
   * A doctor can manage patients
   */
  isDoctor() {
    if ( this.hasRole(EUserRole.DOCTOR) || this.isSuperAdmin() ) {
      return true;
    }
    return false;
  }

  /**
   * An editor can add and modify patients and assign them to groups
   */
  isEditor() {
    if ( this.hasRole(EUserRole.EDITOR) || this.isAdministrator() ) {
      return true;
    }
    return false;
  }

  /**
   * Check if current token is valid and remove it from storage if expired
   */
  checkToken() {
    const that = this;
    this.storage.get(EKeys.TOKEN_KEY).then(token => {
      if (token) {
        const decoded   = that.helper.decodeToken(token);
        const isExpired = that.helper.isTokenExpired(token);
        if (!isExpired) {
          that.token     = token;
          that.tokenData = decoded;
          that.tokenService.token = token;
          console.log(`AUTH.checkToken: token valid - data ${JSON.stringify(decoded)}`);
          that.storage.get(EKeys.USER_KEY).then( userData => {
            that.userData = userData;
            if ( userData.role ) {
              that.userRole = userData.role;
              // console.error(`AUTH.checkToken: user role`, that.userRole);
            } else {
              console.error(`AUTH.checkToken: ERROR - no user role`);
            }
            // console.log(`AUTH.checkToken: user data ${JSON.stringify(userData)}`);
            that.authenticationState.next(AuthServiceStatus.USER_LOGGED_IN);
          });
          that.storage.get(EKeys.SESSION_KEY).then( sessionData => {
            console.log(`AUTH.checkToken: session`, sessionData);
            that.session = sessionData;
          });
        } else {
          console.log(`AUTH.checkToken: token missing or expired`);
          this.logout();
        }
      }
    });
  }

  /**
   * Register a new user
   * @param credentials user cedentials
   */
  register(credentials: IUserCredentials) {
    const that = this;
    const data = {username: credentials.username, email: credentials.email, password: credentials.password};
    return this.httpService.post(`${this.url}${this.endpoint.register}`, data);
  }

  /**
   * Perform the login
   * @param identifier username or email
   * @param password the password
   * @returns error data or token
   */
  login(credentials: IUserCredentials) {
    const that = this;
    const identifier = credentials.email ? credentials.email : credentials.username;
    const password = credentials.password;
    const device = this.store.get(EKeys.DEVICE_KEY); // unique device id (fingerprint)
    return new Promise( (resolve, reject) => {
      console.log(`AUTH.login: identifier=${identifier} password=${password} device=${device}`);
      if ( !identifier || !identifier.length || !password || !password.length ) {
        resolve({ error: true, message: 'Informazioni di autenticazione incomplete' });
        return;
      }
      this.apollo.mutate<any>( { mutation: LOGIN, variables: { identifier, password, device } }).subscribe(
          ({data}) => {

              if ( !data ) {
                console.error(`AUTH.login: NO RESPONSE ERROR`);
                that.authenticationState.next(AuthServiceStatus.AUTH_ERROR);
                reject({ error: true, message: 'Servizio non raggiungibile', data: { msg: 'Timeout or CORS error'} });
                return;
              }

              console.log(`AUTH.login: got data`, data.login );

              that.token     = data.login.jwt;
              that.userData  = data.login.user;
              that.session   = null;
              that.tokenService.token = that.token;
              that.storage.set(EKeys.TOKEN_KEY  , that.token   );
              that.storage.set(EKeys.USER_KEY   , that.userData);
              that.storage.set(EKeys.SESSION_KEY, that.session );

              if ( that.userData.role ) {
                that.userRole = that.userData.role;
                console.warn(`AUTH.login: user role`, that.userRole);
              } else {
                console.error(`AUTH.login: no user role`);
              }

              that.tokenData = that.helper.decodeToken(that.token);
              that.storage.set(EKeys.JWT_DATA_KEY     , that.tokenData);
              console.warn('AUTH.login: user data='   , that.userData );
              console.warn('AUTH.login: token data='  , that.tokenData);
              console.warn('AUTH.login: session data=', that.session  );
              that.authenticationState.next(AuthServiceStatus.USER_LOGGED_IN);
              that.loggedIn = true;
              resolve(that.userData);
          },
          (error) => {
              console.error(`AUTH.login: ERROR ${error.toString()} => ${JSON.stringify(error, null, 4)}`);
              that.authenticationState.next(AuthServiceStatus.AUTH_ERROR);
              reject({ error: true, message: 'Servizio non disponibile', data: error });
          }
      );
    });
  }

  /**
   * Filter the roles that can be used by current user (editor, admin or superadmin)
   * @param roles all the roles
   */
  private filterRoles(roles: IRole[]) {
    const validRoles = [EUserRole.EDITOR, EUserRole.DOCTOR, EUserRole.AUTHENTICATED];  // roles that can be assigned by the app
    // console.warn(`AUTH.roles: got ${roles.length} roles \n` + JSON.stringify(roles));
    this.roles = []; // <IRole[]>data;
    // filter out any role that are not compatible with the application
    for ( const role of roles) {
      // superadmin can assign any role to a user
      if ( this.isSuperAdmin() ) {
        this.roles.push(role);
        continue;
      }
      // admin can assign a subset of roles to a user
      for ( const vrole of validRoles ) {
        if ( role.type === vrole ) {
          this.roles.push(role);
        }
      }
    }
    this.storage.set(EKeys.ROLES_KEY, this.roles);
    console.warn(`AUTH.filterRoles: ${this.roles.length} roles available`, this.roles);
  }

  /**
   * Load user roles that can be assigned by the app to a service member
   */
  loadRoles() {
    const that = this;
    const url  = `${this.url}${this.endpoint.roles}`;
    console.log('AUTH.loadRoles: loading from ' + url);
    this.httpService.get(`${this.url}${this.endpoint.roles}`).then(
        function success(data: any) {
          if ( data.error ) {
            console.error('AUTH.loadRoles: ERROR=', data);
            if ( data.code === 401) {
              that.logout();
            }
            that.showAlert(data.msg);
            return;
          }
          that.allRoles = (data.roles ? data.roles : data) as IRole[];
          that.filterRoles(that.allRoles);  // filter and locally save
        },
        function failure(err) {
          const msg = JSON.stringify(err);
          console.warn('=' + err );
          that.showAlert(msg);
        }
    );
  }

  /**
   * Perform logout by erasing any user related information
   */
  async logout() {
    console.warn('AUTH.logout: START ');
    const sessionId = this.session ? this.session.id : 0;
    const that = this;

    // if session data does not exist, ....
    if ( !this.session || !this.session.id ) {
      this.session    = null;
      this.token      = null;
      this.tokenData  = null;
      this.userData   = null;
      this.loggedIn   = false;
      this.tokenService.deleteToken();
      this.storage.remove(EKeys.SESSION_KEY);
      this.storage.remove(EKeys.TOKEN_KEY).then(() => {
        that.authenticationState.next(AuthServiceStatus.TOKEN_DELETED);
      });
      this.storage.remove(EKeys.USER_KEY).then(() => {
        that.authenticationState.next(AuthServiceStatus.USER_LOGGED_OUT);
      });
      console.error('AUTH.logout: ERROR MISSING SESSION');
      return true;
    }
    // remove session info
    this.apollo.mutate<any>( { mutation: SESSION_DELETE, variables: { id: this.session.id } }).subscribe(
      ({data}) => {
        that.session    = null;
        that.token      = null;
        that.tokenData  = null;
        that.userData   = null;
        that.loggedIn   = false;
        that.tokenService.deleteToken();
        that.storage.remove(EKeys.SESSION_KEY);
        that.storage.remove(EKeys.TOKEN_KEY).then(() => {
          that.authenticationState.next(AuthServiceStatus.TOKEN_DELETED);
        });
        that.storage.remove(EKeys.USER_KEY).then(() => {
          that.authenticationState.next(AuthServiceStatus.USER_LOGGED_OUT);
        });
        console.log(`AUTH.logout: SUCCESS`, data.session);
      },
      (error) => {
        console.error(`AUTH.logout: ERROR ${error.toString()} => ${JSON.stringify(error, null, 4)}`);
        that.storage.remove(EKeys.SESSION_KEY);
      }
    );

    return true;
  }

  /**
   * Sends an email to a user with the link of your reset password page.
   * This link contains an URL param code which is required to reset user password.
   * Received link url format https://my-domain.com/rest-password?code=privateCode.
   * @param userEmail
   * @param serviceUrl Link that user will receive.
   */
  public async forgotPassword(userEmail: string, serviceUrl?: string) {
    const that = this;
    const data = {
      email: userEmail,
      // 'http:/localhost:1337/admin/plugins/users-permissions/auth/reset-password'
      url: serviceUrl ? serviceUrl : `${this.url}${this.adminPlugin}${this.endpoint.forgotpassword}`
    };

    // this.logout();  // reset
    return this.httpService.post(`${this.url}${this.endpoint.forgotpassword}`, data).catch(e => {
      that.showAlert(e.error.msg);
      throw new Error(e);
    });
  }

  /**
   * Reset the user password.
   * @param code Is the url params received from the email link (see forgot password).
   * @param password
   * @param passwordConfirmation
   */
  public async resetPassword( code: string,  password: string, passwordConfirmation: string ) {
    const that = this;
    const data = { code, password, passwordConfirmation};
    this.logout();  // reset
    return this.httpService.post(`${this.url}${this.endpoint.resetpassword}`, data).catch(e => {
      that.showAlert(e.error.msg);
      throw new Error(e);
    });
  }

  /**
   * Return whole user data
   */
  getUserData(): IUser {
    if ( !this.userData ) {
      console.error('AUTH.getUserData: NO USER DATA');
      return null;
    }
    return this.userData;
  }

  /**
   * return the list of user roles
   */
  getUserRoles(): IRole[] {
    return this.roles;
  }

  /**
   * Return user name
   */
  getUserName() {
    if ( !this.userData ) {
      console.error('AUTH.getUserName: NO USER DATA');
      return '';
    }
    return this.userData ? this.userData.username : null;
  }

  /**
   * Return user name
   */
  getName() {
    if ( !this.userData ) {
      console.error('AUTH.getName: NO USER DATA');
      return null;
    }
    if ( this.userData.firstname && this.userData.lastname) {
      return this.userData ? (this.userData.firstname + ' ' + this.userData.lastname) : null;
    }
    return this.userData.username;
  }

  /**
   * Return user id
   */
  getUserId(): string {
    if ( !this.userData ) {
      console.error('AUTH.getUserId: NO USER DATA');
    }
    return this.userData ? this.userData._id : null;
  }

  /**
   * Return user role
   */
  getUserRole() {
    return this.userData ? this.userData.role : null;
  }

  /**
   * Return thw whole token
   */
  async getToken() {
    return  this.token;
  }

  /**
   * Just for testing
   */
  getSpecialData() {
    const that = this;
    return this.httpService.get(`${this.url}/api/special`).catch(e => {
      const status = e.status;
      if (status === 401) {
        that.showAlert('You are not authorized for this!');
        that.logout();
      }
      throw new Error(e);
    });
  }

  /**
   * Check if it runs on browser
   * @returns true if browser, false otherwise
   */
  isBrowser(): boolean {
    return typeof window !== 'undefined';
  }

  /**
   * Show an alert dialog with a message
   * @param msg the message to display
   */
  showAlert(msg) {
    const alert = this.alertController.create({
      message: msg,
      header: 'Error',
      buttons: ['OK']
    });
    alert.then(alertMsg => alertMsg.present());
  }

  getError(errorGraphQL) {
    const error = {
        code: '',
        status: 0,
        id: '',
        message: '',
        error: '',
        where: ''
    };
    (errorGraphQL.graphQLErrors || []).forEach ( err => {
      try {
        error.error   = err.message;
        error.where   = err.path[0] || '';
        error.code    = err.extensions.code;
        error.status  = err.extensions.exception.code;
        error.message = err.extensions.exception.data.message[0].message;
        error.id      = err.extensions.exception.data.message[0].id;
      } catch( err ) {
        console.error(`authService.getError: ${err.toString()}`)
      }
    });
    return (error);
  }

}

/**
 * error example
 * {
 *    "graphQLErrors": [
 *      {
 *     "message": "Bad Request",
 *     "locations": [
 *       {
 *         "line": 2,
 *         "column": 3
 *       }
 *     ],
 *     "path": [
 *       "login"
 *     ],
 *     "extensions": {
 *       "code": "INTERNAL_SERVER_ERROR",
 *       "exception": {
 *         "code": 400,
 *         "data": {
 *           "statusCode": 400,
 *           "error": "Bad Request",
 *           "message": [
 *             {
 *               "messages": [
 *                 {
 *                   "id": "Auth.form.error.invalid",
 *                   "message": "Identifier or password invalid."
 *                 }
 *               ]
 *             }
 *           ],
 *           "data": [
 *             {
 *               "messages": [
 *                 {
 *                   "id": "Auth.form.error.invalid",
 *                   "message": "Identifier or password invalid."
 *                 }
 *               ]
 *             }
 *           ]
 *         }
 *       }
 *     }
 *   }
 * ],
 * "networkError": null,
 * "message": "Bad Request"
 * }
 */
