/**
 * Servizi per gestire la configurazione
 */
import { Injectable     } from '@angular/core';
import { UtilityService } from './utility.service';
import { ConfigService, LoggingLevel} from './config.service';
import { MqttService, IMqttMessage  } from 'ngx-mqtt';
import { EnvironmentService         } from './environment.service';
import { StoreService   } from './store.service';
import { EKeys          } from '../models/hermes.models';

const moment = require('moment');

export interface ILoggerMessage {
    action: string;         // 'log',
    from: string;           //  'clientapp',
    to: string;             //  'logger',
    ts: string;             //  now,
    data: {
        level: string;      // level,
        message: string;    // message,
        client: string;     // this.clientId,
        text: string;       // `${this.clientId} - ${now} [${level}] ${message}`
    }
}

@Injectable({
    providedIn: 'root'
})
export class  LoggerService {

    private rootTopic   = 'vo1c3w1se3'; // (<any>env).rootTopic;
    private loggerTopic = 'logger';
    private clientId    = 'abcdefg';
    private channel     = `${this.rootTopic}/${this.loggerTopic}`;
    private loggingLevel: LoggingLevel = LoggingLevel.NONE;

    constructor(config: ConfigService, private mqttService: MqttService, private envService: EnvironmentService,
            private utility: UtilityService, private store: StoreService) {
        this.clientId  = store.get(EKeys.DEVICE_KEY);
        // this.rootTopic = envService.getMqttRootTopic();
        this.rootTopic = store.get(EKeys.MQTT_ROOT_KEY);
        this.channel   = `${this.rootTopic}/${this.loggerTopic}`;
        console.log(`loggerService.constructor: logging service ${this.channel} for client ${this.clientId}`);
        if (config.loggingLevel) {
            this.loggingLevel = config.loggingLevel;
        }
        // subscribe messages that are for all clients
        this.mqttService.observe(this.channel).subscribe((message: IMqttMessage) => {
            // console.log(`loggerService.MQTT: got ${message.payload}`);
        });
        // this.loop();
    }

    /**
     * test loop
     */
    loop() {
        const send = () => {
            console.log(`loggerService.send: test`);
            this.mqttService.publish(this.channel, "WS MQTT TEST");
            this.mqttService.unsafePublish(this.channel, "WS UNSAFE MQTT TEST", {qos: 1, retain: false});
            setTimeout(send, 2000);
        };
        send();
    }

    /**
     * Log a message according to level set
     * @param level message level
     * @param message the message
     */
    private logIt(level = LoggingLevel.WARNING, message) {
        const now = moment().format('YYYY-MM-DD HH:mm');
        this.clientId = this.clientId || this.store.get(EKeys.DEVICE_KEY);
        if (this.shouldLog(level)) {
            switch (level) {
                case LoggingLevel.ERROR:
                    console.error(message);
                    break;
                case LoggingLevel.WARNING:
                    console.warn(message);
                    break;
                case LoggingLevel.INFO:
                    console.log(message);
                    break;
                default:
                    console.log(message);
            }
            try {
                const logMessage: ILoggerMessage = {
                    action: 'log',
                    from: this.clientId || 'clientapp',
                    to: 'logger',
                    ts: now,
                    data: {
                        level: level,
                        message: message,
                        client: this.clientId,
                        text: `${this.clientId} - ${now} [${level}] ${message}`
                    }
                }
                this.mqttService.unsafePublish(this.channel, JSON.stringify(logMessage), {qos: 1, retain: false});
            } catch ( err) {}
        }
    }

    /**
     * Return true if the required logging level is compatible with current configuration
     * @param level logging level to verify
     */
    private shouldLog(level: LoggingLevel) {
        if (this.loggingLevel === LoggingLevel.NONE) {
            return false;
        } else if (this.loggingLevel === LoggingLevel.ERROR) {
            return level === LoggingLevel.ERROR;
        } else if (this.loggingLevel === LoggingLevel.WARNING) {
            return level === LoggingLevel.ERROR || level === LoggingLevel.WARNING;
        } else if (this.loggingLevel === LoggingLevel.INFO) {
            return level === LoggingLevel.ERROR || level === LoggingLevel.WARNING || level === LoggingLevel.INFO;
        } else {
            return true;
        }
    }

    /**
     * Convert and concatenate all the arguments
     * @param args the argument to evaluate
     */
    private toString(args) {
        let message = '';
        let count = 0;
        const pp = args || [];
        pp.forEach(param => {
            if ( typeof param !== 'string' ) {
                param = this.utility.stringify(param);
            }
            if ( count ) {
                message += ' \n';
            }
            message += param;
            count++;
        });
        return message;
    }

    error(...args) {
        this.logIt(LoggingLevel.ERROR, this.toString(args));
    }

    warning(...args) {
        this.logIt(LoggingLevel.WARNING, this.toString(args));
    }

    warn(...args) {
        this.logIt(LoggingLevel.WARNING, this.toString(args));
    }

    info(...args) {
        this.logIt(LoggingLevel.INFO, this.toString(args));
    }

    log(...args) {
        this.logIt(LoggingLevel.INFO, this.toString(args));
    }

    verbose(...args) {
        this.logIt(LoggingLevel.VERBOSE, this.toString(args));
    }

    table(...args) {
        console.table(args);
    }

}

