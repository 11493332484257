/**
 * @name    Encrypts/Descrypts stored data
 * @file    securestore.service.js
 * @date    15/11/2021
 * @version 1.0.0
 * @author  L.Tavolato
 * @see https://medium.com/geekculture/how-to-encrypt-webstorage-in-angular-97094a0c5809
 */

import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class SecureStorageService {
    private myKey = 'S3cur3St0r3K3y';

    constructor() {
    }

    init() {
        Storage.prototype._setItem = Storage.prototype.setItem;
        Storage.prototype._getItem = Storage.prototype.getItem;

        Storage.prototype.setItem = function(key, value) {
            this._setItem(key, CryptoJS.AES.encrypt(value, this.myKey).toString());
        };

        Storage.prototype.getItem = function(key) {
            const value = this._getItem(key);
            if (value) {
                return CryptoJS.AES.decrypt(value, this.myKey).toString(CryptoJS.enc.Utf8);
            } else {
                return null;
            }
        };
    }

}
