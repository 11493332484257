
import { Injectable } from '@angular/core';
import { Platform   } from '@ionic/angular';
import { Device     } from '@ionic-native/device/ngx';

@Injectable({
    providedIn: 'root'
})
export class DeviceDetailsService {

    name: string = '';
    osVersion: string = '';
    uuid: string = '';
    platform: string = '';
    ready = false;

    private UniqueDeviceID = '';


    constructor( private plt: Platform, private device: Device) {
        console.error(`deviceDetailsService: platform(s)=${this.plt.platforms().join(',')}`);
        this.plt.ready().then(() => {
            if ( this.plt.is('mobile') ) {
                this.ready = true;
                setTimeout(() => {
                    this.osVersion = this.device.version;
                    this.uuid = this.device.uuid;
                    this.name = (window as any).device.name;
                    this.platform = device.platform;
                    console.error(`deviceDetailsService: platform(s)=${this.plt.platforms().join(',')} device name=${this.name} uuid=${this.uuid} osVersion=${this.osVersion} platform=${this.platform}`);
                }, 1000);
            }
            console.error(`deviceDetailsService: platform(s)=${this.plt.platforms().join(',')} device name=${this.name} uuid=${this.uuid} osVersion=${this.osVersion} platform=${this.platform}`);
        });
    }

    toString() {
        if ( this.ready ) {
            console.error(`deviceDetailsService.toString: platform(s)=${this.plt.platforms().join(',')} device name=${this.name} uuid=${this.uuid} osVersion=${this.osVersion} platform=${this.platform}`);
            return `platform(s)=${this.plt.platforms().join(',')} device name=${this.name} uuid=${this.uuid} osVersion=${this.osVersion} platform=${this.platform}`;
        }
        console.error(`deviceDetailsService.toString: not yet ready`);
    }

    /**
     * Read a persistent unique id
     */
/*
    getUniqueDeviceID() {
        this.uniqueDeviceID.get()
        .then((uuid: any) => {
            console.log(`APP.getUniqueDeviceID: UUID ${uuid}`);
            this.UniqueDeviceID = uuid;
        })
        .catch((error: any) => {
            console.log(`APP.getUniqueDeviceID: ERROR ${error.toString()}`, error);
            this.UniqueDeviceID = `3rr0r!${error}`;
        });
        console.log(`APP.getUniqueDeviceID: UID ${JSON.stringify(this.uid)}`)
    }
*/


}
