/**
 * Audio Recorder based on WebRTC
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Media, MediaObject } from '@ionic-native/media/ngx';
import { File   } from '@ionic-native/file/ngx';

import { IMediaRecord, EMediaStatus } from '../../models/global.models';

@Component({
  selector:      'player-mobile',
  templateUrl: './player-mobile.component.html',
  styleUrls: [ './player-mobile.component.scss'],
})
export class PlayerMobileComponent implements OnInit {

  @Input() record: IMediaRecord = null;

  @Output() status: EventEmitter<EMediaStatus> = new EventEmitter<EMediaStatus>();

  playingFlag = false;
  time = '00:00:00';
  player = null;
  start = 0;

  constructor(private media: Media, private file: File) { }

  ngOnInit() {
    console.log(`playerMobile.ngOnInit: ${this.record ? (this.record.name + this.record.ext) : 'no record' }`)
    if ( this.record && this.record.path && this.record.path.length ) {
      this.player = this.media.create(this.record.path);
      this.player.onStatusUpdate.subscribe(this.onAudioStatusUpdate);
      this.player.onSuccess.subscribe(this.onAudioSuccess);
      this.player.onError.subscribe(this.onAudioError);
    }
  }

  updateTime() {
    if ( !this.start ) {
      this.time = '00:00:00';
      return;
    }
    const now     = (new Date()).getTime();
    const elapsed = Math.trunc((now - this.start) / 1000);
    const hours   = Math.trunc( elapsed / (60 * 60));
    const value   = elapsed - hours * (60 * 60);
    const minutes = Math.trunc( value / 60 );
    const seconds = value - (minutes * 60);
    this.time = (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds);
  }

  nextLoop( ) {
    const that = this;
    const nextTick = function() {
      if ( that.playingFlag ) {
        that.updateTime();
        setTimeout(nextTick, 1000);
      }
    };
    nextTick();
  }

  onStartPlaying() {
    console.log(`playerMobile.onStartPlaying: playing=${this.playingFlag} ${this.record.name}${this.record.ext}`);
    if ( this.player && !this.playingFlag ) {
      this.start = (new Date()).getTime();
      this.player.play();
      this.player.setVolume(1);
      this.playingFlag = true;
      this.nextLoop();
    }
  }

  onStopPlaying() {
    if ( this.playingFlag ) {
      console.log(`playerMobile.onStopPlaying: playing=${this.playingFlag} ${this.record.name}${this.record.ext}`);
      this.playingFlag = false;
      this.player.stop();
    }
  }

  /**
   * fires when file status changes
   * @param status the status of current media file
   */
  private onAudioStatusUpdate(status: EMediaStatus) {
    console.log(`playerMobile.onAudioStatusUpdate: ${status}`);
    this.status.emit(status);
    switch (status) {
      case EMediaStatus.MEDIA_NONE: break;
      case EMediaStatus.MEDIA_PAUSED: break;
      case EMediaStatus.MEDIA_RUNNING: break;
      case EMediaStatus.MEDIA_STARTING: break;
      case EMediaStatus.MEDIA_STOPPED:
          this.playingFlag = false;
          if (this.player && this.player.release) {
              this.player.release();
          }
          break;
    }
  }
  /**
   * Fires on success
   */
  private onAudioSuccess() {
    console.log('playerMobile.onAudioSuccess: successful action');
  }

  /**
   * Fires when an error is detected
   * @param error the error
   */
  private onAudioError(error) {
    console.error(`playerMobile.onAudioError: ${JSON.stringify(error.json())}`);
  }

}
