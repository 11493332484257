/**
 * Persistent key/value pair storage with simple file management.
 * Key/Value pairs are store in a very simple db that is stored/retrieved from local storage
 * Keys are case insensitive
 *
 * NOTE:
 * - local storage is really limited so use this store management with care...
 */
import { Injectable } from '@angular/core';
import { File } from '@ionic-native/file/ngx';

export interface IStoreElement {
    key: string;            // a unique key
    value: any;             // any data associated to the key, tipycally a json object
    file: boolean;          // true if a file is associated to this key/value pair
    timestamp: string;      // when last modification occurred
}

export interface IStoreData {
    created: string;      // when the db has been created
    updated: any;         // when last db modification occurred
    db: IStoreElement[];  // all the key/value pairs of the db with some additional info
}

@Injectable()
export class StoreService {

    private store = null;
    private storeId = '_V4C_DB_'; // the name of the local store
    private index: number[] = [];
    private data: IStoreData = {
        created: '',
        updated: '',
        db: []
    };

    constructor(private file: File) {
        this.store = localStorage;
        this.loadDatabase();
    }

    /**
     * Return true if local storage can be used, false otherwise
     */
    serviceAvailable() {
        try {
            return 'localStorage' in window && window.localStorage !== null;
        } catch (e) {
            return false;
        }
    }

    /**
     * Return the time stamp in te form of YYYY/MM/DD HH:MM:SS
     */
    private getTimeStamp() {
        const now = new Date();
        const date = [now.getFullYear(), now.getMonth() + 1, now.getDate()];
        const time = [now.getHours(), now.getMinutes(), now.getSeconds()];
        const sdate = [String(now.getFullYear()), String(now.getMonth() + 1), String(now.getDate())];
        const stime = [String(now.getHours()), String(now.getMinutes()), String(now.getSeconds())];
        for (let i = 1; i < 3; i++) {
            if (time[i] < 10) {
                stime[i] = `0${stime[i]}`;
            }
            if (date[i] < 10) {
                sdate[i] = `0${sdate[i]}`;
            }
        }
        return sdate.join('/') + ' ' + stime.join(':');
    }

    /**
     * Read the database
     */
    private loadDatabase(): boolean {
        this.data.created = this.getTimeStamp();
        this.data.updated = this.getTimeStamp();
        this.data.db = [];
        try {
            const data = this.store.getItem(this.storeId);
            if (data && data.length) {
                this.data = JSON.parse(data);
            }
        } catch (error) {
            console.error('storeService.loadDatabase: ERROR', error);
            return false;
        }
        this.buildIndex();
        // console.warn('storeService.loadDatabase: DB loaded ', this.data);
        return true;
    }

    /**
     * Save the database
     */
    private saveDatabase(): boolean {
        this.data.updated = this.getTimeStamp();
        try {
            this.store.setItem(this.storeId, JSON.stringify(this.data));
        } catch (error) {
            console.error('storeService.saveDatabase: ERROR', error);
            return (false);
        }
        // console.warn('storeService.saveDatabase: DB saved ', this.data);
        return true;
    }

    /**
     * Build a searchable key
     * @param key the key to convert
     */
    private normalizeKey(key) {
        if (!key || !key.length) { return( null ); }
        key = key.replace(/ /g, '_');   // remove white spaces
        key = key.toLowerCase();        // convert to lower case
        return ( key );
    }

    /**
     * Build the key for storing a file
     * @param key is the key associated to file descriptor
     */
    private getFileId(key: string) {
        const k = this.normalizeKey(key);
        return this.storeId + '-' + k;
    }

    /**
     * Build the search index
     */
    private buildIndex() {
        this.index = [];
        // console.log(`storeService.buildIndex: indexing`, this.data);
        try {
            for ( let i = 0; i < this.data.db.length; i++) {
                const key = this.normalizeKey(this.data.db[i].key);
                this.index[key] = i + 1;
            }
        } catch (error) {
            console.error(`storeService.buildIndex: ERROR - resetting DB`, error);
            this.data.created = this.getTimeStamp();
            this.data.updated = this.getTimeStamp();
            this.data.db = [];
            this.saveDatabase();
        }
    }

    /**
     * Search for the index of a key/value pair
     * @param key the name of the key/value pair
     * @return the index if it exists (1-n), 0 if it not exists, -1 on error
     */
    private find(key: string) {
        key = this.normalizeKey(key);
        if (!key) { return( - 1 ); }
        const index = this.index[key];
        return index ? index : 0;
    }

    /**
     * Removes any data in the database
     */
    kill() {
        this.data.db = [];
        return this.saveDatabase();
    }

    /**
     * Add or update a key/value pair
     * @param key the name of the key/value pair
     * @param value any data associated the key
     * @param file flag if any file is associated to the data
     * @return the index on success, - 1 on error
     */
    set(key: string, value: any, file: boolean = false): boolean {
        // console.warn(`storeService.set: key=${key} data=${JSON.stringify(value)}`);
        let index = this.find(key);
        const pair = {key: key, value: value, file: file, timestamp: this.getTimeStamp()};
        if ( index < 0 ) { return(index); }
        if ( !index ) {
            this.data.db.push(pair);
            this.index[key] = this.data.db.length;
            index = this.data.db.length;
            // console.warn(`storeService.set: added at slot #${index}`);
        } else {
            const oldPair: IStoreElement = this.data.db[index - 1];
            if ( oldPair.file ) {
                const fileId = this.getFileId(key);
                // removes the file
                if ( this.saveFileToLocalStorage(fileId, null) === false) {
                    return false;
                }
            }
            this.data.db[index - 1] = pair;            // replaces the pair
            // console.warn(`storeService.set: replaced at slot #${index}`);
        }
        return this.saveDatabase();
    }

    /**
     * Return the value associated to a key if it exist
     * @param key the name of the key/value pair
     * @return the value if it exists or null if it not exists or on error
     */
    get(key: string): any {
        const index = this.find(key);
        const data =  index > 0 ? this.data.db[index - 1].value : null;
        // console.warn(`storeService.get: key=${key} index=${index} data=${data ? JSON.stringify(data, null, 4) : '<no data>'}`);
        return ( data );
    }

    /**
     * Remove a key/value pair from the db
     * @param key the name of the key/value pair
     * @return true on success, false otherwise
     */
    remove(key: string): boolean {
        const index = this.find(key);
        // console.warn(`storeService.remove: key=${key} index=${index}`);
        if ( index > 0 ) {
            this.data.db.splice(index - 1, 1);
            this.buildIndex();
            this.saveDatabase();
        }
        return ( index > 0 ? true : false);
    }

    /**
     * Saves a file and associated data
     * @param key unique identifier of data/file pair
     * @param data file descriptor
     * @param file base64 encoded file
     * @return true on success, false otherwise
     */
    saveFile(key: string, data: any, file: string) {
        const fileId = this.getFileId(key);
        // save the data and then the file
        if ( this.set(key, data, true) ) {
            return this.saveFileToLocalStorage(fileId, file);
        }
        return false;
    }

    /**
     * Return a file
     * @param key the key associated to data/file pair
     */
    getFile(key: string) {
        const fileId = this.getFileId(key);
        return this.store.get(fileId);
    }

    /**
     * Return the list of data related to files
     */
    getFilesData(): IStoreElement[] {
        const data = [];
        for ( let i = 0; i < this.data.db.length; i++) {
            if ( this.data.db[i].file ) {
                data.push(this.data.db[i]);
            }
        }
        return data;
    }

    /**
     * Saves a file to local storage
     * @param fieldId unique file id
     * @param file the file
     */
    private saveFileToLocalStorage ( fieldId: string, file: string ) {
        try {
            this.store.setItem(fieldId, file);
        } catch ( error ) {
            if ( error.message ) {
                console.error(`storeService.saveFileToLocalStorage: SAVE ERROR: ${error.message}`);
            } else {
                console.error(`storeService.saveFileToLocalStorage: ERROR ${error.toString()}`);
            }
            return false;
        }
        return true;
    }

}
