/**
 * Servizi per gestire le popup di notifica
 */
import { Injectable } from '@angular/core';


@Injectable()
export class LinkService {

    constructor() {}

    openWebPage() {
        window.open('https://www.voicewise.it/', '_system', 'location=yes');
    }

    openCloudwisePage() {
        window.open('http://www.cloudwise.it/', '_system', 'location=yes');
    }

}
